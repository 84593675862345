import { InputCurrency, InputDatePicker, InputPadrao, InputSwitch, InputValor, InputValorNovo } from '../../../../components/Inputs'
import { InputBeneficiarioGuia, InputCategoriaAlternativo, InputClienteFinanceiro, InputContaBancaria, InputContaBancariaCliente, InputContaBancariaEmpresa, InputEstabelecimentoSaude, InputModoLiquidacao, InputParcela, InputPeriodicidade, InputPlanoContas, InputSimNao, InputSocio, InputTipoAtendimentoFinanceiro, InputTipoDocMovimento, InputTipoDocumento, InputTipoLancamento, InputTipoTabelaProcedimento } from '../../../../components/InputsAutoComplete'
import React, { useState, useEffect, useRef } from 'react'
import Yup, { validaData, validaLancamento } from '../../../../util/Validacoes'
import { HeaderAgenda, HeaderCadastro, HeaderFinanceiro } from '../../../../components/Headers'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form, useFormikContext } from 'formik'
import { BotaoPadrao, BotaoSubMenu } from '../../../../components/Botoes'
import { convertValueToFloat, formatarMoeda, mensagens, useQuery } from '../../../../util/Utilitarios'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { salvar, get, validar } from '../../../../util/AcessoApi'
import '../../../../assets/css/icones/iconsmind/style.css';
import Atalho from '../../../../util/Atalho'
import { BtnAcao } from '../../../../components/Acoes'
import { DadosAtendimento, InformacoesMovimento } from './DadosAtendimento'
import { CadastroLiquidacao, CadastroLiquidacaoParcelado, MovimentoLancamentos } from './TiposLancamentos'
import { ModalBloqueado, ModalDuasOpcoes, ModalVisualizacao } from '../../../../components/Modais'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { ref } from 'yup'
import InputArquivoS3 from '../../../../components/InputArquivoS3'
import { MODULO } from '../../../../util/Enums'
import Rascunho from '../../../../components/Rascunho'
import { InputEmpresa, InputAtendimentoLancamentoFinanceiro, InputEmpresaClienteLancamento } from '../../../../components/InputsAutoCompleteV2'


const CadastroLancamentoFinanceiro = props => {
    const refLancamento = useRef(null)
    const [liquidar, setLiquidar] = useState(false)

    const [valoresIniciais, setValoresIniciais] = useState({})
    const history = useHistory()
    const idMovimentoFixo = history.location.state && history.location.state.idMovimento ? history.location.state.idMovimento : false
    const [idMovimento, setIdMovimento] = useState()
    const [validarLancamento, setValidarLancamento] = useState(idMovimento ? false : true)
    const [movimentoLancamento, setMovimentoLancamento] = useState([])
    let { id } = useParams()
    let validacaoInicial = Yup.lazy(({ liquidacao, liquidacao_parcelado, arrayLancamentos, liquida_parcela, parcelado_lancamento }) => {
        let index = liquidacao && liquidacao.length > 0 && liquidacao?.findIndex(x => !x.modo_liquidacao)
        return Yup.object({
            cliente_lancamento: Yup.string().nullable().test('Cliente Lançamento ', 'Campo Obrigatório!', function (value) { return validarLancamento ? value : true }),
            valor_geral_lancamento: Yup.string().nullable().test('Valor Geral', 'Campo Obrigatório!', function (value) { return validarLancamento ? value : true }),
            tipo_lancamento: Yup.string().nullable().test('Tipo Lançamento ', 'Campo Obrigatório!', function (value) { return validarLancamento ? value : true }),
            categoria_lancamento: Yup.string().nullable().test('Categproa Lançamento ', 'Campo Obrigatório!', function (value) { return validarLancamento ? value : true }),
            previsao_vencimento_lancamento: Yup.string().nullable().test('Data', 'Data inválida!', function (value) { return validaData(this.parent.previsao_vencimento_lancamento) })
                .test('Data', 'Campo Obrigatório!', function (value) { return validarLancamento ? value : true }),
        }).shape({
            liquidacao: Yup.array()
                .of(
                    Yup.object().shape({
                        modo_liquidacao: Yup.object().nullable().when('valor', {
                            is: () => liquidar && !parcelado_lancamento ? (liquidacao && !liquidacao[index]?.modo_liquidacao) : (!parcelado_lancamento && (idMovimento ? validarLancamento : false) && (liquidacao && !liquidacao[0].modo_liquidacao || (liquidacao && !liquidacao[index]?.modo_liquidacao && liquidacao[index]?.valor))),
                            then: Yup.object().required(),
                            otherwise: Yup.object().nullable()
                        }),
                        valor: Yup.string().nullable()
                        // .test('Valor Liquidação', 'Campo Obrigatório!', function (value) {
                        //     const index = this.path.replace(/[^0-9]+/g, '');
                        //     return liquidar ? !(liquidacao && !liquidacao[index]?.valor) : (!parcelado_lancamento && (idMovimento ? validarLancamento : false) && (liquidacao.length - 1 != index || parseInt(index) === 0) ? value : true)
                        // })
                        ,
                        data_liquidacao: Yup.string().nullable().test('Data Liquidação', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return liquidar && !parcelado_lancamento ? !(liquidacao && !liquidacao[index]?.data_liquidacao) : (!parcelado_lancamento && (idMovimento ? validarLancamento : false) && (liquidacao[index].modo_liquidacao || parseInt(index) === 0) ? value : true)

                        }),
                    })
                ),
            liquidacao_parcelado: Yup.array()
                .of(
                    Yup.object().shape({
                        modo_liquidacao: Yup.object().nullable().test('Modo Liquidação', 'Campo Obrigatório!', function (value) {

                            const index = this.path.replace(/[^0-9]+/g, '');
                            // return !((!value && parcelado_lancamento)
                            //     || (!liquidacao_parcelado[index]?.modo_liquidacao && liquidacao_parcelado[index]?.data_liquidacao))
                            return parcelado_lancamento && validarLancamento && idMovimento && (liquidacao_parcelado[index]?.data_liquidacao || parseInt(index) === 0) ? value : true

                        }),
                        data: Yup.string().nullable().test('Data Prevista', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(!value && parcelado_lancamento && validarLancamento)
                        }),
                        data_liquidacao: Yup.string().nullable().test('Data Liquidação', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return parcelado_lancamento && validarLancamento && idMovimento && (liquidacao_parcelado[index].liquidado || parseInt(index) === 0) ? value : true

                        }),
                        valor_liquidacao: Yup.string().nullable().test('Valor Liquidação', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(!convertValueToFloat(value) && parcelado_lancamento && validarLancamento)
                        }), // these constraints take precedence
                    })
                ),
            liquida_parcela: Yup.object().shape({
                data: Yup.string().nullable().test('Data', 'Campo Obrigatório!', value => parcelado_lancamento && validarLancamento ? value : true),
                periodicidade: Yup.object().nullable().test('Periodicidade', 'Campo Obrigatório!', value => parcelado_lancamento && validarLancamento ? value : true),
                parcela: Yup.string().nullable().test('Número', 'Campo Obrigatório!', value => parcelado_lancamento && validarLancamento ? value : true),
                valor: Yup.string().nullable().test('Valor', 'Campo Obrigatório!', value => parcelado_lancamento && validarLancamento ? !(Number.isNaN(convertValueToFloat(value))) : true),
            })
        }, ['data', 'periodicidade', 'numero_parcela', 'valor'])
    })

    useEffect(_ => {
        setIdMovimento(idMovimentoFixo)
    }, [idMovimentoFixo])

    useEffect(() => {
        var objListas = {
            liquidacao: [{ modo_liquidacao: undefined, data: undefined, valor_liquidacao: undefined, parcelado: false, periodicidade: undefined, parcela: undefined, valor_antigo: 0 }],
            liquidacao_parcelado: [{ modo_liquidacao: undefined, data: undefined, valor_liquidacao: undefined, parcelado: false, periodicidade: undefined, parcela: undefined, valor_antigo: 0 }],
        }
        if (id && !props.tabLancamento) {
            Promise.resolve(get(`/financeiro/lancamento/${id}`)).then(val => {
                if (val.length === 0)
                    props.history.push('/financeiro/lancamento/consulta')
                else {
                    setLiquidar(val.liquidacao.length > 0 ? true : false)
                    val.valor_geral_lancamento = val.valor_total_formatado
                    val.parcelado_lancamento = false
                    val.liquidacao = [...val?.liquidacao.map(x => ({ alteracao: true, liquidar: false, ...x }))];
                    val.liquidacao_parcelado = [...val.liquidacao_parcelado.map(x => ({ alteracao: true, ...x }))];
                    val.tipo_lancamento = { value: 1, label: 'RECEITA' }

                    setValoresIniciais(val)
                }
                // setIdAtendimento(val.atendimento?.value)
            })
        }
        else {
            setValoresIniciais({
                arrayLancamentos: [],
                atendimento_lancamento: "",
                cliente_lancamento: "",
                valor_geral_lancamento: "",
                estabelecimento_saude: "",
                plano_contas_lancamento: "",
                conta_bancaria_lancamento: "",
                tipo_lancamento: { value: 1, label: 'RECEITA' },
                descricao_lancamento: "",
                tipo_documento_lancamento: "",
                nota_fiscal_lancamento: "",
                previsao_vencimento_lancamento: moment().format('DD/MM/YYYY'),
                parcelado_lancamento: false,
                liquidacao: [{ modo_liquidacao: undefined, data_liquidacao: moment().format('DD/MM/YYYY'), valor_liquidacao: undefined, parcelado: false, periodicidade: undefined, parcela: undefined, valor_antigo: 0, valor_original: 0 }],
                liquidacoes: [],
                alterando_linha_lancamento: false
                // liquidacao_parcelado: [{ categoria: undefined, cliente: undefined, data_liquidacao: undefined, liquidado: false, data_liquidado: undefined, valor: undefined }],

            })
        }

    }, [id])

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacaoInicial}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => { refLancamento.current.validarSubmit(values, propsFormik) }}>
            {propsFormik => {
                return (<Form>
                    <Lancamento ref={refLancamento} history={props.history} setValidarLancamento={setValidarLancamento} tabLancamento={props.tabLancamento} liquidar={liquidar}
                        setLiquidar={setLiquidar} />
                </Form>)
            }}
        </Formik>
    )
}

const Lancamento = React.forwardRef((props, ref) => {
    const refLiquidacao = useRef(null)
    const propsFormik = useFormikContext()
    let { id } = useParams()


    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [dataMovimento, setDataMovimento] = useState()
    const [msgValidacao, setMsgValidacao] = useState([])
    const [showModalValidaGuiaBaixada, setShowModalValidaGuiaBaixada] = useState(false)
    const [dataArquivos, setDataArquivos] = useState(null)
    const [limparArquivos, setLimparArquivos] = useState(false)

    const [validacao] = useState(
        Yup.lazy(({ arrayLancamentos }) => Yup.object({
            cliente: Yup.string().nullable(),
            tipo_lancamento: Yup.string().nullable(),
            categoria: Yup.string().nullable(),
            previsao_vencimento: Yup.string().nullable()
        }).shape({
            arrayLancamentos: Yup.array().nullable().test('Lançamento', 'Insira ao menos 1 Lançamento', function (value) {
                if (!id)
                    return this.parent?.arrayLancamentos && this.parent?.arrayLancamentos[0]?.cliente !== undefined
                return true
            })

        })
        )
    )

    const [showModalDados, setShowModalDados] = useState(false)
    const [idAtendimento, setIdAtendimento] = useState()
    const history = useHistory()
    const [idMovimento] = useState(history.location.state && history.location.state.idMovimento ? history.location.state.idMovimento : null)
    const manter = [{ name: 'categoria_lancamento' }, { name: 'tipo_lancamento' }]
    const inputValorLancamento = useRef();
    const [refAtendimento, setRefAtendimento] = useState({})
    const [adicionando, setAdicionando] = useState(false)

    if (ref) {
        ref.current = {
            validarSubmit: (values, propsFormik) => validarSubmit(values, propsFormik),
            valoresIniciais: valoresIniciais
        }
    }

    const Submit = (values, propsFormik) => {

        setSalvando(true)
        values.movimento_financeiro_id = idMovimento //ID MOVIMENTO FINANCEIRO
        if (!idMovimento) {
            if (values.parcelado_lancamento) {
                values.arrayLancamentos = [values?.liquidacao_parcelado?.map(itemLiquidado => ({
                    id: null, data: itemLiquidado.data || null, cliente: values?.cliente_lancamento, socio: values.socio, estabelecimento_saude: values?.estabelecimento_saude || null, valor: convertValueToFloat(values.valor_geral_lancamento),
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: values?.nota_fiscal_lancamento, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: values?.conta_bancaria_lancamento?.value, categoria_id: values?.categoria_lancamento?.value, tipo_lancamento_id: values?.tipo_lancamento?.value, tipo_lancamento_descricao: values?.tipo_lancamento?.label, descricao: values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: values?.tipo_documento_lancamento?.value, data_prevista: values?.previsao_vencimento_lancamento, tipo_lancamento: values?.tipo_lancamento, categoria: values?.categoria_lancamento, atendimento: values?.atendimento_lancamento,
                    parcela: values?.liquida_parcela?.parcela, conta_bancaria: values?.conta_bancaria_lancamento, conta_bancaria_empresa: values?.conta_bancaria_lancamento_empresa, tipo_documento: values?.tipo_documento_lancamento, periodicidade: values?.liquida_parcela?.periodicidade, valor_total_liquidacao: null, arquivos: dataArquivos, empresa: values?.empresa, anotacao: itemLiquidado?.anotacoes || itemLiquidado?.anotacao
                }))[0]].slice()
                values.liquidacoes = [values?.liquidacao_parcelado.map(itemLiquidado => ({
                    id_liquidacao: itemLiquidado.id_liquidacao, data: itemLiquidado.data, cliente: values?.cliente_lancamento, socio: values.socio, estabelecimento_saude: values?.estabelecimento_saude || null, valor: convertValueToFloat(itemLiquidado?.valor_liquidacao),
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: values?.nota_fiscal_lancamento, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: values?.conta_bancaria_lancamento?.value, categoria_id: values?.categoria_lancamento?.value, tipo_lancamento_id: values?.tipo_lancamento?.value, descricao: values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: values?.tipo_documento_lancamento?.value, data_prevista: itemLiquidado?.data, liquidado: itemLiquidado?.liquidado, modo_liquidacao: itemLiquidado?.modo_liquidacao, arquivos: dataArquivos,
                    acrescimo: itemLiquidado?.acrescimo, desconto: itemLiquidado?.desconto, juros_multa: itemLiquidado?.juros_multa, imposto_nfse: itemLiquidado?.imposto_nfse, glosas: itemLiquidado?.glosas, outros_impostos: itemLiquidado?.outros_impostos, anotacao: itemLiquidado?.anotacoes || itemLiquidado?.anotacao

                }))]

            } else {
                values.arrayLancamentos = [values?.liquidacao?.map(itemLiquidado => ({
                    id: null, data: itemLiquidado.data || null, cliente: values?.cliente_lancamento, socio: values.socio, estabelecimento_saude: values?.estabelecimento_saude || null, valor: convertValueToFloat(values.valor_geral_lancamento),
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: values?.nota_fiscal_lancamento, parcelado: false, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: values?.conta_bancaria_lancamento?.value, categoria_id: values?.categoria_lancamento?.value, tipo_lancamento_id: values?.tipo_lancamento?.value, tipo_lancamento_descricao: values?.tipo_lancamento?.label, descricao: values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: values?.tipo_documento_lancamento?.value, data_prevista: values?.previsao_vencimento_lancamento, tipo_lancamento: values?.tipo_lancamento, categoria: values?.categoria_lancamento, atendimento: values?.atendimento_lancamento,
                    parcela: values?.liquida_parcela?.parcela, conta_bancaria: values?.conta_bancaria_lancamento, conta_bancaria_empresa: values?.conta_bancaria_lancamento_empresa, tipo_documento: values?.tipo_documento_lancamento, periodicidade: values?.liquida_parcela?.periodicidade, valor_total_liquidacao: null, arquivos: dataArquivos, empresa: values?.empresa, anotacao: itemLiquidado?.anotacoes || itemLiquidado?.anotacao
                }))[0]].slice()
                values.liquidacoes = [values?.liquidacao.map(itemLiquidado => ({
                    id_liquidacao: itemLiquidado.id_liquidacao, data: itemLiquidado.data, cliente: values?.cliente_lancamento, socio: values.socio, estabelecimento_saude: values?.estabelecimento_saude || null, valor: convertValueToFloat(itemLiquidado?.valor_liquidacao || itemLiquidado.valor || 0),
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: values?.nota_fiscal_lancamento, parcelado: false, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: values?.conta_bancaria_lancamento?.value, categoria_id: values?.categoria_lancamento?.value, tipo_lancamento_id: values?.tipo_lancamento?.value, descricao: values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: values?.tipo_documento_lancamento?.value, data_prevista: itemLiquidado?.data, liquidado: itemLiquidado?.liquidado, modo_liquidacao: itemLiquidado?.modo_liquidacao, arquivos: dataArquivos,
                    acrescimo: itemLiquidado?.acrescimo, desconto: itemLiquidado?.desconto, juros_multa: itemLiquidado?.juros_multa, imposto_nfse: itemLiquidado?.imposto_nfse, glosas: itemLiquidado?.glosas, outros_impostos: itemLiquidado?.outros_impostos,
                    anotacao: itemLiquidado?.anotacoes || itemLiquidado?.anotacao
                }))]
            }
        }
        if (id)
            values['arquivos'] = dataArquivos

        get('/financeiro/statuslancamento', { id: id || 0 }).then(function (val) {
            if (val && val.status_id == '2') {
                mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado.')
                setSalvando(false)
            }
            else
                salvar(
                    { ...values },
                    '/financeiro/lancamento',
                    obj => {
                        if (props.modal) {
                            if (salvarENovo) {
                                propsFormik.resetForm({})
                                manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                                setSalvando(false)
                                setSalvarENovo(false)
                                setIdAtendimento(null)
                            }
                            else {
                                props.setItemSelecionado({ value: obj.id, label: obj.descricao })
                                props.setShow && props.setShow(false)
                            }
                        }
                        else {
                            mostrarToast('sucesso', mensagens.sucesso)
                            if (salvarENovo) {
                                setShowModalValidaGuiaBaixada(false)
                                propsFormik.resetForm({})
                                manter.map(x => propsFormik.setFieldValue(x.name, values[x.name]))
                                setSalvando(false)
                                document.getElementsByName('atendimento_lancamento')[0].focus()
                                setIdAtendimento(null)
                            }
                            else
                                idMovimento ? props.history.push('/financeiro/movimento/consulta') : props.history.push('/financeiro/lancamento/consulta')

                        }
                    },
                    () => setSalvando(false))
        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const validarSubmit = (values, propsFormik) => {
        // if (values.arrayLancamentos && values.arrayLancamentos.length > 0) {
        //     props.setValidarLancamento(false)
        // }
        if (!idMovimento) {
            if (values.parcelado_lancamento) {
                values.arrayLancamentos = [values?.liquidacao_parcelado?.map(itemLiquidado => ({
                    id: null, data: itemLiquidado.data || null, cliente: values?.cliente_lancamento, socio: values.socio, estabelecimento_saude: values?.estabelecimento_saude || null, valor: values.valor_geral_lancamento,
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: values?.nota_fiscal_lancamento, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: values?.conta_bancaria_lancamento?.value, categoria_id: values?.categoria_lancamento?.value, tipo_lancamento_id: values?.tipo_lancamento?.value, tipo_lancamento_descricao: values?.tipo_lancamento?.label, descricao: values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: values?.tipo_documento_lancamento?.value, data_prevista: values?.previsao_vencimento_lancamento, tipo_lancamento: values?.tipo_lancamento, categoria: values?.categoria_lancamento, atendimento: values?.atendimento_lancamento,
                    parcela: values?.liquida_parcela?.parcela, conta_bancaria: values?.conta_bancaria_lancamento, conta_bancaria_empresa: values?.conta_bancaria_lancamento_empresa, tipo_documento: values?.tipo_documento_lancamento, periodicidade: values?.liquida_parcela?.periodicidade, valor_total_liquidacao: null, empresa: values?.empresa
                }))[0]].slice()

            } else {
                values.arrayLancamentos = [values?.liquidacao?.map(itemLiquidado => ({
                    id: null, data: itemLiquidado.data || null, cliente: values?.cliente_lancamento, socio: values.socio, estabelecimento_saude: values?.estabelecimento_saude || null, valor: values.valor_geral_lancamento,
                    data_liquidacao: itemLiquidado?.data_liquidacao, nfse: values?.nota_fiscal_lancamento, parcelado: false, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: values?.liquida_parcela?.periodicidade?.value,
                    conta_bancaria_id: values?.conta_bancaria_lancamento?.value, categoria_id: values?.categoria_lancamento?.value, tipo_lancamento_id: values?.tipo_lancamento?.value, tipo_lancamento_descricao: values?.tipo_lancamento?.label, descricao: values?.descricao_lancamento,
                    tipo_documento_movimento_financeiro_id: values?.tipo_documento_lancamento?.value, data_prevista: values?.previsao_vencimento_lancamento, tipo_lancamento: values?.tipo_lancamento, categoria: values?.categoria_lancamento, atendimento: values?.atendimento_lancamento,
                    parcela: values?.liquida_parcela?.parcela, conta_bancaria: values?.conta_bancaria_lancamento, conta_bancaria_empresa: values?.conta_bancaria_lancamento_empresa, tipo_documento: values?.tipo_documento_lancamento, periodicidade: values?.liquida_parcela?.periodicidade, valor_total_liquidacao: null, empresa: values?.empresa
                }))[0]].slice()
            }
        }
        setSalvando(true)
        //VALIDA RETENCAO/ACRESCIMOS JUNTO 
        Promise.resolve(validar('/financeiro/validaGuiaBaixada', { arrayLancamentos: values?.arrayLancamentos?.map(x => ({ atendimento: x?.atendimento, id: x?.id })), liquidacao: !values.parcelado ? values.liquidacao : values.liquidacao_parcelado.filter(x => x.liquidado), valor: convertValueToFloat(values.valor_geral_lancamento) }, () => Submit(values, propsFormik), (e) => {
            setShowModalValidaGuiaBaixada(true)
            setMsgValidacao(<><div dangerouslySetInnerHTML={{ __html: e?.response && e?.response?.data?.alertas.map(x => x.replace(/"/g, '') + '<br/><br/>').join('') }} /></>)
            setSalvando(false)
        }))
    }

    useEffect(() => {
        let data_movimento = null
        if (!id && idMovimento) {
            Promise.resolve(get('/financeiro/informacoesmovimento', { id: idMovimento }).then((item) => {
                data_movimento = item[0].data
                setDataMovimento(data_movimento)
                propsFormik.setFieldValue('atendimento_lancamento', '')
                propsFormik.setFieldValue('previsao_vencimento_lancamento', data_movimento)
                propsFormik.setFieldValue('parcelado_lancamento', false)
                propsFormik.setFieldValue('tipo_lancamento', { value: 1, label: 'RECEITA' })
                propsFormik.setFieldValue('liquidacao', [{ modo_liquidacao: undefined, data: data_movimento, valor_liquidacao: undefined, parcelado: false, periodicidade: undefined, parcela: undefined, data_liquidacao: data_movimento, valor_original: 0 }])
            }).catch((e) => { console.log(e) }))
            propsFormik.setFieldValue('tipo_lancamento', { value: 1, label: 'RECEITA' })

        }
    }, [idMovimento])


    useEffect(() => {
        var objListas = {
            liquidacao: [{ modo_liquidacao: undefined, data: undefined, valor_liquidacao: undefined, parcelado: false, periodicidade: undefined, parcela: undefined }],
            liquidacao_parcelado: [{ modo_liquidacao: undefined, data: undefined, valor_liquidacao: undefined, parcelado: false, periodicidade: undefined, parcela: undefined }],
        }
        if (id && !props.tabLancamento) {

            Promise.resolve(get(`/financeiro/lancamento/${id}`)).then(val => {
                if (val.length === 0)
                    props.history.push('/financeiro/movimento/consulta')
                else {
                    setDataArquivos(val.arquivos)
                    val.valor_geral_lancamento = val.valor_total_formatado
                    val.parcelado_lancamento = false
                    val.liquidacao = [...val?.liquidacao.map(x => ({ alteracao: true, liquidar: false, ...x })), ...objListas.liquidacao];
                    val.valor_original = convertValueToFloat(val.valor_total_formatado)
                    val.liquidacao_parcelado = [...val.liquidacao_parcelado.map(x => ({ alteracao: true, ...x })), ...objListas.liquidacao_parcelado];
                    val.tipo_lancamento = { value: 1, label: 'RECEITA' }
                    setValoresIniciais(val)
                    setIdAtendimento(val?.atendimento)
                }
            })
        } else {
            setDataArquivos([])
            setValoresIniciais({
                atendimento_lancamento: "",
                cliente_lancamento: "",
                valor_geral_lancamento: "",
                tipo_lancamento: { value: 1, label: 'RECEITA' },
                estabelecimento_saude: "",
                plano_contas_lancamento: "",
                conta_bancaria_lancamento: "",
                descricao_lancamento: "",
                tipo_documento_lancamento: "",
                nota_fiscal_lancamento: "",
                previsao_vencimento_lancamento: propsFormik.values.data,
                parcelado_lancamento: false,
                liquidacao: [{ modo_liquidacao: undefined, data_liquidacao: propsFormik.values.data || moment().format('DD/MM/YYYY'), valor_liquidacao: undefined, parcelado: false, periodicidade: undefined, parcela: undefined }],
                liquidacoes: [],
                alterando_linha_lancamento: false,
                empresa: ''
                // liquidacao_parcelado: [{ categoria: undefined, cliente: undefined, data_liquidacao: undefined, liquidado: false, data_liquidado: undefined, valor: undefined }],

            })
            propsFormik.setValues(propsFormik.values)

            if (propsFormik?.values?.atendimento_lancamento?.value)
                setIdAtendimento(propsFormik?.values?.atendimento_lancamento?.value)

        }

    }, [id])
    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>faturamento</span>,
        () => <Link className='link-active' to={'/faturamento/tabelaProcedimento/consulta'}>Tabela Procedimento</Link>,
    ]

    const verificaValidacoes = (values, propsFormik) => {

        if (idMovimento) {
            if (!values.arrayLancamentos || (values.arrayLancamentos && values.arrayLancamentos.length < 1)) {
                mostrarToast('erro', 'Adicione ao menos um Lançamento')
            }
            else if (values.arrayLancamentos && values.arrayLancamentos.length > 0) {
                if ((values.liquidacao && values.liquidacao[0] && values.liquidacao[0].modo_liquidacao)
                    ||
                    (values.liquidacao_parcelado && values.liquidacao_parcelado[0] && values.liquidacao_parcelado[0].modo_liquidacao)
                    ||
                    (values.liquida_parcela && values.liquida_parcela.parcela && values.liquida_parcela.periodicidade)) {
                    mostrarToast('erro', 'Adicione o Lançamento atual antes de continuar')
                } else {
                    props.setValidarLancamento(propsFormik.values.arrayLancamentos.filter(x => !x.id).length > 0 ? false : true)//VALIDAÇÃO FEITA PARA LANÇAMENTOS DENTRO DO MOVIMENTO ONDE SERÁ OBRIGATÓRIO TER PELO MENOS UMA LIQUIDAÇÃO
                    setTimeout(_ => propsFormik.submitForm(values, propsFormik), 50)
                }
            }
        } else {
            propsFormik.submitForm(values, propsFormik)

        }
    }


    return (propsFormik ? <>
        {/* <Rascunho modulo={116} history={history} formik={propsFormik} /> */}
        {idMovimento && !props.tabLancamento ?
            <InformacoesMovimento className='' titulo={'Informações Movimento Financeiro'} icone={'icon-Dec'} idMovimento={idMovimento} /> : <></>}
        {props.tabLancamento === true ? <></> :
            <div className="mt-40">

                <HeaderCadastro titulo={'Lançamentos Movimento '} classeChildren={'space-between-100 col-12'}>
                </HeaderCadastro>
            </div>
        }
        <div className={props.modal && props.modal || props.tabLancamentocss && props.tabLancamentocss || 'card mb-20'}>
            <div className={props.tabLancamentocss + 'tab-p-1 card-body' || 'card-body'}>
                <div className='row'>
                    <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' />

                    <InputAtendimentoLancamentoFinanceiro label='Atendimento' autoFocus ref={refAtendimento} adicionando={adicionando} name="atendimento_lancamento" placeholder="Atendimento" propsFormik={propsFormik}
                        onChange={(value) => {

                            console.log('value: ', value);
                            propsFormik.setFieldValue('atendimento_lancamento', value)
                            if (value != null)
                                setIdAtendimento(value.value)
                            else
                                setIdAtendimento(0)
                        }} tamanho={!props.modal && 'col-lg-3'} classeTooltip={props.modal && 'tooltip-input-modal'} salvarENovo={salvarENovo} focusSubmit />

                    <InputClienteFinanceiro label='Cliente' name="cliente_lancamento" placeholder="Cliente" propsFormik={propsFormik} obrigatorio={true}
                        botaoVisivel={true} array={true} tamanho={!props.modal && 'col-lg-5'} classeTooltip={props.modal && 'tooltip-input-modal'} onChange={(option) => {
                            propsFormik.setFieldValue('conta_bancaria_lancamento', null)
                            propsFormik.setFieldValue('cliente_lancamento', option)

                        }} />

                    <InputCurrency onUpdate={val => {
                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                        inputValorLancamento.current = valor
                    }}
                        label={'Valor R$'} name={`valor_geral_lancamento`}
                        value={inputValorLancamento.current || propsFormik?.values?.valor_geral_lancamento || propsFormik?.values?.valor_geral_lancamento_formatado}
                        classeTooltip={'tooltip-input-modal'}
                        propsFormik={propsFormik}
                        onBlur={(e) => {
                            if (inputValorLancamento.current || inputValorLancamento.current === 0) {
                                propsFormik.values.valor_geral_lancamento = inputValorLancamento.current && inputValorLancamento.current.toString().trim();
                                propsFormik.values.valor_geral_lancamento_formatado = formatarMoeda(inputValorLancamento.current && convertValueToFloat(inputValorLancamento.current.toString()));
                                propsFormik.values.valor_geral_formatado = formatarMoeda(inputValorLancamento.current && convertValueToFloat(inputValorLancamento.current.toString()));
                            }
                            if (propsFormik.values.parcelado_lancamento) {

                                // propsFormik.setFieldValue(`liquidacao[0].valor`, propsFormik.values.liquidacao[0].valor || e.currentTarget.value)
                                propsFormik.setFieldValue(`liquidacao[0].valor`, convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value))
                                propsFormik.setFieldValue(`liquidacao[0].valor_liquidacao_formatado`, convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value))
                                propsFormik.setFieldValue(`liquidacao[0].valor_original`, convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value))
                                propsFormik.setFieldValue(`liquida_parcela.valor_formatado`, formatarMoeda(convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value)))
                                propsFormik.setFieldValue(`liquida_parcela.valor`, convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value))
                            }
                            else {
                                if (propsFormik.values.id_lancamento_alteracao === null || propsFormik.values.id_lancamento_alteracao === undefined) {//VAI SETAR O VALOR DO LANÇAMENTO PARA A PRIMEIRA POSIÇÃO DA LIQUIDAÇÃO SOMENTE NO CADASTRO E QUANDO NÃO HÁ MAIS DE UM MODO DE LIQUIDAÇÃO REFERENTE A CARTA UNICO-717
                                    propsFormik.setFieldValue(`liquidacao[0].valor`, convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value))
                                    propsFormik.setFieldValue(`liquidacao[0].valor_liquidacao_formatado`, convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value))
                                    propsFormik.setFieldValue(`liquidacao[0].valor_liquidacao`, convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value))
                                    propsFormik.setFieldValue(`liquida_parcela.valor`, convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value))
                                    propsFormik.setFieldValue(`liquida_parcela.valor_formatado`, formatarMoeda(convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value)))
                                    propsFormik.setFieldValue(`liquidacao[0].valor_original`, convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value))


                                }
                                else if (!(propsFormik.values.liquidacao && propsFormik.values.liquidacao[0] && propsFormik.values.liquidacao[0].modo_liquidacao)) {

                                    propsFormik.setFieldValue(`liquidacao[0].valor_liquidacao`, convertValueToFloat(propsFormik.values.liquidacao && propsFormik.values.liquidacao[0] && propsFormik.values.liquidacao[0].valor_liquidacao || e.currentTarget.value))
                                    propsFormik.setFieldValue(`liquidacao[0].valor`, convertValueToFloat(propsFormik.values.liquidacao && propsFormik.values.liquidacao[0] && propsFormik.values.liquidacao[0].valor || e.currentTarget.value))
                                    propsFormik.setFieldValue(`liquidacao[0].valor_original`, convertValueToFloat(propsFormik.values.liquidacao && propsFormik.values.liquidacao[0] && propsFormik.values.liquidacao[0].valor || e.currentTarget.value))

                                }

                            }
                            // propsFormik.values.liquidacao[0].valor = 150
                            inputValorLancamento.current = null;
                        }}
                        classDiv='col-3 col-xl-3col-lg-3 pr-0' obrigatorio={true} />
                    {/* <InputValor obrigatorio label='Valor' name={`valor_geral`} value={propsFormik.values.liquidacao && propsFormik.values.liquidacao[0].valor || propsFormik.values.valor_geral} classeTooltip={'tooltip-input-modal'} classDiv='col-lg-3' propsFormik={propsFormik} isDisable={false} max={100000000} onBlur={(e) => {
                                    if (propsFormik.values.parcelado)
                                        propsFormik.setFieldValue(`liquidacao[0].valor`, propsFormik.values.liquidacao[0].valor || e.currentTarget.value)
                                    else
                                        propsFormik.setFieldValue(`liquidacao[0].valor_liquidacao`, propsFormik.values.liquidacao[0].valor_liquidacao || e.currentTarget.value)
                                    // propsFormik.values.liquidacao[0].valor = 150
                                }} /> */}
                    <BtnAcao type={'button'} icone="icon-File-TextImage focus-button" texto="Dados Atendimento" action={_ => { setShowModalDados(true) }} ajusta_padding={20} />

                    <InputEstabelecimentoSaude label='Estabelecimento Saúde' name="estabelecimento_saude" placeholder="Estabelecimento Saúde" propsFormik={propsFormik}
                        tamanho={!props.modal && 'col-lg-4'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                    <InputTipoLancamento label='Tipo Lançamento' name="tipo_lancamento" placeholder="Tipo Lançamento" propsFormik={propsFormik} obrigatorio={true} isClearable={false}
                        tamanho={!props.modal && 'col-lg-3'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                    {/* <InputPlanoContas label='Categoria' name="categoria_lancamento" placeholder="Categoria" propsFormik={propsFormik} obrigatorio={true}
                        tamanho={!props.modal && 'col-lg-5'} classeTooltip={props.modal && 'tooltip-input-modal'} botaoVisivel={true} /> */}
                    <InputCategoriaAlternativo setarSeForUm={props.tabLancamento || !id} label='Categoria' name="categoria_lancamento" placeholder="Categoria" propsFormik={propsFormik} obrigatorio={true}
                        tamanho={!props.modal && 'col-lg-5'} array={true} classeTooltip={props.modal && 'tooltip-input-modal'} botaoVisivel={true} />
                    <InputEmpresaClienteLancamento label='Empresa' name="empresa" placeholder="Empresa" propsFormik={propsFormik}
                        tamanho={!props.modal && 'col-xl-2 col-lg-6 col-md-6'} classeTooltip={props.modal && 'tooltip-input-modal'} onChange={(option) => {
                            propsFormik.setFieldValue('conta_bancaria_lancamento_empresa', null)
                            propsFormik.setFieldValue('empresa', option)

                        }} />
                    <InputContaBancariaEmpresa label='Conta Bancária Empresa' name="conta_bancaria_lancamento_empresa" placeholder="Conta Bancária Empresa" propsFormik={propsFormik} botaoVisivel={propsFormik.values?.empresa}
                        modal tamanho={!props.modal && 'col-lg-3'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                    <InputContaBancariaCliente label='Conta Bancária Cliente' name="conta_bancaria_lancamento" placeholder="Conta Bancária Cliente" propsFormik={propsFormik} botaoVisivel={propsFormik.values?.cliente_lancamento}
                        modal tamanho={!props.modal && 'col-lg-3'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                    <InputPadrao label='Descrição' name='descricao_lancamento' type='text' placeholder='Descrição' classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-4'} />
                    <InputTipoDocMovimento label='Tipo Documento' name="tipo_documento_lancamento" placeholder="Tipo Documento" propsFormik={propsFormik}
                        tamanho={!props.modal && 'col-lg-3'} classeTooltip={props.modal && 'tooltip-input-modal'} botaoVisivel={true} />

                    <InputSwitch label='Parcelado?' propsFormik={propsFormik} disabled={propsFormik.values.tipo_lancamento?.value === 2} onChange={(option) => {
                        propsFormik.setFieldValue('parcelado_lancamento', option)

                        propsFormik.setFieldValue(`liquidacao`, [{ modo_liquidacao: null, data: propsFormik.values.previsao_vencimento_lancamento, valor: formatarMoeda(convertValueToFloat(propsFormik.values.valor_geral_lancamento).toFixed(2)) || undefined, periodicidade: null, parcela: null }])//Limpar primeira linha liquidação
                        propsFormik.setFieldValue(`liquida_parcela`, { periodicidade: null, data: propsFormik.values.previsao_vencimento_lancamento, valor: formatarMoeda(convertValueToFloat(propsFormik.values.valor_geral_lancamento).toFixed(2)) || undefined, parcela: null })//Limpar primeira linha liquidação
                        propsFormik.setFieldValue(`liquidacao_parcelado`, [])//Limpar linhas do parcelado ao alterar status do input
                    }}
                        quebrarLinha classeDiv='form-group ajusta-switch ' name='parcelado_lancamento' size="md" tamanho={!props.modal && 'col-xl-1 col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />

                    <InputPadrao label='NFSe/Documento' name='nota_fiscal_lancamento' type='text' placeholder='NFSe/Documento' classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-3'} />

                    <InputDatePicker tamanho={'col-lg-2'} label="Previsão Vencimento" name="previsao_vencimento_lancamento" type="text" obrigatorio="true"
                        setarValor={value => {
                            propsFormik.setFieldValue("previsao_vencimento_lancamento", value)
                        }} classeTooltip={props.modal && 'tooltip-input-modal'} mostrarFeriado={true}
                        onBlurData={value => {
                            propsFormik.setFieldValue("liquidacao[0].data_liquidacao", value)
                            propsFormik.setFieldValue("liquida_parcela.data", value)

                            if (!propsFormik.values.parcelado_lancamento && propsFormik.values.liquidacao.length > 1)
                                refLiquidacao.current.atualizarDataLiquidacao(value)
                            if (props.setPreencheuPrevisao) {
                                if (value && moment(value, 'DD/MM/YYYY').isValid())
                                    props.setPreencheuPrevisao(true)
                                else
                                    props.setPreencheuPrevisao(false)
                            }


                        }}
                    />
                    {/* <InputEmpresa label={'Empresa'} placeholder='Empresa' classeTooltip={'tooltip-input-modal'}
                        name={`empresa`} tamanho="col-xl-2 col-lg-6 col-md-6"  /> */}


                    {dataArquivos != null ?
                        <InputArquivoS3
                            label='Arquivos'
                            name='arquivo'
                            placeholder='Arquivo'
                            tamanho='col-12'
                            diretorioS3='movimentofinanceiro/DDMMYYYY/'
                            modulo={MODULO["/financeiro/lancamento"]}
                            tamanhoMinimo={0}
                            tamanhoMaximoEmMB={300}
                            setDataArquivos={setDataArquivos}
                            dataArquivos={dataArquivos != null ? dataArquivos : []}
                            setIsSubmit={dataArquivos.length > 0 ? setSalvando : () => { }}
                            limparCampos={limparArquivos}
                        /> : <></>}

                    {/*Modal Lateral */}
                    <DadosAtendimento setShowModal={setShowModalDados} showModal={showModalDados} idAtendimento={idAtendimento} propsFormik={propsFormik} />

                    <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />

                </div>
            </div>
        </div>
        {/* <CadastroLiquidacao propsFormik={propsFormik} dataMovimento={dataMovimento} />
                    <CadastroLiquidacaoParcelado propsFormik={propsFormik} /> */}
        {/* <MovimentoLancamentos propsFormik={propsFormik}></MovimentoLancamentos> */}

        <CadastroLiquidacao ref={refLiquidacao} propsFormik={propsFormik} id={id} valoresIniciais={valoresIniciais} dataMovimento={dataMovimento} tabLancamento={props.tabLancamento} setIdAtendimento={setIdAtendimento} limparArquivos={(boleano) => setLimparArquivos(boleano)} dataArquivos={dataArquivos} idMovimento={idMovimento} dadosMovimentoLancamento={props.dadosMovimentoLancamento} setDadosMovimentoLancamento={props.setDadosMovimentoLancamento}
            setValidarLancamento={props.setValidarLancamento} refAtendimento={refAtendimento} setAdicionando={setAdicionando} liquidar={props.liquidar} setLiquidar={props.setLiquidar} />
        <CadastroLiquidacaoParcelado propsFormik={propsFormik} id={id} tabLancamento={props.tabLancamento} dadosMovimentoLancamento={props.dadosMovimentoLancamento} setIdAtendimento={setIdAtendimento} limparArquivos={() => setLimparArquivos(true)} dataArquivos={dataArquivos} setDadosMovimentoLancamento={props.setDadosMovimentoLancamento}
            setValidarLancamento={props.setValidarLancamento} idMovimento={idMovimento} setAdicionando={setAdicionando} />
        {(idMovimento || id) || props.tabLancamento ?
            <MovimentoLancamentos propsFormik={propsFormik} id={id} history={props.history} tabLancamento={props.tabLancamento} dadosMovimentoLancamento={props.dadosMovimentoLancamento} setDadosMovimentoLancamento={props.setDadosMovimentoLancamento} idMovimento={idMovimento} limparArquivos={(boleano) => setLimparArquivos(boleano)} setValorTotalLancamento={props.setValorTotalLancamento} setDataArquivos={setDataArquivos}></MovimentoLancamentos>
            : <></>}
        <div >
            {props.tabLancamento === true ?
                <></> :
                <div>
                    <div className='separator mt-40 mb-5'></div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Salvar' type='button' disabled={salvando} click={_ => { verificaValidacoes(propsFormik.values, propsFormik); setSalvarENovo(false) }} />
                        {((props.modal || !id) && ((props.tabLancamento && idMovimento) || (!props.tabLancamento && !idMovimento))) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => { verificaValidacoes(propsFormik.values, propsFormik); setSalvarENovo(true) }} idTooltip={'salvarENovo'} />
                        }
                    </div>
                </div>
            }
            <ModalDuasOpcoes showModal={showModalValidaGuiaBaixada} setShowModal={setShowModalValidaGuiaBaixada}
                funcaoOpcao2={() => { Submit(propsFormik.values, propsFormik); setShowModalValidaGuiaBaixada(false) }}
                funcaoOpcao1={() => { setShowModalValidaGuiaBaixada(false) }}
                esconderFechar mensagem={<>{msgValidacao}<br /></>}
                textoOpcao1={'Fechar'}
                classeOpcao1={'btn-sm btn-primary-modal simples'}
                textoOpcao2={'Continuar'} />

        </div>
    </> : <></>)


})



export default CadastroLancamentoFinanceiro
export { Lancamento }