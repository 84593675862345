import React, { useState, useEffect, useRef } from 'react';
import { consultarRelatorio, get } from '../../../util/AcessoApi'
import { ConsultaRelatorio } from '../../../components/RelatorioPadrao'
import {
    InputTipoRelatorio, InputOperadoraSaude,
    InputEmpresa, InputTipoExecutante, InputEstabelecimentoSaude,
    InputTipoRepasse,
    InputTipoGuiaRecurso,
    InputTipoRelatorioPersonalizado
} from '../../../components/InputsAutoComplete'
import { InputDatePicker, InputTimePicker, InputMonthPicker } from '../../../components/Inputs'
import { BotaoPadrao } from '../../../components/Botoes'
import { Formik, Form } from 'formik'
import ContentLoader from 'react-content-loader'
import Yup, { validaData } from '../../../util/Validacoes'
import { getUsuario } from '../../../util/Configuracoes'
import moment from "moment"
import { Link } from 'react-router-dom';
import { convertValueToFloat, formatarMoeda } from '../../../util/Utilitarios';
import { ordenarMultiplo } from '../../../components/Ordenamentos';
import { mostrarToast } from '../../../components/Toasts';
import { getDistinctPropertyValues, ordenarArrayObj } from '../../../util/FuncoesComuns';
import { MODULOS_SISTEMA, RETENCOES } from '../../../util/Enums';
import { TableVirtuoso } from 'react-virtuoso'
import MensagemPadraoRelatorio from '../../../components/RelatorioEspecifico/RelatorioSemDados'
import { InputContrato, InputGuiaStatus } from '../../../components/InputsAutoCompleteV2';
import { relatorioEstabelecimentoEspecificoVirtuoso, relatorioEstabelecimentoEspecifico } from '../../../components/RelatorioEspecifico/RelatorioAuditoria';
import { InputPadrao, InputPadraoSimples } from '../../../components/InputsV2';


export default props => {
    const startOfMonth = moment().clone().format('DD/MM/YYYY')
    const endOfMonth = moment().clone().format('DD/MM/YYYY')
    const actualMonth = moment().clone().format('MM/YYYY')
    const actualYear = moment().clone().format('YYYY')
    const [filtros, setFiltros] = useState([])
    const [showModalFiltros, setShowModalFiltros] = useState(true)
    const [titulo, setTitulo] = useState('Relatório Resumo')
    const [dados, setDados] = useState([])
    const [relatorioSelecionado, setRelatorioSelecionado] = useState(0)
    const [valuesFiltros, setValuesFiltros] = useState({ tipo_relatorio: '', data_inicial: startOfMonth, data_final: endOfMonth, competencia: actualMonth, ano: actualYear, rotina: { value: 'false', label: 'Não' } })
    const [relatorioAtual, setRelatorioAtual] = useState({})
    const [carregando, setCarregando] = useState(false)
    const [carregandoFiltros, setCarregandoFiltros] = useState(false)
    const user = getUsuario()
    const ref = useRef(null)
    const [ordenamento, setOrdenamento] = useState([])
    const [ano, setAno] = useState()

    useEffect(() => {
        if (actualYear) {
            setAno(actualYear)
        }
    }, [actualYear])

    const tipoRelatorio = [
        { label: 'Relatório Apresentado x Recebido - Operadora Saúde', value: MODULOS_SISTEMA.RELATORIO_APRESENTADO_RECEBIDO_OPERADORA_SAUDE },
        { label: 'Apres. x Rec. Estabelecimento de Saúde', value: MODULOS_SISTEMA.RELATORIO_APRESENTADO_RECEBIDO_ESTABELECIMENTO_SAUDE },
        { label: 'Recebido Analítico Estabelecimento de Saúde (Guia)', value: MODULOS_SISTEMA.RELATORIO_APRES_RECEB_ANALITICO_ESTABELECIMENTO_SAUDE },
        { label: 'Recebido Paciente (Guia)', value: MODULOS_SISTEMA.RELATORIO_APRES_RECEB_PACIENTE_GUIA },
        { label: 'Guias não Baixadas (Anual)', value: MODULOS_SISTEMA.RELATORIO_ANUAL_GUIAS_NAO_BAIXADAS },
    ]

    // user.tipo !== 4 && tipoRelatorio.push({ label: 'Estabelecimento de Saúde Descoberto', value: 2 })

    const [validacao, setValidacao] = useState(Yup.object({
        data_inicial: Yup.string().nullable()
            .test('Data Inicial', 'Data inválida!', function (value) { return validaData(this.parent.data_inicial) }),
        data_final: Yup.string().nullable()
            .test('Data Final', 'Data inválida!', function (value) { return validaData(this.parent.data_final) })
    }))

    const filtrosPadrao = propsFormik => [
        <InputMonthPicker label="Competência" name="competencia" type="text"
            setarValor={value => { propsFormik.setFieldValue("competencia", value) }} classeTooltip={'tooltip-input-modal'} />,
        <InputMonthPicker label="Referência (Procedimento)" name="referencia" type="text"
            setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} />,
        <InputTipoRepasse label="Tipo Repasse" placeholder="Tipo Repasse" name="tipo_repasse" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputTipoExecutante label={'Tipo Executante'} placeholder='Tipo Executante' classeTooltip={'tooltip-input-modal'} name={'tipo_executante'} propsFormik={propsFormik} />,
        <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
        <InputEstabelecimentoSaude label="Estabelecimento de Saúde" placeholder="Estabelecimento de Saúde" name="estabelecimento_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputOperadoraSaude label="Operadora Sáude" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputDatePicker label="Data Inicial Pagamento" name="data_inicial_pagamento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_pagamento", val)} modal classeTooltip={'tooltip-input-modal'} />,
        <InputDatePicker label="Data Final Pagamento" name="data_final_pagamento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_pagamento", val)} modal classeTooltip={'tooltip-input-modal'} />,
    ]

    const filtrosEstabAnalitico = (propsFormik, tipoRepName) => [
        <InputMonthPicker label="Competência" name="competencia" type="text"
            setarValor={value => { propsFormik.setFieldValue("competencia", value) }} classeTooltip={'tooltip-input-modal'} />,
        <InputMonthPicker label="Referência (Procedimento)" name="referencia" type="text"
            setarValor={value => { propsFormik.setFieldValue("referencia", value) }} classeTooltip={'tooltip-input-modal'} />,
        <InputEstabelecimentoSaude label="Estabelecimento de Saúde" placeholder="Estabelecimento de Saúde" name="estabelecimento_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputOperadoraSaude label="Operadora Sáude" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputContrato label="Contrato" name="contrato_convenio" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputTipoGuiaRecurso label="Tipo Repasse" placeholder="Tipo Repasse" name={tipoRepName} propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
        <InputDatePicker label="Data Inicial Pagamento" name="data_inicial_pagamento" type="text" setarValor={val => propsFormik.setFieldValue("data_inicial_pagamento", val)} modal classeTooltip={'tooltip-input-modal'} />,
        <InputDatePicker label="Data Final Pagamento" name="data_final_pagamento" type="text" setarValor={val => propsFormik.setFieldValue("data_final_pagamento", val)} modal classeTooltip={'tooltip-input-modal'} />,
    ]

    const calculoValorTotal = (dadosAgr, arrayConfigCol) => {
        console.log('dadosAgr: ', dadosAgr);
        const valorTotalProduzido = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.total_produzido).map(x => convertValueToFloat(x.total_produzido)).reduce((ant, atual) => ant + atual, 0);
        const valorTotalRepassado = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.total_repassado).map(x => convertValueToFloat(x.total_repassado)).reduce((ant, atual) => ant + atual, 0);
        const valorTotalDiferenca = dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.filter(item => item.total_diferenca).map(x => convertValueToFloat(x.total_diferenca)).reduce((ant, atual) => ant + atual, 0);


        return (dadosAgr[0] && dadosAgr[0].data && dadosAgr[0].data.length > 0 ? <>
            <table>
                <tr><td colspan={dadosAgr[0].colunas ? dadosAgr[0].colunas.length : 0} className={'td-data pr-0'}></td></tr>
                <tr className={'tr-data text-right bold'}>
                    <td width={'30px'} className={'td-data pr-0'}></td>
                    <td width={'40%'} className={'td-data pr-0'}><span>Totais: </span></td>
                    {arrayConfigCol[0].filter(x => x.coluna === 'total_produzido' && x.visivel).length > 0 ? <td width={'10%'} className={'td-data pr-0'}><span className={'ml-2'}>{formatarMoeda(valorTotalProduzido)}</span></td> : <></>}
                    {arrayConfigCol[0].filter(x => x.coluna === 'total_repassado' && x.visivel).length > 0 ? <td width={'10%'} className={'td-data pr-0'}><span className={'ml-2'}>{formatarMoeda(valorTotalRepassado)}</span></td> : <></>}
                    {arrayConfigCol[0].filter(x => x.coluna === 'total_diferenca' && x.visivel).length > 0 ? <td width={'10%'} className={'td-data pr-0'}><span className={'ml-2'}>{formatarMoeda(valorTotalProduzido - valorTotalRepassado)}</span></td> : <></>}
                </tr>
            </table>
            {/* 
            <table style={{ marginTop: 25 }}>
                <td width={'43%'} style={{ verticalAlign: 'baseline' }}>
                    <table style={{ width: '100%' }}>
                        <tr className={'tr-data'}><td className='td-data' colSpan={3}><span className='bold' style={{ width: '100%' }}>Totais</span></td></tr>
                        <tr className='tr-data titulo-1'>
                            <td className='text-center bold td-data' width={'30%'}>Total Produzido</td>
                            <td className='text-center bold td-data' width={'30%'}>Total Repassado</td>
                            <td className='text-right bold td-data' width={'30%'}>Total Diferença</td>
                        </tr>
                        <tr className='tr-data tr-movimento-receita'>
                            <td className='text-center td-data'>{formatarMoeda(valorTotalProduzido)}</td>
                            <td className='text-center td-data'>{formatarMoeda(valorTotalRepassado)}</td>
                            <td className='text-right td-data'>{formatarMoeda(valorTotalProduzido - valorTotalRepassado)}</td>
                        </tr>


                    </table>
                </td>

            </table> */}

        </> : <></>)
    }

    useEffect(() => {
        if (relatorioAtual.data && relatorioAtual.data[0]) {
            let cloneRelatorioAtual = { ...relatorioAtual }
            const dados = cloneRelatorioAtual.data
            cloneRelatorioAtual.data = ordenarMultiplo(dados, ref.current.obterOrdenamentosMultiplos(0))
            setDados([{
                numerarLinha: true, colunas: cloneRelatorioAtual.colunas, data: dados, classeTr: cloneRelatorioAtual.classeTr,
                aposAgrupamento: cloneRelatorioAtual.aposAgrupamento, aposRelatorio: cloneRelatorioAtual.aposRelatorio,
                relatorioEspecifico: !!cloneRelatorioAtual.relatorioEspecifico
                    ? (dados, arrayConfigCol, configOrdenamento) => cloneRelatorioAtual.relatorioEspecifico(dados, arrayConfigCol, configOrdenamento) : null
            }])
            setRelatorioAtual(cloneRelatorioAtual)
        }
    }, [ordenamento])

    const relatoriosPorTipo = propsFormik => [
        {
            titulo: 'Relatório Apresentado x Recebido - Sintético Operadora de Saúde',
            tipo: MODULOS_SISTEMA.RELATORIO_APRESENTADO_RECEBIDO_OPERADORA_SAUDE,
            url: '/relatorio/apresentadorepassadooperadorasaude',
            // aposRelatorio: calculoValorTotal,
            campos: filtrosPadrao(propsFormik),
            // classeTr: 'linha-bottom-relatorio',
            colunas: [
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%' },
                { label: 'Operadora Saúde', name: 'operadora', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '50%' },
                { label: 'Valor Apresentado', name: 'total_produzido', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: 'Valor Recebido', name: 'total_repassado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: 'Diferença', name: 'total_diferenca', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: '% Recebido', name: 'percentual_repassado', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '5%' },
                { label: 'Tipo Recebimento', name: 'tipo_repasse', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '8%' },
            ],
            colunasCsv: [
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%' },
                { label: 'Operadora Saúde', name: 'operadora', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '50%' },
                { label: 'Valor Apresentado', name: 'total_produzido', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: 'Valor Recebido', name: 'total_repassado', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: 'Diferença', name: 'total_diferenca', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: '% Recebido', name: 'percentual_repassado', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '5%' },
                { label: 'Tipo Recebimento', name: 'tipo_repasse', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '8%' },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Tipo Repasse', name: 'tipo_repasse', name_coluna: 'tipo_repasse' },
                { label: 'Tipo Executante', name: 'tipo_executante', name_coluna: 'tipo_executante' },
                { label: 'Empresa', name: 'empresa', name_coluna: 'empresa' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Data Inicial Procedimento', name: 'data_inicial_pagamento', name_coluna: 'data_inicial_pagamento' },
                { label: 'Data Final Procedimento', name: 'data_final_pagamento', name_coluna: 'data_final_pagamento' },
            ]
        },
        {
            titulo: 'Relatório Apresentado x Recebido - Sintético Estabelecimento de Saúde',
            tipo: MODULOS_SISTEMA.RELATORIO_APRESENTADO_RECEBIDO_ESTABELECIMENTO_SAUDE,
            url: '/relatorio/apresentadorepassadoestabelecimentosaude',
            // aposRelatorio: calculoValorTotal,
            campos: filtrosPadrao(propsFormik),
            classeTr: 'linha-bottom-relatorio',
            colunas: [
                { label: 'Código', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '50%' },
                { label: 'Valor Apresentado', name: 'total_produzido', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: 'Valor Recebido', name: 'total_repassado', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: 'Diferença', name: 'total_diferenca', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: '% Recebido', name: 'percentual_repassado', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '5%' },
                { label: 'Tipo Recebimento', name: 'tipo_repasse', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '8%' },

            ],
            data: [],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Tipo Repasse', name: 'tipo_repasse', name_coluna: 'tipo_repasse' },
                { label: 'Tipo Executante', name: 'tipo_executante', name_coluna: 'tipo_executante' },
                { label: 'Empresa', name: 'empresa', name_coluna: 'empresa' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
            ]
        },
        {
            titulo: 'Recebido Analítico Estabelecimento de Saúde (Guia)',
            tipo: MODULOS_SISTEMA.RELATORIO_APRES_RECEB_ANALITICO_ESTABELECIMENTO_SAUDE,
            url: '/relatorio/analiticorepassadoestabelecimentosaude',
            campos: filtrosEstabAnalitico(propsFormik, "tipo_pagamento_estab"),
            classeTr: 'linha-bottom-relatorio',
            coluna_agrupar: 'procedimentos',
            agrupado: true,
            colunas: [
                { label: 'Código', name: 'codigo', visivel: true },
                { label: 'Cód. Barras', name: 'cod_barras', visivel: true },
                { label: 'Data', name: 'data', visivel: true },
                { label: 'Hora Proc.', name: 'hora_proc', visivel: true },
                { label: 'Paciente', name: 'paciente', visivel: true },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude', visivel: true },
                { label: 'Operadora Saúde', name: 'operadora_saude', visivel: true },
                { label: 'Status', name: 'status', visivel: true }
            ],
            data: [],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Contrato', name: 'contrato_convenio', name_coluna: 'contrato_convenio' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Tipo Repasse', name: 'tipo_pagamento_estab', name_coluna: 'tipo_pagamento_estab' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
            ],
            csvMultiplo: true,
            colunasCsv: [
                {
                    nome: 'procedimentos',
                    colunas: [
                        { label: 'Guia Id', name: 'guia_id' },
                        { label: 'Código de Barras', name: 'codigo_barras' },
                        { label: 'Data Procedimento', name: 'data_procedimento' },
                        { label: 'Horário', name: 'horario' },
                        { label: 'Nome Beneficiário', name: 'nome_beneficiario' },
                        { label: 'Estabelecimento de Saúde', name: 'estabelecimento_saude' },
                        { label: 'Operadora de Saúde', name: 'operadora_saude' },
                        { label: 'Código Proc', name: 'codigo', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%', classe: 'bold' },
                        { label: 'Procedimento', name: 'procedimento', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                        { label: 'Porte', name: 'porte', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                        { label: 'Valor Porte(R$)	', name: 'valor_unitario_formatado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                        { label: 'ACM(%)', name: 'acomodacao', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, classe: 'bold' },
                        { label: 'Perc.(%)', name: 'percentual', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                        { label: 'Red.Acre.(%)', name: 'reducao_acrescimo', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                        { label: 'Urg.(%)', name: 'urgencia_porcentagem', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                        { label: 'Valor Total(R$)', name: 'valor_total_formatado', visivel: true, ordenamento: { tipo: 'data', ativo: false }, classe: 'bold' },
                    ]
                },
                {
                    nome: 'procedimentos_repassados',
                    colunas: [
                        { label: 'Guia Id', name: 'guia_id' },
                        { label: 'Data Procedimento', name: 'data_procedimento' },
                        { label: 'Nome Beneficiário', name: 'nome_beneficiario' },
                        { label: 'Estabelecimento de Saúde', name: 'estabelecimento_saude' },
                        { label: 'Operadora de Saúde', name: 'operadora_saude' },
                        { label: 'Id', name: 'id' },
                        { label: 'Item', name: 'item' },
                        { label: 'Código', name: 'codigo' },
                        { label: 'Data Pagamento', name: 'data_pagamento' },
                        { label: 'Tipo', name: 'tipo' },
                        { label: 'Tipo Pagamento', name: 'tipo_pagamento' },
                        { label: 'Valor Procedimento', name: 'valor_procedimento_formatado' },
                        { label: 'Valor Repassado', name: 'valor_repassado_formatado' }
                    ]
                },
            ],
            virtuoso: true,
            relatorioEspecifico: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioEstabelecimentoEspecificoVirtuoso(dados, arrayConfigCol, configOrdenamento, htmlHeader),
            relatorioEspecificoImpressao: (dados, arrayConfigCol, configOrdenamento, htmlHeader) => relatorioEstabelecimentoEspecifico(dados, arrayConfigCol, configOrdenamento, htmlHeader)
        },
        {
            titulo: 'Recebido Paciente (Guia)',
            tipo: MODULOS_SISTEMA.RELATORIO_APRES_RECEB_PACIENTE_GUIA,
            url: '/relatorio/sinteticopacienterepassado',
            campos: filtrosEstabAnalitico(propsFormik, "tipo_pagamento"),
            colunas: [
                { label: '#', name: '#', visivel: true, alinhamento: 'text-center', classe: 'bold', width: '1%' },
                { label: 'Código', name: 'id', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '3%' },
                { label: 'Cód. de Barras', name: 'codigo_barras', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '8%' },
                { label: 'Data Procedimento', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%' },
                { label: 'Horário', name: 'horario', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '7%' },
                { label: 'Paciente', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '14%' },
                { label: 'Estab. de Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '14%' },
                { label: 'Operadora Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '19%' },
                { label: 'Valor Apresentado', name: 'valor_apresentado', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: 'Valor Recebido', name: 'valor_recebido', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: 'Diferença', name: 'glosa', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: 'Tipo Pagamento', name: 'tipo_pagamento', visivel: false, ordenamento: { tipo: 'texto', ativo: false }, width: '10%' },
            ],
            colunasCsv: [
                { label: 'Código', name: 'id', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%' },
                { label: 'Cód. de Barras', name: 'codigo_barras', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%' },
                { label: 'Data Procedimento', name: 'data_procedimento', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%' },
                { label: 'Horário', name: 'horario', visivel: true, ordenamento: { tipo: 'numero', ativo: false }, width: '5%' },
                { label: 'Paciente', name: 'nome_beneficiario', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Estab. de Saúde', name: 'estabelecimento_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Operadora Saúde', name: 'operadora_saude', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
                { label: 'Valor Apresentado', name: 'valor_apresentado', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: 'Valor Recebido', name: 'valor_recebido', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: 'Diferença', name: 'glosa', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '10%', totalizar: true },
                { label: 'Tipo Pagamento', name: 'tipo_pagamento', visivel: true, ordenamento: { tipo: 'texto', ativo: false } },
            ],
            data: [],
            colunasFiltros: [
                { label: 'Competência', name: 'competencia', name_coluna: 'competencia' },
                { label: 'Referência (Procedimento)', name: 'referencia', name_coluna: 'referencia' },
                { label: 'Contrato', name: 'contrato_convenio', name_coluna: 'contrato_convenio' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
                { label: 'Tipo Repasse', name: 'tipo_pagamento', name_coluna: 'tipo_pagamento' },
                { label: 'Data Inicial Procedimento', name: 'data_procedimento', name_coluna: 'data_inicial_procedimento' },
                { label: 'Data Final Procedimento', name: 'data_procedimento', name_coluna: 'data_final_procedimento' },
            ],
            virtuoso: true
        },
        {
            titulo: 'Relatório Anual de Guias não Baixadas',
            tipo: MODULOS_SISTEMA.RELATORIO_ANUAL_GUIAS_NAO_BAIXADAS,
            url: '/relatorio/guiasnaobaixadas',
            campos:
                [
                    <InputPadraoSimples label="Ano" obrigatorio placeholder="Ano" name="ano" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} maxLength={4} onBlur={e => {
                        setAno(e.target.value)
                        propsFormik.setFieldValue("ano", e.target.value)
                    }} />,
                    <InputGuiaStatus label="Status" placeholder="Status" name="status" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                    <InputEmpresa label="Empresa" placeholder="Empresa" name="empresa" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo autoFocus />,
                    <InputEstabelecimentoSaude label="Estabelecimento de Saúde" placeholder="Estabelecimento de Saúde" name="estabelecimento_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                    <InputOperadoraSaude label="Operadora Sáude" name="operadora_saude" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'} multiplo />,
                ],
            classeTr: 'linha-bottom-relatorio',
            colunas: [
                { label: '#', name: '#', visivel: true, alinhamento: 'text-center', classe: 'bold', width: '1%' },
                { label: 'Operadora Saúde', name: 'nome_fantasia', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, width: '8%' },
                { label: `Jan/${ano} `, name: 'janeiro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Fev/${ano} `, name: 'fevereiro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Mar/${ano} `, name: 'marco', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Abr/${ano} `, name: 'abril', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Mai/${ano} `, name: 'maio', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Jun/${ano} `, name: 'junho', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Jul/${ano} `, name: 'julho', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Ago/${ano} `, name: 'agosto', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Set/${ano} `, name: 'setembro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Out/${ano} `, name: 'outubro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Nov/${ano} `, name: 'novembro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Dez/${ano} `, name: 'dezembro', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '7%', totalizar: true },
                { label: `Total `, name: 'total', visivel: true, ordenamento: { tipo: 'texto', ativo: false }, alinhamento: 'text-right', width: '8%', totalizar: true },

            ],
            data: [],
            colunasFiltros: [
                { label: 'Ano', name: 'ano', name_coluna: 'ano' },
                { label: 'Status', name: 'status', name_coluna: 'status' },
                { label: 'Empresa', name: 'empresa', name_coluna: 'empresa' },
                { label: 'Estabelecimento Saúde', name: 'estabelecimento_saude_id', name_coluna: 'estabelecimento_saude' },
                { label: 'Operadora Saúde', name: 'operadora_saude_id', name_coluna: 'operadora_saude' },
            ],
            ajustaEscala: true
        },
    ]

    const submit = (values, propsFormik) => {

        // if (values && !values.referencia && (!values.data_inicial_procedimento && !values.data_final_procedimento) && (!values.data_registro_inicial && !values.data_registro_final) && values?.tipo_relatorio?.value !== 3) {
        //     if (values?.tipo_relatorio?.value === 4)
        //         mostrarToast('erro', 'Escolha ao menos um filtro!')

        //     else
        //         mostrarToast('erro', 'Escolha ao menos um filtro! Referência ou Data de Procedimento Inicial e Final')
        // }

        if (relatorioSelecionado == MODULOS_SISTEMA.RELATORIO_ANUAL_GUIAS_NAO_BAIXADAS)
            if (values && !values.ano) {
                return mostrarToast('erro', 'Escolha um ano.')
            }

        if (values && !values.ano && !values.competencia && !values.referencia && (!values.data_inicial_procedimento && !values.data_final_procedimento) && (!values.data_inicial_registro && !values.data_final_registro) && (!values.data_inicial_entrega && !values.data_final_entrega)) {
            if (values?.tipo_relatorio?.value === 3)
                mostrarToast('erro', 'Escolha uma Referência ou Competência.')
            else
                mostrarToast('erro', 'Escolha ao menos um filtro! Referência, Competência, Data Procedimento, Data Registro ou Data Entrega Inicial e Final')

        }
        else if (values && values.ano && values.competencia && values.referencia && (values.data_inicial_procedimento || values.data_final_procedimento) && (values.data_inicial_registro || values.data_final_registro) && (values.data_inicial_entrega || values.data_final_entrega)) {

            mostrarToast('erro', 'Escolha ao menos um filtro! Referência, Competência, Data Procedimento, Data Registro ou Data Entrega Inicial e Final')

        }
        else {
            setAno(values.ano)
            setCarregando(true)
            let novoRelatorio = relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado)

            for (let i = Object.keys(values).length; i > 0; i--)
                if (Array.isArray(values[Object.keys(values)[i]]) && values[Object.keys(values)[i]].length === 0 || !values[Object.keys(values)[i]])
                    delete values[Object.keys(values)[i]]

            setShowModalFiltros(false)
            setValuesFiltros(values)
            setRelatorioAtual({ ...novoRelatorio })

            if (values.tipo_relatorio) {
                setFiltros(Object.keys(values).map((x, idx, array) => {

                    const valorAtual = values[x]

                    return (x !== 'tipo_relatorio'
                        && (typeof valorAtual === 'string' ? valorAtual : (valorAtual && valorAtual.length ? valorAtual.map(y => y.value) && valorAtual.map(y => y.value).length : (valorAtual && valorAtual.value ? valorAtual.value.toString().length : valorAtual))) &&
                    {
                        coluna: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name_coluna,
                        filtro: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).label,
                        name: novoRelatorio.colunasFiltros.find(y => y.name_coluna === x) !== undefined && novoRelatorio.colunasFiltros.find(y => y.name_coluna === x).name,
                        value: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.value) : valorAtual.value,
                        dado: typeof valorAtual === 'string' ? valorAtual : valorAtual.length ? valorAtual.map(y => y.label).toString().split(',').join(', ') : valorAtual.label
                    })
                }).filter(x => x.coluna !== false && x.value))
            }
            else {
                setRelatorioAtual({})
                setCarregando(false)
                setDados([])
                setFiltros([])
            }
        }
    }

    useEffect(() => {
        let cloneRelatorioAtual = { ...relatorioAtual }
        const newFiltro = [...filtros]

        if (user.tipo === 4)
            newFiltro.push({ coluna: 'usuario', value: user.id }, { coluna: 'tipo', value: user.tipo })


        cloneRelatorioAtual && cloneRelatorioAtual.url &&
            consultarRelatorio(cloneRelatorioAtual.url, array => {
                cloneRelatorioAtual.data = array
                setTitulo(cloneRelatorioAtual.titulo)
                setDados([{
                    numerarLinha: true, colunas: cloneRelatorioAtual.colunas, csvMultiplo: cloneRelatorioAtual.csvMultiplo, colunasCsv: cloneRelatorioAtual.colunasCsv ? cloneRelatorioAtual.colunasCsv : null, data: cloneRelatorioAtual.data, classeTr: cloneRelatorioAtual.classeTr, aposAgrupamento: cloneRelatorioAtual.aposAgrupamento, aposRelatorio: cloneRelatorioAtual.aposRelatorio, agrupado: cloneRelatorioAtual?.agrupado || false, relatorioEspecifico: !!cloneRelatorioAtual.relatorioEspecifico ? (dados, arrayConfigCol, configOrdenamento) => cloneRelatorioAtual.relatorioEspecifico(dados, arrayConfigCol, configOrdenamento) : null,
                    relatorioEspecificoImpressao: !!cloneRelatorioAtual.relatorioEspecificoImpressao ? (dados, arrayConfigCol, configOrdenamento) => cloneRelatorioAtual.relatorioEspecificoImpressao(dados, arrayConfigCol, configOrdenamento) : null, virtuoso: !!cloneRelatorioAtual.virtuoso, ajustaEscala: !!cloneRelatorioAtual.ajustaEscala
                }])
                setCarregando(false)
                setRelatorioAtual(cloneRelatorioAtual)
            }, null, { value: newFiltro, usuario_tipo: user.tipo, usuario_id: user.id })
    }, [filtros])

    const changeTipo = (value) => {
        setRelatorioAtual({})
        setDados([])
        setCarregandoFiltros(true)
        setFiltros([])
        // setRelatorioAtual({ })
        // const index = tipoRelatorio.findIndex(x => x.value === value)
        setValuesFiltros({ tipo_relatorio: value, data_inicial: startOfMonth, data_final: endOfMonth, competencia: actualMonth, ano: actualYear })
        setRelatorioSelecionado(value?.value)

    }
    return (
        <ConsultaRelatorio showModalFiltros={showModalFiltros} setShowModalFiltros={setShowModalFiltros} dados={dados} titulo={titulo} filtros={filtros} setFiltros={setFiltros} virtuoso={relatorioAtual.virtuoso} ajustaEscala={relatorioAtual.ajustaEscala} orientacao={relatorioSelecionado == 5 ? 'A4 landscape' : 'portrait'} orientacaoPadrao={relatorioSelecionado == 5 ? 'landscape' : 'portrait'}
            loading={carregando} limparFiltrosFormik={() => setValuesFiltros({ tipo_relatorio: valuesFiltros.tipo_relatorio })} ref={ref} classePersonalizada={relatorioAtual && relatorioAtual.classePersonalizada} ordenarArrayCompleto={relatorioAtual && relatorioAtual.ordenarArrayCompleto}>
            <Formik
                initialValues={valuesFiltros}
                enableReinitialize={true}
                validationSchema={validacao}
                onSubmit={(values, propsFormik) => submit(values, propsFormik)}>
                {propsFormik => (<Form>
                    <div className='row'>
                        <InputTipoRelatorioPersonalizado label="Tipo Relatório" name="tipo_relatorio" placeholder="Tipo Relatório" classeTooltip={'tooltip-input-modal'}
                            propsFormik={propsFormik} modulos={relatoriosPorTipo(propsFormik).map(x => x.tipo)} autoFocus tipoRelatorio={tipoRelatorio} setRelatorioSelecionado={changeTipo} obrigatorio={true} />
                        {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado) && <div className={carregandoFiltros ? 'd-none' : ''} style={{ width: '100%' }}>
                            {relatoriosPorTipo(propsFormik).find(x => x.tipo === relatorioSelecionado).campos.map(
                                (item, idx, array) => {
                                    if (idx === (array.length - 1)) {
                                        setTimeout(() => {
                                            setCarregandoFiltros(false)
                                        }, 1000)
                                    }
                                    return item
                                }
                            )}
                        </div>}
                        {carregandoFiltros === true ?
                            <ContentLoader
                                speed={2}
                                width='100%'
                                height='400'
                                backgroundColor="#f3f3f3"
                                foregroundColor="#e4e4e4"
                                {...props} >
                                <rect x="12" y="10" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="30" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="100" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="120" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="190" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="210" rx="3" ry="3" width="355" height="50" />
                                <rect x="12" y="280" rx="3" ry="3" width="170" height="10" />
                                <rect x="12" y="300" rx="3" ry="3" width="355" height="50" />
                            </ContentLoader>
                            : <></>}
                    </div>
                    <div className='direita container-botoes mb-3 group-salvar-novo'>
                        <BotaoPadrao texto='Filtrar' disabled={false} />
                    </div>
                </Form>)}
            </Formik>
        </ConsultaRelatorio >
    )
}