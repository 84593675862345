import { InputCurrency, InputDatePicker, InputPadrao, InputSwitch, InputValor, InputValorNovo } from '../../../../components/Inputs'
import { InputAtendimentoFinanceiro, InputBeneficiarioGuia, InputCategoriaFinanceiro, InputClienteFinanceiro, InputContaBancaria, InputContaBancariaCliente, InputContaBancariaEmpresa, InputEmpresaCliente, InputModoLiquidacao, InputParcela, InputPeriodicidade, InputPlanoContas, InputSimNao, InputSocio, InputTipoAtendimentoFinanceiro, InputTipoDocMovimento, InputTipoDocumento, InputTipoLancamento, InputTipoLancamentoFinanceiro, InputTipoTabelaProcedimento } from '../../../../components/InputsAutoComplete'
import React, { useState, useEffect, useRef } from 'react'
import Yup, { validaData, validaLancamento } from '../../../../util/Validacoes'
import { HeaderAgenda, HeaderCadastro, HeaderFinanceiro } from '../../../../components/Headers'
import { mostrarToast } from '../../../../components/Toasts'
import { Formik, Form, useFormikContext } from 'formik'
import { BotaoPadrao, BotaoSubMenu } from '../../../../components/Botoes'
import { convertValueToFloat, formatarMoeda, mensagens, useQuery } from '../../../../util/Utilitarios'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { salvar, get, validar } from '../../../../util/AcessoApi'
import '../../../../assets/css/icones/iconsmind/style.css';
import Atalho from '../../../../util/Atalho'
import { BtnAcao } from '../../../../components/Acoes'
import { CadastroLiquidacao, CadastroLiquidacaoParcelado, MovimentoLancamentos } from './TiposLancamentos'
import { ModalBloqueado, ModalDuasOpcoes, ModalVisualizacao } from '../../../../components/Modais'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { ref } from 'yup'
import InputArquivoS3 from '../../../../components/InputArquivoS3'
import { MODULO } from '../../../../util/Enums'
import Rascunho from '../../../../components/Rascunho'
import { InputEmpresa, InputEmpresaClienteLancamento, InputTipoMovimento } from '../../../../components/InputsAutoCompleteV2'


const Lancamento = (props) => {

    const refLiquidacao = useRef(null)
    let { id } = useParams()
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [dataMovimento, setDataMovimento] = useState()
    const [msgValidacao, setMsgValidacao] = useState([])
    const [showModalValidaGuiaBaixada, setShowModalValidaGuiaBaixada] = useState(false)
    const [dataArquivos, setDataArquivos] = useState(null)
    const [limparArquivos, setLimparArquivos] = useState(false)
    const [showModalDados, setShowModalDados] = useState(false)
    const [idAtendimento, setIdAtendimento] = useState()
    const history = useHistory()
    const [idMovimento] = useState(history.location.state && history.location.state.idMovimento ? history.location.state.idMovimento : null)
    const manter = [{ name: 'conta_bancaria_lancamento' }, { name: 'tipo_lancamento' }, { name: 'empresa' }, { name: 'categoria_lancamento' }, { name: 'conta_bancaria_lancamento_empresa' }]
    const inputValorLancamento = useRef();
    const [refAtendimento, setRefAtendimento] = useState({})
    const [adicionando, setAdicionando] = useState(false)
    const [liquidar, setLiquidar] = useState(false)
    const idLancamentoDiario = history.location.state && history.location.state.idLancamentoDiario ? history.location.state.idLancamentoDiario : null

    let validacao = Yup.lazy(({ liquidacao, liquidacao_parcelado, arrayLancamentos, liquida_parcela, parcelado_lancamento }) => {
        let index = liquidacao && liquidacao.length > 0 && liquidacao?.findIndex(x => !x.modo_liquidacao)
        return Yup.object({
            cliente_lancamento: Yup.string().nullable().required(),
            tipo_lancamento: Yup.string().nullable().required(),
            categoria_lancamento: Yup.string().nullable().required(),
            previsao_vencimento_lancamento: Yup.string().nullable().required(),
            empresa: Yup.object().nullable().required(),
            conta_bancaria_lancamento_empresa: Yup.object().nullable().required(),
        }).shape({
            liquidacao: Yup.array()
                .of(
                    Yup.object().shape({
                        modo_liquidacao: Yup.object().nullable().when('valor', {
                            is: () => liquidar && parcelado_lancamento ? (liquidacao && !liquidacao[index]?.modo_liquidacao) : false,
                            then: Yup.object().required(),
                            otherwise: Yup.object().nullable()
                        }),
                        valor: Yup.string().nullable()
                        // .test('Valor Liquidação', 'Campo Obrigatório!', function (value) {
                        //     const index = this.path.replace(/[^0-9]+/g, '');
                        //     return liquidar ? !(liquidacao && convertValueToFloat(liquidacao[index]?.valor_liquidacao) === 0) : true
                        // })
                        ,
                        data_liquidacao: Yup.string().nullable().test('Data Liquidação', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return liquidar && !parcelado_lancamento ? !(liquidacao && !liquidacao[index]?.data_liquidacao) : true

                        }),
                    })
                ),
            liquidacao_parcelado: Yup.array()
                .of(
                    Yup.object().shape({
                        modo_liquidacao: Yup.object().nullable().test('Modo Liquidação', 'Campo Obrigatório!', function (value) {

                            const index = this.path.replace(/[^0-9]+/g, '');
                            // return !((!value && parcelado_lancamento)
                            //     || (!liquidacao_parcelado[index]?.modo_liquidacao && liquidacao_parcelado[index]?.data_liquidacao))
                            return parcelado_lancamento && (liquidacao_parcelado[index]?.data_liquidacao || parseInt(index) === 0) ? value : true

                        }),
                        data: Yup.string().nullable().test('Data Prevista', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(!value && parcelado_lancamento)
                        }),
                        data_liquidacao: Yup.string().nullable().test('Data Liquidação', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return parcelado_lancamento && idMovimento && (liquidacao_parcelado[index].liquidado || parseInt(index) === 0) ? value : true

                        }),
                        valor_liquidacao: Yup.string().nullable().test('Valor Liquidação', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(!convertValueToFloat(value) && parcelado_lancamento)
                        }), // these constraints take precedence
                    })
                ),
            liquida_parcela: Yup.object().shape({
                data: Yup.string().nullable().test('Data', 'Campo Obrigatório!', value => parcelado_lancamento ? value : true),
                periodicidade: Yup.object().nullable().test('Periodicidade', 'Campo Obrigatório!', value => parcelado_lancamento ? value : true),
                parcela: Yup.string().nullable().test('Número', 'Campo Obrigatório!', value => parcelado_lancamento ? value : true),
                valor: Yup.string().nullable().test('Valor', 'Campo Obrigatório!', value => parcelado_lancamento ? !(Number.isNaN(convertValueToFloat(value))) : true),
            })
        }, ['data', 'periodicidade', 'numero_parcela', 'valor'])
    })


    if (ref) {
        ref.current = {
            validarSubmit: (values, propsFormik) => validarSubmit(values, propsFormik),
            valoresIniciais: valoresIniciais
        }
    }

    const Submit = (values, propsFormik) => {

        setSalvando(true)

        if (id)
            values['arquivos'] = dataArquivos


        if (values.parcelado_lancamento) {
            values.arrayLancamentos = [values?.liquidacao_parcelado?.map(itemLiquidado => ({
                id: null, data: itemLiquidado.data || null, cliente: values?.cliente_lancamento, socio: values.socio, estabelecimento_saude: values?.estabelecimento_saude || null, valor: convertValueToFloat(values.valor_geral_lancamento),
                data_liquidacao: itemLiquidado?.data_liquidacao, nfse: values?.nota_fiscal_lancamento, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: values?.liquida_parcela?.periodicidade?.value,
                conta_bancaria_id: values?.conta_bancaria_lancamento?.value, conta_bancaria_empresa_id: values?.conta_bancaria_lancamento_empresa?.value, categoria_id: values?.categoria_lancamento?.value, tipo_lancamento_id: values?.tipo_lancamento?.value, tipo_lancamento_descricao: values?.tipo_lancamento?.label, descricao: values?.descricao_lancamento,
                tipo_documento_movimento_financeiro_id: values?.tipo_documento_lancamento?.value, data_prevista: values?.previsao_vencimento_lancamento, tipo_lancamento: values?.tipo_lancamento, categoria: values?.categoria_lancamento, atendimento: values?.atendimento_lancamento,
                parcela: values?.liquida_parcela?.parcela, conta_bancaria: values?.conta_bancaria_lancamento, tipo_documento_lancamento: values?.tipo_documento_lancamento, conta_bancaria_empresa: values?.conta_bancaria_lancamento_empresa, tipo_documento: values?.tipo_documento_lancamento, periodicidade: values?.liquida_parcela?.periodicidade, valor_total_liquidacao: null, arquivos: dataArquivos, empresa: values?.empresa,
                tipo_movimento: values?.tipo_movimento
            }))[0]].slice()
            values.liquidacoes = [values?.liquidacao_parcelado.map(itemLiquidado => ({
                id_liquidacao: itemLiquidado.id_liquidacao, data: itemLiquidado.data, cliente: values?.cliente_lancamento, socio: values.socio, estabelecimento_saude: values?.estabelecimento_saude || null, valor: convertValueToFloat(itemLiquidado?.valor_liquidacao),
                data_liquidacao: itemLiquidado?.data_liquidacao, nfse: values?.nota_fiscal_lancamento, parcelado: true, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: values?.liquida_parcela?.periodicidade?.value,
                conta_bancaria_id: values?.conta_bancaria_lancamento?.value, conta_bancaria_empresa_id: values?.conta_bancaria_lancamento_empresa?.value, categoria_id: values?.categoria_lancamento?.value, tipo_lancamento_id: values?.tipo_lancamento?.value, descricao: values?.descricao_lancamento,
                tipo_documento_movimento_financeiro_id: values?.tipo_documento_lancamento?.value, data_prevista: itemLiquidado?.data, liquidado: itemLiquidado?.liquidado, modo_liquidacao: itemLiquidado?.modo_liquidacao, arquivos: dataArquivos,
                acrescimo: itemLiquidado?.acrescimo, desconto: itemLiquidado?.desconto, juros_multa: itemLiquidado?.juros_multa, imposto_nfse: itemLiquidado?.imposto_nfse, glosas: itemLiquidado?.glosas, outros_impostos: itemLiquidado?.outros_impostos,
                anotacao: itemLiquidado?.anotacoes, empresa: values?.empresa

            }))]

        } else {
            values.arrayLancamentos = [values?.liquidacao?.map(itemLiquidado => ({
                id: null, data: itemLiquidado.data || null, cliente: values?.cliente_lancamento, socio: values.socio, estabelecimento_saude: values?.estabelecimento_saude || null, valor: convertValueToFloat(values.valor_geral_lancamento),
                data_liquidacao: itemLiquidado?.data_liquidacao, nfse: values?.nota_fiscal_lancamento, parcelado: false, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: values?.liquida_parcela?.periodicidade?.value,
                conta_bancaria_id: values?.conta_bancaria_lancamento?.value, conta_bancaria_empresa_id: values?.conta_bancaria_lancamento_empresa?.value, categoria_id: values?.categoria_lancamento?.value, tipo_lancamento_id: values?.tipo_lancamento?.value, tipo_lancamento_descricao: values?.tipo_lancamento?.label, descricao: values?.descricao_lancamento,
                tipo_documento_movimento_financeiro_id: values?.tipo_documento_lancamento?.value, data_prevista: values?.previsao_vencimento_lancamento, tipo_lancamento: values?.tipo_lancamento, categoria: values?.categoria_lancamento, atendimento: values?.atendimento_lancamento,
                parcela: values?.liquida_parcela?.parcela, conta_bancaria: values?.conta_bancaria_lancamento, tipo_documento_lancamento: values?.tipo_documento_lancamento, conta_bancaria_empresa: values?.conta_bancaria_lancamento_empresa, tipo_documento: values?.tipo_documento_lancamento, periodicidade: values?.liquida_parcela?.periodicidade, valor_total_liquidacao: null, arquivos: dataArquivos, empresa: values?.empresa,
                tipo_movimento: values?.tipo_movimento
            }))[0]].slice()
            values.liquidacoes = [values?.liquidacao.map(itemLiquidado => ({
                id_liquidacao: itemLiquidado.id_liquidacao, data: itemLiquidado.data, cliente: values?.cliente_lancamento, socio: values.socio, estabelecimento_saude: values?.estabelecimento_saude || null, valor: convertValueToFloat(itemLiquidado?.valor_liquidacao || itemLiquidado.valor || 0),
                data_liquidacao: itemLiquidado?.data_liquidacao, nfse: values?.nota_fiscal_lancamento, parcelado: false, modo_liquidacao_id: itemLiquidado?.modo_liquidacao?.value, periodicidade_id: values?.liquida_parcela?.periodicidade?.value,
                conta_bancaria_id: values?.conta_bancaria_lancamento?.value, conta_bancaria_empresa_id: values?.conta_bancaria_lancamento_empresa?.value, categoria_id: values?.categoria_lancamento?.value, tipo_lancamento_id: values?.tipo_lancamento?.value, descricao: values?.descricao_lancamento,
                tipo_documento_movimento_financeiro_id: values?.tipo_documento_lancamento?.value, data_prevista: itemLiquidado?.data, liquidado: itemLiquidado?.liquidado, modo_liquidacao: itemLiquidado?.modo_liquidacao, arquivos: dataArquivos,
                acrescimo: itemLiquidado?.acrescimo, desconto: itemLiquidado?.desconto, juros_multa: itemLiquidado?.juros_multa, imposto_nfse: itemLiquidado?.imposto_nfse, glosas: itemLiquidado?.glosas, outros_impostos: itemLiquidado?.outros_impostos,
                anotacao: itemLiquidado?.anotacoes, empresa: values?.empresa
            }))]
        }


        get('/financeiro/statuslancamento', { id: id || 0 }).then(function (val) {
            if (val && val.status_id == '2') {
                mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado.')
                setSalvando(false)
            }
            else
                salvar(
                    { ...values },
                    '/financeiro/lancamentofinanceiro',
                    obj => {
                        if (props.modal) {
                            if (salvarENovo) {
                                propsFormik.resetForm({})
                                manter.forEach(x => propsFormik.setFieldValue(x, values[x]))
                                setSalvando(false)
                                setSalvarENovo(false)
                            }
                            else {
                                props.setItemSelecionado({ value: obj.id, label: obj.descricao })
                                props.setShow && props.setShow(false)
                            }
                        }
                        else {
                            mostrarToast('sucesso', mensagens.sucesso)
                            if (salvarENovo) {
                                setShowModalValidaGuiaBaixada(false)
                                propsFormik.resetForm({})
                                manter.map(x => propsFormik.setFieldValue(x.name, values[x.name]))
                                setSalvando(false)
                                // document.getElementsByName('atendimento_lancamento')[0].focus()
                            }
                            else {
                                props.history.push('/financeiro/lancamentofinanceiro/consulta')

                            }

                        }
                    },
                    () => setSalvando(false))

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    const validarSubmit = (values, propsFormik) => {
        setSalvando(true)
        //VALIDA RETENCAO/ACRESCIMOS JUNTO 
        Promise.resolve(validar('/financeiro/validaGuiaBaixada', { liquidacao: !values.parcelado ? values.liquidacao : values.liquidacao_parcelado.filter(x => x.liquidado), valor: convertValueToFloat(values.valor_geral_lancamento) }, () => Submit(values, propsFormik), (e) => {
            setShowModalValidaGuiaBaixada(true)
            setMsgValidacao(<><div dangerouslySetInnerHTML={{ __html: e?.response && e?.response?.data?.alertas.map(x => x.replace(/"/g, '') + '<br/><br/>').join('') }} /></>)
            setSalvando(false)
        }))
    }

    useEffect(_ => {
        setValoresIniciais(history.location.state)
    }, [history.location.state])

    useEffect(() => {
        if (idLancamentoDiario != null) {
            Promise.resolve(get(`/financeiro/lancamento/${idLancamentoDiario}`)).then(val => {
                if (val.length === 0)
                    props.history.push('/financeiro/lancamentofinanceiro/consulta')
                else {
                    setLiquidar(val.liquidacao.length > 0 ? true : false)
                    val.origem_lancamento_id = val.id
                    val.id = null
                    val.valor_geral_lancamento = val.valor_total_formatado
                    val.parcelado_lancamento = false
                    val.liquidacao.forEach(x => delete x.id_liquidacao)
                    val.liquidacao.length == 0 && val.liquidacao.push(
                        {
                            acrescimo: null,
                            acrescimo_formatado: null,
                            alteracao: true,
                            data: "",
                            data_liquidacao: null,
                            data_registro: null,
                            desconto: null,
                            desconto_formatado: null,
                            glosas: null,
                            glosas_formatado: null,
                            imposto_nfse: null,
                            imposto_nfse_formatado: null,
                            juros_multa: null,
                            juros_multa_formatado: null,
                            liquidar: false,
                            modo_liquidacao: null,
                            numero_parcela: null,
                            outros_impostos: null,
                            outros_impostos_formatado: null,
                            valor: ""
                        }
                    )

                    setValoresIniciais(val)
                }
            })


        } else if (id) {
            Promise.resolve(get(`/financeiro/lancamentofinanceiro/${id}`)).then(val => {
                if (val.length === 0)
                    props.history.push('/financeiro/movimento/consulta')
                else {
                    setDataArquivos(val.arquivos)
                    val.valor_geral_lancamento = val.valor_total_formatado
                    val.parcelado_lancamento = false
                    val.liquidacao = [...val?.liquidacao.map(x => ({ alteracao: true, liquidar: false, ...x })),];
                    val.valor_original = convertValueToFloat(val.valor_total_formatado)
                    val.liquidacao_parcelado = [...val.liquidacao_parcelado.map(x => ({ alteracao: true, ...x })),];
                    setValoresIniciais(val)
                    // setIdAtendimento(val?.atendimento)
                }
            })
        } else {
            setDataArquivos([])
            setValoresIniciais({
                // atendimento_lancamento: "",
                cliente_lancamento: "",
                valor_geral_lancamento: "",
                // tipo_lancamento: { value: 1, label: 'RECEITA' },
                plano_contas_lancamento: "",
                conta_bancaria_lancamento: "",
                descricao_lancamento: "",
                tipo_documento_lancamento: "",
                nota_fiscal_lancamento: "",
                previsao_vencimento_lancamento: moment().format('DD/MM/YYYY'),
                parcelado_lancamento: false,
                liquidacao: [{ modo_liquidacao: undefined, data_liquidacao: moment().format('DD/MM/YYYY'), valor_liquidacao: undefined, parcelado: false, periodicidade: undefined, parcela: undefined }],
                liquidacoes: [],
                alterando_linha_lancamento: false

            })
        }

    }, [id])

    const links = [
        () => <Link to={'/dashboard'}>Dashboard</Link>,
        () => <span>Financeiro</span>,
        () => <Link className='link-active' to={'/financeiro/lancamentofinanceiro/consulta'}>Lançamento Financeiro</Link>,
    ]

    return (
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacao}
            enableReinitialize={true}
            onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
            {propsFormik => (<Form>
                {propsFormik.values ?
                    <>
                        {!props.modal && <HeaderCadastro titulo={"Cadastro Lançamento"} link={links} />}
                        <div className={props.modal && props.modal || 'card mb-20'}>
                            <div className={'card-body'}>
                                <div className='row'>
                                    <Rascunho modulo={props.modulo} history={history} />
                                    <InputPadrao label='Id' name='id' type='hidden' placeholder='Id' />
                                    <InputEmpresaClienteLancamento autoFocus label='Empresa' name="empresa" placeholder="Empresa" propsFormik={propsFormik} obrigatorio
                                        tamanho={!props.modal && 'col-xl-3 col-lg-6 col-md-6'} classeTooltip={props.modal && 'tooltip-input-modal'} onChange={(option) => {
                                            propsFormik.setFieldValue('conta_bancaria_lancamento_empresa', null)
                                            propsFormik.setFieldValue('empresa', option)

                                        }} />
                                    <InputTipoLancamentoFinanceiro label='Tipo Lançamento' name="tipo_lancamento" placeholder="Tipo Lançamento" propsFormik={propsFormik} obrigatorio={true}
                                        tamanho={!props.modal && 'col-lg-2'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputCategoriaFinanceiro label='Categoria' name="categoria_lancamento" placeholder="Categoria" propsFormik={propsFormik} obrigatorio={true}
                                        tamanho={!props.modal && 'col-lg-4'} array={true} classeTooltip={props.modal && 'tooltip-input-modal'} botaoVisivel={true} disabled={!propsFormik.values?.tipo_lancamento} />
                                    <InputContaBancariaEmpresa label='Conta Bancária Empresa' name="conta_bancaria_lancamento_empresa" placeholder="Conta Bancária Empresa" propsFormik={propsFormik} botaoVisivel={propsFormik.values?.empresa}
                                        modal obrigatorio
                                        tamanho={!props.modal && 'col-lg-3'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputClienteFinanceiro label='Cliente' name="cliente_lancamento" placeholder="Cliente" propsFormik={propsFormik} obrigatorio={true}
                                        botaoVisivel={true} array={true} salvarENovo={salvarENovo} focusSubmit tamanho={!props.modal && 'col-lg-3'} classeTooltip={props.modal && 'tooltip-input-modal'} onChange={(option) => {
                                            propsFormik.setFieldValue('conta_bancaria_lancamento', null)
                                            propsFormik.setFieldValue('cliente_lancamento', option)

                                        }} />
                                    <InputContaBancariaCliente label='Conta Bancária Cliente' name="conta_bancaria_lancamento" placeholder="Conta Bancária Cliente" propsFormik={propsFormik} botaoVisivel={propsFormik.values?.cliente_lancamento}
                                        modal tamanho={!props.modal && 'col-lg-4'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputDatePicker tamanho={'col-lg-2'} label="Previsão Vencimento" name="previsao_vencimento_lancamento" type="text" obrigatorio="true"
                                        setarValor={value => {
                                            propsFormik.setFieldValue("previsao_vencimento_lancamento", value)
                                        }} classeTooltip={props.modal && 'tooltip-input-modal'} mostrarFeriado={true}
                                        onBlurData={value => {
                                            propsFormik.setFieldValue("liquidacao[0].data_liquidacao", value)
                                            propsFormik.setFieldValue("liquida_parcela.data", value)

                                            if (!propsFormik.values.parcelado_lancamento && propsFormik.values.liquidacao.length > 1)
                                                refLiquidacao.current.atualizarDataLiquidacao(value)
                                            if (props.setPreencheuPrevisao) {
                                                if (value && moment(value, 'DD/MM/YYYY').isValid())
                                                    props.setPreencheuPrevisao(true)
                                                else
                                                    props.setPreencheuPrevisao(false)
                                            }
                                        }}
                                    />
                                    <InputCurrency onUpdate={val => {
                                        let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                        inputValorLancamento.current = valor
                                    }}
                                        label={'Valor R$'} name={`valor_geral_lancamento`}
                                        value={inputValorLancamento.current || propsFormik?.values?.valor_geral_lancamento || propsFormik?.values?.valor_geral_lancamento_formatado}
                                        classeTooltip={'tooltip-input-modal'}
                                        propsFormik={propsFormik}
                                        onBlur={(e) => {
                                            if (inputValorLancamento.current || inputValorLancamento.current === 0) {
                                                propsFormik.values.valor_geral_lancamento = inputValorLancamento.current && inputValorLancamento.current.toString().trim();
                                                propsFormik.values.valor_geral_lancamento_formatado = formatarMoeda(inputValorLancamento.current && convertValueToFloat(inputValorLancamento.current.toString()));
                                                propsFormik.values.valor_geral_formatado = formatarMoeda(inputValorLancamento.current && convertValueToFloat(inputValorLancamento.current.toString()));
                                            }

                                            propsFormik.setFieldValue(`liquidacao[0].valor_liquidacao`, convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value))
                                            propsFormik.setFieldValue(`liquidacao[0].valor_liquidacao_formatado`, convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value))
                                            propsFormik.setFieldValue(`liquidacao[0].valor_original`, convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value))
                                            propsFormik.setFieldValue(`liquida_parcela.valor_formatado`, formatarMoeda(convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value)))
                                            propsFormik.setFieldValue(`liquida_parcela.valor`, convertValueToFloat(propsFormik.values.valor_geral_lancamento || e.currentTarget.value))

                                            // if (!(propsFormik.values.liquidacao && propsFormik.values.liquidacao[0] && propsFormik.values.liquidacao[0].modo_liquidacao)) {

                                            //     propsFormik.setFieldValue(`liquidacao[0].valor_liquidacao`, convertValueToFloat(propsFormik.values.liquidacao && propsFormik.values.liquidacao[0] && propsFormik.values.liquidacao[0].valor_liquidacao || e.currentTarget.value))
                                            //     propsFormik.setFieldValue(`liquidacao[0].valor`, convertValueToFloat(propsFormik.values.liquidacao && propsFormik.values.liquidacao[0] && propsFormik.values.liquidacao[0].valor || e.currentTarget.value))
                                            //     propsFormik.setFieldValue(`liquidacao[0].valor_original`, convertValueToFloat(propsFormik.values.liquidacao && propsFormik.values.liquidacao[0] && propsFormik.values.liquidacao[0].valor || e.currentTarget.value))

                                            // }
                                            // propsFormik.values.liquidacao[0].valor = 150
                                            inputValorLancamento.current = null;
                                        }}
                                        classDiv='col-2 col-xl-3 col-lg-2 col-sm-12' obrigatorio={true} />
                                    {/* 
                                    <InputValor obrigatorio label='Valor' name={`valor_geral`} value={propsFormik.values.liquidacao && propsFormik.values.liquidacao[0].valor || propsFormik.values.valor_geral} classeTooltip={'tooltip-input-modal'} classDiv='col-lg-3' propsFormik={propsFormik} isDisable={false} max={100000000} onBlur={(e) => {
                                        if (propsFormik.values.parcelado)
                                            propsFormik.setFieldValue(`liquidacao[0].valor`, propsFormik.values.liquidacao[0].valor || e.currentTarget.value)
                                        else
                                            propsFormik.setFieldValue(`liquidacao[0].valor_liquidacao`, propsFormik.values.liquidacao[0].valor_liquidacao || e.currentTarget.value)
                                        // propsFormik.values.liquidacao[0].valor = 150
                                    }} /> */}





                                    <InputPadrao label='Descrição' name='descricao_lancamento' type='text' placeholder='Descrição' classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-3'} />
                                    <InputTipoDocMovimento label='Tipo Documento' name="tipo_documento_lancamento" placeholder="Tipo Documento" propsFormik={propsFormik}
                                        tamanho={!props.modal && 'col-lg-2'} classeTooltip={props.modal && 'tooltip-input-modal'} botaoVisivel={true} />
                                    <InputPadrao label='NFSe/Documento' name='nota_fiscal_lancamento' type='text' placeholder='NFSe/Documento' classeTooltip={'tooltip-input-modal'} tamanho={!props.modal && 'col-lg-3'} />
                                    <InputTipoMovimento label='Tipo Movimento' name="tipo_movimento" placeholder="Tipo Movimento" propsFormik={propsFormik}
                                        tamanho={!props.modal && 'col-lg-3'} classeTooltip={props.modal && 'tooltip-input-modal'} />
                                    <InputSwitch label='Parcelado?' propsFormik={propsFormik} onChange={(option) => {
                                        propsFormik.setFieldValue('parcelado_lancamento', option)

                                        propsFormik.setFieldValue(`liquidacao`, [{ modo_liquidacao: null, data: propsFormik.values.previsao_vencimento_lancamento, valor: formatarMoeda(convertValueToFloat(propsFormik.values.valor_geral_lancamento).toFixed(2)) || undefined, periodicidade: null, parcela: null }])//Limpar primeira linha liquidação
                                        propsFormik.setFieldValue(`liquida_parcela`, { periodicidade: null, data: propsFormik.values.previsao_vencimento_lancamento, valor: formatarMoeda(convertValueToFloat(propsFormik.values.valor_geral_lancamento).toFixed(2)) || undefined, parcela: null })//Limpar primeira linha liquidação
                                        propsFormik.setFieldValue(`liquidacao_parcelado`, [])//Limpar linhas do parcelado ao alterar status do input
                                    }}

                                        quebrarLinha classeDiv='form-group ajusta-switch ' name='parcelado_lancamento' size="md" tamanho={!props.modal && 'col-xl-1 col-lg-1'} classeTooltip={props.modal && 'tooltip-input-modal'} />



                                    {dataArquivos != null ?
                                        <InputArquivoS3
                                            label='Arquivos'
                                            name='arquivo'
                                            placeholder='Arquivo'
                                            tamanho='col-12'
                                            diretorioS3='movimentofinanceiro/DDMMYYYY/'
                                            modulo={MODULO["/financeiro/lancamentofinanceiro"]}
                                            tamanhoMinimo={0}
                                            tamanhoMaximoEmMB={300}
                                            setDataArquivos={setDataArquivos}
                                            dataArquivos={dataArquivos != null ? dataArquivos : []}
                                            setIsSubmit={dataArquivos.length > 0 ? setSalvando : () => { }}
                                            limparCampos={limparArquivos}
                                        /> : <></>}

                                    {/*Modal Lateral */}
                                    <Atalho formik={propsFormik} salvando={salvando} setSalvareNovo={setSalvarENovo} salvarENovo={true} />

                                </div>
                            </div>
                        </div>
                        <CadastroLiquidacao ref={refLiquidacao} propsFormik={propsFormik} id={id} valoresIniciais={valoresIniciais} dataMovimento={dataMovimento} tabLancamento={props.tabLancamento} setIdAtendimento={setIdAtendimento} limparArquivos={(boleano) => setLimparArquivos(boleano)} dataArquivos={dataArquivos} idMovimento={idMovimento} dadosMovimentoLancamento={props.dadosMovimentoLancamento} setDadosMovimentoLancamento={props.setDadosMovimentoLancamento}
                            setValidarLancamento={props.setValidarLancamento} refAtendimento={refAtendimento} setAdicionando={setAdicionando} liquidar={liquidar} setLiquidar={setLiquidar} />
                        <CadastroLiquidacaoParcelado propsFormik={propsFormik} id={id} tabLancamento={props.tabLancamento} dadosMovimentoLancamento={props.dadosMovimentoLancamento} setIdAtendimento={setIdAtendimento} limparArquivos={() => setLimparArquivos(true)} dataArquivos={dataArquivos} setDadosMovimentoLancamento={props.setDadosMovimentoLancamento}
                            setValidarLancamento={props.setValidarLancamento} setAdicionando={setAdicionando} />
                        <div >
                            <div>
                                <div className='separator mt-40 mb-5'></div>
                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} idTooltip={'salvar'} />
                                    {(props.modal || !id) && <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} idTooltip={'salvarENovo'} />
                                    }
                                </div>
                            </div>
                            <ModalDuasOpcoes showModal={showModalValidaGuiaBaixada} setShowModal={setShowModalValidaGuiaBaixada}
                                funcaoOpcao2={() => { Submit(propsFormik.values, propsFormik); setShowModalValidaGuiaBaixada(false) }}
                                funcaoOpcao1={() => { setShowModalValidaGuiaBaixada(false) }}
                                esconderFechar mensagem={<>{msgValidacao}<br /></>}
                                textoOpcao1={'Fechar'}
                                classeOpcao1={'btn-sm btn-primary-modal simples'}
                                textoOpcao2={'Continuar'} />

                        </div>
                    </>
                    : <></>}
            </Form>
            )}
        </Formik>

    )
}
export default Lancamento