import React, { useEffect, useState, useRef } from 'react'
import Modal from 'react-modal'
import '../assets/css/modal.css'
import { ListaSimples } from './Listas'
import { BotaoPadrao, BotaoModal, BotaoSecundario } from './Botoes'
import { InputPadrao, InputAutocomplete } from './Inputs'
import { Formik, Form } from "formik";
import { getApi } from '../util/Configuracoes'
import Yup from '../util/Validacoes';
import { LoadRelatorio } from './Loading'


const ModalCadastro = (props) => {
    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape")
                props.setShowModal(false)
        }, false);
    }, []);

    const customStyles = {
        content: {
            left: 'unset',
            top: '0',
            right: '0',
            bottom: 'auto',
            height: '100%',
            width: '400px'
        }
    };

    return (
        <div>
            <Modal
                isOpen={props.show}
                contentLabel="Modal #1 Global Style Override Example"
                style={props.tamanho || customStyles}>
                <div className="modal-header">
                    <h5 class="modal-title"><span>{props.titulo}</span></h5>
                    <button class="close" onClick={() => { props.setShowModal(!props.show);props.zerarTotal && props.zerarTotal(0); props.setVersoes && props.setVersoes([])}}><span aria-hidden="true">×</span></button>
                </div>
                {props.children}
            </Modal>
        </div>
    );
}

const ModalCalendar = (props) => {

    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape")
                props.setShowModal(false)
        }, false);
    }, []);

    const customStyles = {
        content: {
            left: 'unset',
            top: '0',
            right: '0',
            bottom: 'auto',
            height: '100%',
            width: '400px'
        }
    };

    return (
        <div>
            <Modal
                isOpen={props.show}
                contentLabel="Modal #1 Global Style Override Example"
                style={customStyles}>
                <div className="modal-header modal-header-calendar">
                    <h5 class="modal-title"><span>{props.titulo}</span></h5>
                    <button class="close" onClick={() => props.setShowModal(!props.show)}><span aria-hidden="true">×</span></button>
                </div>
                {props.children}
            </Modal>
        </div>
    );
}

const ModalSenha = (props) => {


    useEffect(() => {
        Modal.setAppElement('body');
        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape")
                props.setShowModal(false)
        }, false);
    }, []);

    const customStyles = {
        content: {
            left: 'unset',
            top: '0',
            right: '0',
            bottom: 'auto',
            height: '100%',
            width: '400px'
        }
    };

    return (
        <>
            <div className="card-usuario-nav-link" onClick={() => props.setShowModal(!props.showModal)}>
                <span className="icone-card-usuario icon-Key-Lock"></span>
                <span className="item-titulo-card-usuario">Alterar senha</span>
            </div>
            <Modal
                isOpen={props.showModal}
                contentLabel="Modal #1 Global Style Override Example"
                style={customStyles}>
                <div className="modal-header">
                    <h5 className="modal-title"><span>{props.titulo}</span></h5>
                    <button className="close" onClick={() => props.setShowModal(!props.showModal)}><span aria-hidden="true">×</span></button>
                </div>
                {props.children}
            </Modal>
        </>
    );
}

const ModalPadraoCadastro = (props) => {

    useEffect(() => {
        Modal.setAppElement('body');
        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape" && (props.fecharComEsc === true || props.fecharComEsc === undefined))
                props.setShowModal(false)
        }, false);
    }, []);

    const customStyles = {
        content: {
            left: 'unset',
            top: '0',
            right: '0',
            bottom: 'auto',
            height: '100%',
            width: '400px'
        }
    }
    return (
        <Modal
            isOpen={props.showModal}
            contentLabel="Modal #1 Global Style Override Example"
            style={customStyles}>
            <div className="modal-header">
                <h5 className="modal-title"><span>{props.titulo}</span></h5>
                <button className="close" onClick={() => props.setShowModal(!props.showModal)}><span aria-hidden="true">×</span></button>
            </div>
            {props.children}
        </Modal>
    );
}

const ModalTelefone = (props) => {

    const { show, setShowModal } = props
    const { data, setData } = props;
    const [isSubmit, setIsSubmit] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [dataTipoTelefone, setDataTipoTelefone] = useState([])
    const [limparCampo, setLimparCampo] = useState(false)
    const [carregando, setCarregando] = useState(true)


    const { history } = props

    let validacaoInicial = Yup.object({
        tipo: Yup.string(),
        telefone: Yup.string()
            .required()
            .max(30)
    })

    useEffect(() => {
        if (show) {
            setValoresIniciais({
                tipo_telefone: { value: 1, label: 'Principal' },
                telefone: data.length > 0 ? "" : props.inicial.value
            })
        }
    }, [show])

    useEffect(() => {
        if (data.length == 0 && show == true) {
            setLimparCampo(true)
        }
        setValoresIniciais({
            tipo_telefone: { value: 1, label: 'Principal' },
            telefone: ""
        })
    }, [data])

    useEffect(() => {
        if (dataTipoTelefone.length > 0)
            setCarregando(false)
        else
            setCarregando(true)

    }, [dataTipoTelefone])

    useEffect(() => {
        getApi(setDataTipoTelefone, '/parametros/tipoTelefoneCombo')
        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape")
                setShowModal(false)
        }, false);
    }, [])

    useEffect(() => {
        if (limparCampo) {
            props.setFieldValue("")
        }
    }, [limparCampo])

    const renderList = (item, idx) => {
        return <><span>{item.tipo_telefone && item.tipo_telefone.label + ' - ' + item.telefone || item.numero}</span></>
    }

    const novoTelefone = (values, { resetForm }) => {
        setLimparCampo(false)
        const newDataTelefone = [...data]
        values = { tipo_telefone: values.tipo_telefone, telefone: values.telefone.replace(/[_]/g, '') }
        newDataTelefone.push(values)
        setData(newDataTelefone)
        resetForm({})
    }

    const filterColors = (inputValue) => {

        if (dataTipoTelefone !== undefined) {
            return dataTipoTelefone.filter(i =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
            ).slice(0, 10);
        }
    }

    const loadOptions = (inputValue, callback) => callback(filterColors(inputValue));

    const removerLinha = (idx) => {
        var newData = data.filter((item, idx2) => idx2 !== idx)
        setData(newData);
    }
    const refAtualizacao = useRef(null)


    return (<ModalCadastro titulo='Telefones' show={show} setShowModal={setShowModal}>
        <Formik
            initialValues={valoresIniciais}
            validationSchema={validacaoInicial}
            enableReinitialize={true}
            onSubmit={novoTelefone}>
            {propsFormik => (
                <Form>
                    <div ref={refAtualizacao}>
                        {carregando === true ? <LoadRelatorio refLoading={refAtualizacao} heigth={320} /> :
                            <>
                                <InputAutocomplete
                                    label="Tipo"
                                    name="tipo_telefone"
                                    type="select"
                                    placeholder="Tipo" obrigatorio="true"
                                    onChange={option => propsFormik.setFieldValue("tipo_telefone", option)}
                                    propsFormik={propsFormik}
                                    loadOptions={loadOptions} />
                                <InputPadrao
                                    mascaraTelefone={true}
                                    label="Telefone"
                                    name="telefone"
                                    type="text"
                                    placeholder="Telefone" obrigatorio="true" classeTooltip={"tooltip-input-modal"}
                                />
                                <div className="form-group col-12 flex-direita">
                                    <BotaoPadrao texto="Adicionar" disabled={isSubmit} classe="btn-sm btn-primary primary" />
                                </div>
                            </>}
                    </div>
                </Form>)}
        </Formik>
        {carregando !== true && <ListaSimples data={data || []} setData={setData} render={renderList} numerada excluivel funcaoRemover={(idx) => removerLinha(idx)} />}
    </ModalCadastro>)
}

const ModalDuasOpcoes = (props) => {
    useEffect(() => {
        Modal.setAppElement('body');
    }, []);

    const customStyles = {
        content: {
            top: 'calc(40% - 100px)',
            left: 'calc(50% - 200px)',
            height: '200px',
            width: '400px'
        }
    }

    const funcaoClick = (funcao) => {
        if (props.naoFecharNoClick)
            props.setShowModal(false)

        funcao();
    }

    return (
        <>
            <Modal
                isOpen={props.showModal}
                contentLabel="Modal #1 Global Style Override Example"
                style={customStyles} className='modal-content'>
                <div className='modal-header'>
                    <span><b>{props.titulo ? props.titulo : 'Atenção'}</b></span>
                </div>
                <div className='modal-body custom-scrollbar scrollbar-primario' style={{ maxHeight: '300px' }}>
                    <span>{props.mensagem}</span>
                    {props.children}
                </div>
                <div className='modal-footer'>
                    {props.esconderOpcao1 == true ? <></> : <BotaoPadrao texto={props.textoOpcao1} disabled={props.salvando} classe={props.classeOpcao1 ? props.classeOpcao1 : "btn-sm btn-primary-modal primary"} click={() => funcaoClick(() => props.funcaoOpcao1())} />}
                    {props.esconderOpcao2 == true ? <></> :
                        (<>
                            {props.textoOpcao2 && <BotaoPadrao texto={props.textoOpcao2} disabled={props.salvando} classe={props.classeOpcao2 ? props.classeOpcao2 : "btn-sm btn-primary-modal primary"} click={() => funcaoClick(() => props.funcaoOpcao2())} />}
                        </>)
                    }
                </div>
            </Modal>
        </>
    );
}

const ModalEscolha = props => {
    useEffect(() => {
        Modal.setAppElement('body');
    }, []);

    const customStyles = {
        content: {
            top: 'calc(40% - 100px)',
            left: 'calc(50% - 200px)',
            height: '200px',
            width: '400px'
        }
    }

    return (
        <Modal isOpen={props.showModal} contentLabel="Modal #1 Global Style Override Example" style={customStyles} className='modal-content'>
            <div className='modal-header'>
                <span>{props.mensagem}</span>
            </div>
            <div className='modal-body'>
                {props.children}
            </div>
            <div className='modal-footer'>
                {props.confirmar && props.funcaoConfirmar &&
                    <BotaoPadrao texto={'Confirmar'} classe={'btn btn btn-primary primary'} click={_ => { props.setShowModal(false); props.funcaoConfirmar() }} />}

                <BotaoPadrao texto={'Cancelar'} classe={'btn-sm btn-primary-modal simples'} click={_ => {
                    if (props.funcaoCancelar) props.funcaoCancelar();
                    props.setShowModal(false)
                }} />
            </div>
        </Modal>
    )
}


const ModalBloqueado = (props) => {

    useEffect(() => {
        Modal.setAppElement('body');
    }, []);

    const customStyles = {
        content: {
            top: 'calc(2%)',
            left: 'calc(1%)',
            height: '96%',
            width: '98%'
        }
    }

    return (
        <>
            <div className="modal-bloqueado">
                <Modal
                    isOpen={props.showModal}
                    style={customStyles}
                    contentLabel="Modal #1 Global Style Override Example"
                >
                    <div className='modal-body modal-bloqueado' >
                        {props.children}
                    </div>
                </Modal>
            </div>

        </>
    );
}

const ModalVisualizacao = (props) => {
    useEffect(() => {
        Modal.setAppElement('body');
    }, []);

    return (
        <ModalPadraoCadastro {...props}>
            <div><div className='detalhes'>
                {props.data.map((item, idx) => {
                    return (
                        <>
                            {idx === 0 ? <></> : <hr />}
                            <div className='item-detalhes'>
                                <h6><b>{item.titulo}</b></h6>
                                <h6>{item.descricao}</h6>
                                {item.html}
                            </div></>
                    )
                })}
            </div>
            </div>
        </ModalPadraoCadastro>
    );
}

const ModalCenter = (props) => {
    useEffect(() => {
        document.addEventListener("keydown", (e) => {
            if (e.key == "Escape")
                props.setShowModal(false)
        }, false);
    }, []);

    const customStyles = {
        content: {
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            height: props.height,
            width: props.width
        }
    };

    return (
        <div>
            <Modal
                isOpen={props.show}
                contentLabel="Modal #1 Global Style Override Example"
                style={customStyles}>
                <div className="modal-header">
                    <h5 class="modal-title"><span>{props.titulo}</span></h5>
                    <button class="close" onClick={() => props.setShowModal(!props.show)}><span aria-hidden="true">×</span></button>
                </div>
                {props.children}
            </Modal>
        </div>
    );
}



export { ModalCadastro, ModalSenha, ModalTelefone, ModalCalendar, ModalPadraoCadastro, ModalDuasOpcoes, ModalVisualizacao, ModalBloqueado, ModalCenter, ModalEscolha }
