import React, { useRef, useState } from 'react'
import { Imprimir, Voltar, BtnAcao, MinimizarDados } from './Acoes'
import ReactToPrint from 'react-to-print'
import { meses } from '../util/Utilitarios'
import { withRouter } from 'react-router-dom'
import '../assets/css/visualizacao.css'
import Interweave from 'interweave';
import { getUsuario } from '../util/Configuracoes'
import moment from 'moment'

const tipoGrupo = grupo => {
    const obj = {
        'tabela': <table className="table table-responsive-md table-visualizacao" >
            <thead>
                {grupo.thead}
            </thead>
            <tbody>
                {(grupo.tbody && grupo.tbody.length && grupo.tbody) || <tr><td className='div-center' colSpan={grupo.thead && grupo.thead.props.children.length}>Sem Registros!</td></tr>}
                {(grupo.aposGrupo ? grupo.aposGrupo : <></>)}
            </tbody>
        </table>,
        default: () => grupo.colunas && grupo.colunas.map((coluna, index) => coluna &&
            <div key={index} className={coluna.tamanho + ' col-sm-12 mb-20'}>
                <span className="data-title">{coluna.titulo}</span>
                {coluna.htmlDireto ? coluna.htmlDireto : 
                (coluna.html ? <Interweave content={coluna.descricao.toString()} /> : <span >{coluna.descricao}</span>)}
            </div>
        )
    }

    return obj[grupo.tipoGrupo] || obj.default()
}

const VisualizacaoPadrao = props => {
    const user = getUsuario()
    const reference = useRef();
    const btnPrint = useRef();
    const actualDate = new Date();
    const data = props.data
    const [minimizar, setMinimizar] = useState([])

    return (
        <div className="card" ref={reference}>
            <ReactToPrint
                trigger={() => <button className={"hidden d-none"} ref={btnPrint} />}
                content={() => reference.current} pageStyle={`@page { size: ${props.orientacao || 'a4 auto'} }`} />
            {
                <div className="card-body">
                    <div className={'d-block-print'}>
                        <div className={'col-12 row mt-3'}>
                            <div className="col-8">
                                <h5>{data.titulo}</h5>
                                <h6>{actualDate.getDate().toString().padStart(2, '0') + ' de ' + (meses[actualDate.getMonth() + 1] || meses.default) + ' de ' + actualDate.getFullYear()}</h6>
                            </div>
                            <div className="col-4 text-right">
                                <img alt='logo' className="logo-menu" src={require('../assets/img/logo-menu.png')}></img>
                            </div>
                        </div>
                    </div>
                    <div className={'d-none-print colored'}>
                        <div className={'col-12 row mr-0 ml-0 mb-1 pr-0 pl-0'}>
                            <div className="col-12 row-space-between pr-0 pl-0">
                                <div>
                                    {/* <BtnAcao icone="icon-Arrow-Left" texto="Voltar" action={() => props.history.goBack()} /> */}
                                </div>
                                <div className="text-right">
                                    <Imprimir action={() => btnPrint.current.click()} />
                                </div>
                            </div>
                            <div className="col-12 titulo-visualizacao">
                                <h5>{data.titulo}</h5>
                                <h6>{actualDate.getDate().toString().padStart(2, '0') + ' de ' + (meses[actualDate.getMonth() + 1] || meses.default) + ' de ' + actualDate.getFullYear()}</h6>
                            </div>
                        </div>
                    </div>
                    {data.grupos ? data.grupos.filter(x => x.visivel !== false).map((grupo, index, arrayAtual) => grupo &&
                        <div className={"dados-visualizacao " + (minimizar[index] === false ? "d-none-print" : "")} key={index}>

                            <div className="group-title row-space-between ">
                                <h5> {grupo.titulo} </h5>
                                {grupo.minimizar ? <div className='d-none-print'> <MinimizarDados className={"visualizacao " + (minimizar[index] === false ? "strikediag" : "")}
                                    texto={minimizar[index] === true || minimizar[index] === undefined ? "Esconder na Impressão " : "Mostrar na Impressão"} action={() => {
                                        if (minimizar.length > 0) {
                                            let cloneArray = [...minimizar]
                                            cloneArray[index] = !cloneArray[index]
                                            setMinimizar(cloneArray)
                                        } else {
                                            let cloneArray = Array(arrayAtual.length).fill(true)
                                            cloneArray[index] = false
                                            setMinimizar(cloneArray)
                                        }

                                    }

                                    } /> </div> : <></>}

                            </div>
                            <div className="row">
                                {tipoGrupo(grupo)}
                            </div>
                        </div>
                    ) : <div className="dados-visualizacao">
                        <div className="group-title">
                            <h5>Não há dados!</h5>
                        </div>
                    </div>}
                    {
                        props.visualizacaoAssinatura == true ?
                            <div className="group-title assinatura">
                                <span className='linha-assinatura'>
                                    <span>Responsável</span>
                                </span>
                            </div> :
                            <></>
                    }
                    <div className="footer-only-print-visualizacao">
                        <div className="col-md-12">
                            <span><i>Visualização Emitida <b>{user.nome}</b> no dia {moment().format('DD/MM/YYYY')} às {moment().format('HH:mm:ss')}</i></span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default VisualizacaoPadrao
export {tipoGrupo}