import React, { useState, useRef, useEffect, useReducer } from 'react'
import { AdicionarMovimento, BtnAcao, Detalhes, EmitirRecibo, ExportarLancamento, PopoverConsultaLancamento, RemoverLiquidacao } from '../../../../components/Acoes'
import DataGridAlternativa from '../../../../components/DataGridAlternativa'
import { get, excluir, visualizar, postComAcao, validar, putComAcao } from '../../../../util/AcessoApi'
import copy from 'copy-to-clipboard'
import {
    LinkBotao, Excluir, Visualizar, Btn, LiquidacaoLancamento, AlterarLancamentoFinanceiro, CancelarLancamento, AdicionarCheque, AdicionarGuia
} from '../../../../components/Acoes'
import { getUsuario } from '../../../../util/Configuracoes';
import { ACOES, MODULO } from '../../../../util/Enums';
import { convertValueToFloat, distinctArr, formatarMoeda, mensagens, useWindowSize } from '../../../../util/Utilitarios';
import { mostrarToast } from '../../../../components/Toasts';
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown';
import { ModalCadastro, ModalDuasOpcoes, ModalPadraoCadastro } from '../../../../components/Modais'
import { FieldArray, Form, Formik } from 'formik'
import { InputCurrency, InputDatePicker, InputValor, TextArea } from '../../../../components/Inputs'
import { InputAtendimentoFinanceiro, InputModoLiquidacao, InputMovimentoFinanceiroLiquidacao, InputChequeDetalhado } from '../../../../components/InputsAutoComplete'
import Yup, { validaData } from '../../../../util/Validacoes'
import { BotaoPadrao } from '../../../../components/Botoes'
import { Overlay, Tooltip } from 'react-bootstrap'
import { FiAlertTriangle } from 'react-icons/fi'
import moment from 'moment'
import { InputDataRecibo, InputAssinaturaRecibo, InputSocioAtivos, InputEmpresa } from '../../../../components/InputsAutoCompleteV2'
import { useHistory } from 'react-router-dom'

const ConsultaLancamento = (props) => {
    const [data, setData] = useState([])
    const [dados, setDados] = useState([])
    const [width] = useWindowSize()
    const target = useRef(null)
    const [copiado, setCopiado] = useState(false)
    const history = useHistory()
    const [modalLiquidacao, setModalLiquidacao] = useState(false)
    const [modalCancelamento, setModalCancelamento] = useState(false)
    const [modalRemoverLiquidacao, setModalRemoverLiquidacao] = useState(false)
    const [modalMovimento, setModalMovimento] = useState(false)
    const [modalAdicionarCheque, setModalAdicionarCheque] = useState(false)
    const [modalAdicionarGuia, setModalAdicionarGuia] = useState(false)
    const [idLancamento, setIdLancamento] = useState()
    const [detalhesLancamento, setDetalhesLancamento] = useState()
    const [showModalDetalhes, setShowModalDetalhes] = useState(false)
    const [itemDetalhes, setItemDetalhes] = useState([])
    const [modalRecibo, setModalRecibo] = useState(false)
    const [socioRecibo, setSocioRecibo] = useState(null)
    const [empresaRecibo, setEmpresaRecibo] = useState(null)

    useEffect(() => {
        get('/financeiro/lancamentoSubtituloCor', null, null, null).then(val => {
            if (val && val.length) {
                setDados(val)
            }
            else {
                setDados({})
            }
        })
    }, [])

    const columns = React.useMemo(
        () => [
            {
                id: 'consulta-cbos',
                columns: [
                    {
                        Header: 'Código',
                        accessor: 'id',
                        html: item => {
                            return (
                                <>
                                    <span className='icones-consulta-lancamento  min-width-125'>
                                        <span className='status-lancamento' style={{ background: item.cor_status }}></span>
                                        <span style={{ marginTop: 8 }}> {item.id}</span>

                                        <span style={{ marginTop: 7 }}>
                                            {item.tipo_lancamento_id ?
                                                <PopoverConsultaLancamento id={item.tipo_lancamento_id} copiado={copiado} onToggle={() => setCopiado(false)}
                                                    titulo={
                                                        <>
                                                            <div className='text-center color-gray' style={{ fontSize: 13 }}>
                                                                <b>Tipo Lançamento:</b>
                                                            </div>
                                                        </>}
                                                    info={
                                                        <div style={{ marginTop: '-15px', padding: '10px', marginLeft: '30px' }}>
                                                            <span><b>{item.tipo_lancamento}</b></span>
                                                            <br />
                                                        </div>
                                                    }>
                                                    {<span className={item.tipo_lancamento_id == 1 ? 'icon-Add  ' : 'icon-Remove  '} style={{ color: item.tipo_lancamento_cor }} ></span>}
                                                </PopoverConsultaLancamento>

                                                : <></>

                                            }
                                            {item.parcelado.includes('Sim') ?
                                                <PopoverConsultaLancamento id={item.numero_parcela} copiado={copiado} onToggle={() => setCopiado(false)}
                                                    titulo={
                                                        <>
                                                            <div className='text-center color-gray' style={{ fontSize: 13 }}>
                                                                <b>Informações Parcela:</b>
                                                            </div>
                                                        </>}
                                                    info={
                                                        <div style={{ marginTop: '-15px', padding: '10px' }}>
                                                            <span>Nº Parcela: <b>{item.numero_parcela}</b></span>
                                                            <br />
                                                            <span>Lanc. Principal: <b>{item.lancamento_pai}</b></span>
                                                        </div>
                                                    }>
                                                    {<span className={item.parcelado.includes('Sim') ? 'icon-observacao red-color' : ''} style={{ marginLeft: 10, marginTop: 8 }} ></span>}
                                                </PopoverConsultaLancamento>

                                                : <span style={{ marginLeft: 23, marginTop: 8 }} >{''}</span>

                                            }
                                        </span>
                                    </span>
                                </>
                            )
                        },
                        ordenamento: {
                            tipo: 'numero',
                            ativo: true,
                            modo: 'desc',
                        }
                    },
                    {
                        Header: 'Aten.(Guias)',
                        accessor: 'guia_id',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Movimento',
                        accessor: 'movimento',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Data',
                        accessor: 'data_prevista',
                        ordenamento: {
                            tipo: 'data',
                        }
                    },
                    {
                        Header: 'Movimento Financeiro',
                        accessor: 'movimento_financeiro',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'movimento_financeiro',
                            idColuna: 'movimento_financeiro_id',

                        }
                    },
                    {
                        Header: 'Movimento (SIM/NÃO)',
                        accessor: 'movimento_sim_nao',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'possui_movimento',
                            idColuna: 'movimento_sim_nao',
                        },
                    },
                    {
                        Header: 'Cliente ',
                        accessor: 'entidade',
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'cliente',
                            idColuna: 'cliente_id'
                        }
                    },
                    {
                        Header: 'Empresa',
                        accessor: 'empresa_lancamento_nome',
                        visivel: true,
                        apenasDataGrid: true,
                        filtro: {
                            tipo: 'empresa',
                            idColuna: 'empresa_id'
                        }
                    },
                    {
                        Header: 'Lanc. com Guia?',
                        accessor: 'guia_sim_nao',
                        visivel: false,
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'lancamento_com_guia',
                            idColuna: 'guia_sim_nao'
                        }
                    },
                    {
                        Header: 'Liquidado?',
                        accessor: 'liquidacao',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'liquidado',
                            idColuna: 'liquidacao',
                            boolean: true
                        },
                    },
                    {
                        Header: 'Tipo de Lançamento',
                        accessor: 'tipo_lancamento_id',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'tipo_lancamento',
                            idColuna: 'tipo_lancamento_id',
                        },
                    },
                    {
                        Header: 'Categoria',
                        accessor: 'categoria_id',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'categoria_financeiro',
                            idColuna: 'categoria_id',
                        },
                    },
                    {
                        Header: 'Forma de Pagamento ',
                        accessor: 'modo_pagamento',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'tipo_pagamento',
                            idColuna: 'modo_pagamento'
                        }
                    },
                    {
                        Header: 'Sócio',
                        accessor: 'socio',
                        html: item => <span>{distinctArr(item.socio).join(', ')}</span>,
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'socio',
                            idColuna: 'socio_id',
                            boolean: true
                        },
                    },
                    {
                        Header: 'Estab/Op. De Saúde',
                        accessor: 'estab_op_saude',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Estabelecimento(s) de Saúde',
                        accessor: 'estabelecimento_saude',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'estabelecimento_saude',
                            idColuna: 'estabelecimento_saude_id',
                        },
                    },
                    {
                        Header: 'Operadora(s) de Sáude',
                        accessor: 'operadora_saude',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'operadora_saude',
                            idColuna: 'operadora_saude_id',
                        },
                    },
                    {
                        Header: 'Valor',
                        accessor: 'valor_total_formatado',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Data Liquidação',
                        accessor: 'data_liquidacao',
                        ordenamento: {
                            tipo: 'data',
                        }
                    },
                    {
                        Header: 'Data Prevista Inicial',
                        accessor: 'data_prevista_inicial',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_prevista_inicial',
                            idColuna: 'data_prevista_inicial',
                            datePicker: true,
                            date: true
                        }
                    },
                    {
                        Header: 'Data Prevista Final',
                        accessor: 'data_prevista_final',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_prevista_final',
                            idColuna: 'data_prevista_final',
                            datePicker: true,
                            date: true
                        }
                    },
                    {
                        Header: 'Data Liquidação Inicial',
                        accessor: 'data_liquidacao_inicial',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_liquidacao_inicial',
                            idColuna: 'data_liquidacao_inicial',
                            datePicker: true,
                            date: true
                        }
                    },
                    {
                        Header: 'Data Liquidação Final',
                        accessor: 'data_liquidacao_final',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_liquidacao_final',
                            idColuna: 'data_liquidacao_final',
                            datePicker: true,
                            date: true
                        }
                    },

                    {
                        Header: 'Estado Lançamento',
                        accessor: 'cor_status',
                        visivel: false,
                        apenasDataGrid: true,
                        filtro: {
                            tipo: 'estado_lancamento',
                            idColuna: 'estado_lancamento'
                        }
                    },
                    {
                        Header: 'Status',
                        accessor: 'status_id',
                        visivel: false,
                        apenasDataGrid: true,
                        filtro: {
                            tipo: 'status_lancamento',
                            idColuna: 'status_id'
                        }
                    },
                    {
                        Header: 'Particular',
                        accessor: 'guia_particular',
                        visivel: false,
                        apenasDataGrid: true,
                        filtro: {
                            tipo: 'input_sim_nao',
                            idColuna: 'guia_particular'
                        }
                    },
                    {
                        Header: 'Valor Liquidado',
                        accessor: 'valor_liquidado',
                        ordenamento: {
                            tipo: 'texto'
                        }
                    },
                    {
                        Header: 'Possui NFSe?',
                        accessor: 'possui_nfse',
                        visivel: true,
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'possui_nfse',
                            idColuna: 'possui_nfse',
                        },
                    },
                    {
                        Header: 'Data Registro Inicial',
                        accessor: 'data_registro_inicial',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_inicial',
                            idColuna: 'data_registro_inicial',
                            datePicker: true,
                            date: true
                        }
                    },
                    {
                        Header: 'Data Registro Final',
                        accessor: 'data_registro_final',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'data',
                        },
                        filtro: {
                            tipo: 'data_final',
                            idColuna: 'data_registro_final',
                            datePicker: true,
                            date: true
                        }
                    },
                    {

                        Header: 'Usuario Inserção',
                        accessor: 'usuario_insercao',
                        visivel: false,
                        apenasDataGrid: true,
                        ordenamento: {
                            tipo: 'texto'
                        },
                        filtro: {
                            tipo: 'usuario_top',
                            idColuna: 'usuario_id_insercao',

                        }
                    },
                ],
            },
        ],
        []
    )

    const obterDetalhes = async (props) => {
        let { id } = props
        setItemDetalhes({ lancamento: [{ titulo: 'Carregando...' }] })

        let dados = {}
        await Promise.resolve(get(`/financeiro/obterModoPagamentoLancamento/${id}`)).then(response => {
            dados.lancamento = [
                { titulo: 'Código', descricao: id },
                { titulo: 'Empresa', descricao: props.empresa_lancamento_nome },
                { titulo: 'Cliente', descricao: props.entidade },
                { titulo: 'CPF/CNPJ', descricao: props.cpf_cnpj },
                { titulo: 'Estabelecimento de Saúde', descricao: props.estabelecimento_saude },
                { titulo: 'Descrição', descricao: props.descricao },
                { titulo: 'Tipo Pagamento', descricao: props.modo_pagamento_sigla },
                { titulo: 'Tipo Documento', descricao: props.tipo_documento_lancamento },
                { titulo: 'NFse/Documento', descricao: props.nfse_documento },
                { titulo: 'Data Liquidação', descricao: props.data_liquidacao },
                { titulo: 'Valor ', descricao: (response[0].modo_pagamento_sigla !== '' ? props.valor_liquidado + ' (' + response[0].modo_pagamento_sigla + ')' : '') },
            ]
        })
        await Promise.resolve(get(`/financeiro/guialancamento/${id}`)).then(val2 => {
            dados.guia = val2;
        })
        // dados.guia = [
        //     { titulo: 'Código', descricao: props.guia_id },
        //     { titulo: 'Paciente', descricao: props.nome_beneficiario },
        //     { titulo: 'Data Procedimento', descricao: props.data_procedimento },
        //     { titulo: 'Valor', descricao: formatarMoeda(props.valor_guia) },
        // ]

        await Promise.resolve(get(`/financeiro/obterdetalhesmovimento/${id}`)).then(response => {
            dados.movimento = [
                { titulo: 'Código', descricao: response[0].id },
                { titulo: 'Entidade', descricao: response[0].cliente },
                { titulo: 'Data ', descricao: response[0].data_movimento },
                { titulo: 'Status', descricao: response[0].status },
            ]

        })

        await Promise.resolve(get(`/financeiro/notafiscallancamento`, { id: id })).then(val => {
            dados.nota_fiscal = val
        })

        setItemDetalhes(dados)

    }

    const subtitulo = () => {
        return <div className='guia-legenda'> {
            dados && dados.map(x => <>
                <span className='legenda' style={{ background: x.cor }}>{x.descricao}</span>
            </>)
        } </div>
    }

    const OutrasAcoes = ({ propsAcao, ...props }) => {
        return (
            <Dropdown >
                <DropdownTrigger>
                    <Btn icone='icon-ellipsi' texto="Mais Ações" />
                </DropdownTrigger>
                <DropdownContent>
                    <div className="card-acoes-dropdown">
                        <div className="ajusta-dropdown-consulta-guia">
                            {[
                                <Excluir to={'/financeiro/movimento/consulta/'} action={() => exclusao(propsAcao)} />,
                                !propsAcao.row.data_liquidacao ?
                                    <LiquidacaoLancamento action={async () => {
                                        try {
                                            const validarLiquidacao = await get('/financeiro/validarliquidacao', { id: propsAcao.row.id });

                                            if (!validarLiquidacao.length) {
                                                const validarCancelamento = await get('/financeiro/validarcancelamento', { id: propsAcao.row.id });

                                                if (validarCancelamento[0].status_id != 2) {
                                                    setModalLiquidacao(true)
                                                    setIdLancamento(propsAcao.row.id)
                                                    setDetalhesLancamento(propsAcao.row)
                                                } else {
                                                    mostrarToast('erro', 'Operação não permitida! Lançamento cancelado!');
                                                }
                                            }
                                            else {
                                                mostrarToast('erro', 'Operação não permitida! Lançamento liquidado!')
                                            }
                                        }
                                        catch (e) {
                                            console.log('e: ', e);
                                            mostrarToast('erro', mensagens['erro']);
                                        }
                                    }} /> : <></>,
                                propsAcao.row.data_liquidacao && !propsAcao.row.movimento_financeiro_id ? <AdicionarMovimento
                                    action={async () => {
                                        try {
                                            const status = await get('/financeiro/statuslancamento', { id: propsAcao.row.id })

                                            if (status && status.status_id == '2') {
                                                mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado!')
                                                return
                                            }

                                            const usuarioId = getUsuario().id;
                                            const permissao = await get('/controleAcesso/permissao', { usuario_id: usuarioId, modulo_id: 116, acao_id: ACOES.ADICIONAR_MOVIMENTO });

                                            if (!permissao.length) {
                                                mostrarToast('erro', mensagens['permissao']);
                                                return;
                                            }
                                            setModalMovimento(true)
                                            setIdLancamento(propsAcao.row.id);
                                            setDetalhesLancamento(propsAcao.row)
                                        } catch (e) {
                                            console.log('e: ', e);
                                            mostrarToast('erro', mensagens['erro']);
                                        }
                                    }}
                                /> : <></>,
                                propsAcao.row.data_liquidacao ? <RemoverLiquidacao action={async () => {
                                    try {
                                        const status = await get('/financeiro/statuslancamento', { id: propsAcao.row.id })

                                        if (status && status.status_id == '2') {
                                            mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado!')
                                            return
                                        }

                                        const usuarioId = getUsuario().id;
                                        const permissao = await get('/controleAcesso/permissao', { usuario_id: usuarioId, modulo_id: 116, acao_id: ACOES.REMOVER_LIQUIDACAO });

                                        if (!permissao.length) {
                                            mostrarToast('erro', mensagens['permissao']);
                                            return;
                                        }

                                        setModalRemoverLiquidacao(true);
                                        setIdLancamento(propsAcao.row.id);
                                        setDetalhesLancamento(propsAcao.row)
                                    } catch (e) {
                                        console.log('e: ', e);
                                        mostrarToast('erro', mensagens['erro']);
                                    }
                                }} />
                                    : <></>,
                                <CancelarLancamento action={async () => {
                                    try {
                                        const status = await get('/financeiro/statuslancamento', { id: propsAcao.row.id })

                                        if (status && status.status_id == '2') {
                                            mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado!')
                                            return
                                        }

                                        const usuarioId = getUsuario().id;
                                        const permissao = await get('/controleAcesso/permissao', { usuario_id: usuarioId, modulo_id: 116, acao_id: ACOES.CANCELAR_LANCAMENTO });

                                        if (permissao.length) {
                                            setModalCancelamento(true);
                                            setIdLancamento(propsAcao.row.id);
                                            setDetalhesLancamento(propsAcao.row)
                                        } else {
                                            mostrarToast('erro', mensagens['permissao'])
                                        }
                                    } catch (e) {
                                        console.log('e: ', e);
                                        mostrarToast('erro', mensagens['erro']);
                                    }
                                }} />,
                                <AdicionarCheque action={async () => {
                                    try {
                                        const status = await get('/financeiro/statuslancamento', { id: propsAcao.row.id })

                                        if (status && status.status_id == '2') {
                                            mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado!')
                                            return
                                        }

                                        const usuarioId = getUsuario().id;
                                        const permissao = await get('/controleAcesso/permissao', { usuario_id: usuarioId, modulo_id: 116, acao_id: ACOES.ADICIONAR_CHEQUE });

                                        if (permissao.length) {
                                            setModalAdicionarCheque(true);
                                            setIdLancamento(propsAcao.row.id);
                                            setDetalhesLancamento(propsAcao.row)
                                        } else {
                                            mostrarToast('erro', mensagens['permissao'])
                                        }
                                    } catch (e) {
                                        console.log('e: ', e);
                                        mostrarToast('erro', mensagens['erro']);
                                    }
                                }} />,
                                <AdicionarGuia action={async () => {
                                    try {
                                        const status = await get('/financeiro/statuslancamento', { id: propsAcao.row.id })

                                        if (status && status.status_id == '2') {
                                            mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado!')
                                            return
                                        }

                                        const usuarioId = getUsuario().id;
                                        const permissao = await get('/controleAcesso/permissao', { usuario_id: usuarioId, modulo_id: 116, acao_id: ACOES.ADICIONAR_GUIA });

                                        if (permissao.length) {
                                            const status_movimento = await get('/financeiro/validarAdicionarGuia', { id: propsAcao.row.id });
                                            if (((status_movimento === 1 || status_movimento === 0)) || status_movimento == undefined) {
                                                setModalAdicionarGuia(true);
                                                setIdLancamento(propsAcao.row.id);
                                                setDetalhesLancamento(propsAcao.row)
                                            } else {
                                                mostrarToast('erro', 'Operação Bloqueada! Movimento Fechado ou Conferido.')
                                            }
                                        } else {
                                            mostrarToast('erro', mensagens['permissao'])
                                        }
                                    } catch (e) {
                                        console.log('e: ', e);
                                        mostrarToast('erro', mensagens['erro']);
                                    }
                                }} />,
                                <Detalhes action={async () => {
                                    const status = await get('/financeiro/statuslancamento', { id: propsAcao.row.id })

                                    if (status && status.status_id == '2') {
                                        mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado!')
                                        return
                                    }
                                    
                                    setShowModalDetalhes(true); obterDetalhes(propsAcao.row)
                                }} />,
                                <EmitirRecibo action={async () => {
                                    try {
                                        const status = await get('/financeiro/statuslancamento', { id: propsAcao.row.id })

                                        if (status && status.status_id == '2') {
                                            mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado!')
                                            return
                                        }

                                        const usuarioId = getUsuario().id;
                                        const permissao = await get('/controleAcesso/permissao', { usuario_id: usuarioId, modulo_id: 116, acao_id: ACOES.RECIBO });

                                        if (permissao.length) {
                                            if (propsAcao.row.guia_id)
                                                setEmpresaRecibo(propsAcao.row.empresa_guia_object)
                                            else
                                                setEmpresaRecibo(propsAcao.row.empresa_lancamento_object)

                                            setSocioRecibo(propsAcao.row.socio_object)
                                            setModalRecibo(true);
                                            setIdLancamento(propsAcao.row.id);
                                            setDetalhesLancamento(propsAcao.row)
                                        } else
                                            mostrarToast('erro', mensagens['permissao'])
                                    } catch (e) {
                                        mostrarToast('erro', mensagens['erro']);
                                    }
                                }} />,
                                <ExportarLancamento action={async () => {
                                    await Promise.resolve(get('/controleAcesso/permissao', {
                                        usuario_id: getUsuario().id,
                                        modulo_id: MODULO['/financeiro/lancamento'],
                                        acao_id: ACOES.EXPORTAR_LANCAMENTO
                                    }).then(function (val) {

                                        if (val.length) {
                                            history.push({ pathname: '/financeiro/lancamentofinanceiro/cadastro', state: { idLancamentoDiario: propsAcao.row.id } })

                                        }
                                        else {
                                            mostrarToast('erro', 'O usuário não possui permissão para esta ação! Contate um administrador.')
                                        }

                                    }).catch((e) => mostrarToast('erro', mensagens['erro'])))

                                }} />,
                            ]}
                        </div>
                    </div>
                </DropdownContent>
            </Dropdown>
        )

    }

    const exclusao = (propsAcao) => {
        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: props.modulo,
            acao_id: ACOES.EXCLUIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                excluir('/financeiro/lancamento', propsAcao.row.id,
                    () => {

                        target.current.atualizar();
                        mostrarToast('sucesso', mensagens['sucesso'])
                    },
                    (e) => {
                        if (e.response && e.response.data && e.response.data.erros)
                            e.response.data.erros.forEach((item) => mostrarToast('erro', item))
                        else
                            mostrarToast('erro', mensagens.erro)
                    }, propsAcao.row.descricao_auditoria)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => {
            console.log('e: ', e);
            mostrarToast('erro', mensagens['erro'])
        })

    }

    const alteracao = (propsAcao) => {
        Promise.all([
            get('/financeiro/validarliquidacao', { id: propsAcao.row.id }),
            get('/financeiro/statuslancamento', { id: propsAcao.row.id }),
        ]).then(([liquidacao, cancelado]) => {
            if (liquidacao.length)
                mostrarToast('erro', 'Operação não permitida! Lançamento Liquidado.')
            else if (cancelado && cancelado.status_id == '2')
                mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado!')
            else
                props.history.push('/financeiro/lancamento/alteracao/' + propsAcao.row.id)

        }).catch(error => mostrarToast('erro', mensagens['erro']))
    }


    const acoesObj = [ // AÇÕES PARA TELA 1920
        (propsAcao) => <Visualizar to={'/financeiro/lancamento/visualizacao/' + propsAcao.row.id} />,
        (propsAcao) => propsAcao.row.status_id !== 2 ? <AlterarLancamentoFinanceiro action={() => alteracao(propsAcao)} /> : <></>,
        (propsAcao) => propsAcao.row.status_id !== 2 ? <OutrasAcoes propsAcao={propsAcao} /> : <></>,
        (propsAcao) => propsAcao.row.status_id === 2 ? <Excluir to={'/financeiro/movimento/consulta/'} action={() => exclusao(propsAcao)} /> : <></>

    ]

    const acoesObj2 = [ // AÇÕES PARA TELA MENOR 1920
        (propsAcao) => <Visualizar to={'/financeiro/lancamento/visualizacao/' + propsAcao.row.id} />,
        (propsAcao) => propsAcao.row.status_id !== 2 ? <AlterarLancamentoFinanceiro action={() => alteracao(propsAcao)} /> : <></>,
        (propsAcao) => <Excluir to={'/financeiro/lancamento/consulta/'} action={() => exclusao(propsAcao)} />,

        (propsAcao) => propsAcao.row.status_id !== 2 && !propsAcao.row.data_liquidacao ? <LiquidacaoLancamento action={async () => {
            try {

                const validarLiquidacao = await get('/financeiro/validarliquidacao', { id: propsAcao.row.id });
                if (!validarLiquidacao.length) {

                    const validarCancelamento = await get('/financeiro/validarcancelamento', { id: propsAcao.row.id });

                    if (validarCancelamento[0].status_id != 2) {

                        setModalLiquidacao(true)
                        setIdLancamento(propsAcao.row.id)
                        setDetalhesLancamento(propsAcao.row)
                    } else {
                        mostrarToast('erro', 'Operação não permitida! Lançamento cancelado!');
                    }

                }
                else {
                    mostrarToast('erro', 'Operação não permitida! Lançamento liquidado!')
                }
            }
            catch (e) {
                console.log('e: ', e);
                mostrarToast('erro', mensagens['erro']);
            }

        }} />
            : <></>,
        (propsAcao) => propsAcao.row.status_id !== 2 && propsAcao.row.data_liquidacao && !propsAcao.row.movimento_financeiro_id ? <AdicionarMovimento
            action={async () => {
                try {
                    const status = await get('/financeiro/statuslancamento', { id: propsAcao.row.id })

                    if (status && status.status_id == '2') {
                        mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado!')
                        return
                    }

                    const usuarioId = getUsuario().id;
                    const permissao = await get('/controleAcesso/permissao', { usuario_id: usuarioId, modulo_id: 116, acao_id: ACOES.ADICIONAR_MOVIMENTO });

                    if (!permissao.length) {
                        mostrarToast('erro', mensagens['permissao']);
                        return;
                    }
                    setModalMovimento(true)
                    setIdLancamento(propsAcao.row.id);
                    setDetalhesLancamento(propsAcao.row)
                } catch (e) {
                    console.log('e: ', e);
                    mostrarToast('erro', mensagens['erro']);
                }
            }}
        /> : <></>,
        (propsAcao) => propsAcao.row.status_id !== 2 && propsAcao.row.data_liquidacao ? <RemoverLiquidacao action={async () => {

            try {
                const status = await get('/financeiro/statuslancamento', { id: propsAcao.row.id })

                if (status && status.status_id == '2') {
                    mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado!')
                    return
                }

                const usuarioId = getUsuario().id;
                const permissao = await get('/controleAcesso/permissao', { usuario_id: usuarioId, modulo_id: 116, acao_id: ACOES.REMOVER_LIQUIDACAO });

                if (!permissao.length) {
                    mostrarToast('erro', mensagens['permissao']);
                    return;
                }
                setModalRemoverLiquidacao(true);
                setIdLancamento(propsAcao.row.id);
                setDetalhesLancamento(propsAcao.row)
            } catch (e) {
                console.log('e: ', e);
                mostrarToast('erro', mensagens['erro']);
            }
        }} />
            : <></>,
        (propsAcao) => propsAcao.row.status_id !== 2 ? <CancelarLancamento action={async () => {

            try {
                const usuarioId = getUsuario().id;
                const permissao = await get('/controleAcesso/permissao', { usuario_id: usuarioId, modulo_id: 116, acao_id: ACOES.CANCELAR_LANCAMENTO });

                if (!permissao.length) {
                    mostrarToast('erro', mensagens['permissao']);
                    return;
                }

                const validarLiquidacao = await get('/financeiro/validarliquidacao', { id: propsAcao.row.id });

                if (!validarLiquidacao.length) {
                    const validarCancelamento = await get('/financeiro/validarcancelamento', { id: propsAcao.row.id });

                    if (validarCancelamento[0].status_id != 2) {
                        setModalCancelamento(true);
                        setIdLancamento(propsAcao.row.id);
                        setDetalhesLancamento(propsAcao.row)
                    } else {
                        mostrarToast('erro', 'Operação não permitida! Lançamento cancelado!');
                    }
                } else {
                    mostrarToast('erro', 'Operação não permitida! Lançamento liquidado!');
                }
            } catch (e) {
                console.log('e: ', e);
                mostrarToast('erro', mensagens['erro']);
            }

        }} /> : <></>,
        (propsAcao) => propsAcao.row.status_id !== 2 ? <AdicionarCheque action={async () => {

            try {
                const status = await get('/financeiro/statuslancamento', { id: propsAcao.row.id })

                if (status && status.status_id == '2') {
                    mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado!')
                    return
                }

                const usuarioId = getUsuario().id;

                const permissao = await get('/controleAcesso/permissao', { usuario_id: usuarioId, modulo_id: 116, acao_id: ACOES.ADICIONAR_CHEQUE });

                if (permissao.length) {
                    setModalAdicionarCheque(true);
                    setIdLancamento(propsAcao.row.id);
                    setDetalhesLancamento(propsAcao.row)
                } else {
                    mostrarToast('erro', mensagens['permissao'])
                }

            } catch (e) {
                console.log('e: ', e);
                mostrarToast('erro', mensagens['erro']);
            }

        }} /> : <></>,

        (propsAcao) => propsAcao.row.status_id !== 2 ? <AdicionarGuia action={async () => {

            try {
                const status = await get('/financeiro/statuslancamento', { id: propsAcao.row.id })

                if (status && status.status_id == '2') {
                    mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado!')
                    return
                }

                const usuarioId = getUsuario().id;

                const permissao = await get('/controleAcesso/permissao', { usuario_id: usuarioId, modulo_id: 116, acao_id: ACOES.ADICIONAR_GUIA });

                if (permissao.length) {

                    const status_movimento = await get('/financeiro/validarAdicionarGuia', { id: propsAcao.row.id });

                    if (((status_movimento === 1 || status_movimento === 0)) || status_movimento == undefined) {
                        setModalAdicionarGuia(true);
                        setIdLancamento(propsAcao.row.id);
                        setDetalhesLancamento(propsAcao.row)
                    } else {
                        mostrarToast('erro', 'Operação Bloqueada! Movimento Fechado ou Conferido.')
                    }
                } else {
                    mostrarToast('erro', mensagens['permissao'])
                }
            } catch (e) {
                console.log('e: ', e);
                mostrarToast('erro', mensagens['erro']);
            }
        }
        } /> : <></>,
        (propsAcao) => propsAcao.row.status_id !== 2 ? <Detalhes action={async () => {
            const status = await get('/financeiro/statuslancamento', { id: propsAcao.row.id })

            if (status && status.status_id == '2') {
                mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado!')
                return
            }

            setShowModalDetalhes(true)
            obterDetalhes(propsAcao.row)
        }} /> : <></>,
        (propsAcao) => propsAcao.row.status_id !== 2 ?
            <EmitirRecibo action={async () => {
                try {
                    const status = await get('/financeiro/statuslancamento', { id: propsAcao.row.id })

                    if (status && status.status_id == '2') {
                        mostrarToast('erro', 'Operação não permitida! Lançamento Cancelado!')
                        return
                    }

                    const usuarioId = getUsuario().id;
                    const permissao = await get('/controleAcesso/permissao', { usuario_id: usuarioId, modulo_id: 116, acao_id: ACOES.RECIBO });

                    if (permissao.length) {
                        if (propsAcao.row.guia_id)
                            setEmpresaRecibo(propsAcao.row.empresa_guia_object)
                        else
                            setEmpresaRecibo(propsAcao.row.empresa_lancamento_object)

                        setSocioRecibo(propsAcao.row.socio_object)
                        setModalRecibo(true);
                        setIdLancamento(propsAcao.row.id)
                        setDetalhesLancamento(propsAcao.row)
                    } else
                        mostrarToast('erro', mensagens['permissao'])
                } catch (e) {
                    mostrarToast('erro', mensagens['erro']);
                }
            }} /> : <></>
    ]

    return (
        <div>
            <DataGridAlternativa target={target} acoesObj={width <= 1822 ? acoesObj2 : acoesObj} acoes={true} data={data} columns={columns} caminhoBase='/financeiro/lancamento'
                setData={setData} subtitulo={subtitulo} modulo={props.modulo}
                titulo={'Consulta Lançamento Movimento Financeiro'} tituloLista={'Lista Lançamento Movimento Financeiro'} urlCadastro='/financeiro/lancamento/cadastro' icone={'icon-Receipt-3'} caminhoConsultaFiltros='/financeiro/lancamentopaginacao' />
            <ModalLiquidacaoLancamento target={target} showModal={modalLiquidacao} setShowModal={setModalLiquidacao} idLancamento={idLancamento} setIdLancamento={setIdLancamento} detalhesLancamento={detalhesLancamento} />
            <ModalCancelamento target={target} showModal={modalCancelamento} setShowModal={setModalCancelamento} idLancamento={idLancamento} detalhesLancamento={detalhesLancamento} />
            <ModalRemoverLiquidacao target={target} showModal={modalRemoverLiquidacao} setShowModal={setModalRemoverLiquidacao} idLancamento={idLancamento} detalhesLancamento={detalhesLancamento} />
            <ModalMovimento target={target} showModal={modalMovimento} setShowModal={setModalMovimento} idLancamento={idLancamento} detalhesLancamento={detalhesLancamento} />
            <ModalAdicionarCheque target={target} showModal={modalAdicionarCheque} setShowModal={setModalAdicionarCheque} idLancamento={idLancamento} detalhesLancamento={detalhesLancamento} />
            <ModalAdicionarGuia target={target} showModal={modalAdicionarGuia} setShowModal={setModalAdicionarGuia} idLancamento={idLancamento} detalhesLancamento={detalhesLancamento} />
            <AcaoDetalhesLancamento titulo={'Detalhes Lançamento'} data={itemDetalhes} showModal={showModalDetalhes} setShowModal={setShowModalDetalhes} />
            <ModalRecibo target={target} showModal={modalRecibo} setShowModal={setModalRecibo} idLancamento={idLancamento} socioRecibo={socioRecibo} empresaRecibo={empresaRecibo} detalhesLancamento={detalhesLancamento} history={props.history} />
        </div>
    )
}

const ModalLiquidacaoLancamento = ({ target, idLancamento, detalhesLancamento, ...props }) => {

    const refLiquidacao = useRef(null)
    const refMovimentoFInanceiro = useRef(null)

    const [salvando, setSalvando] = useState(false)
    const inputValorLiquidacao = useRef([]);
    const inputValorAcrescimo = useRef([]);
    const inputValorDesconto = useRef([]);
    const inputValorJurosMulta = useRef([]);
    const inputValorImpostoNFE = useRef([]);
    const inputValorGlosa = useRef([]);
    const inputValorOutrosImpostos = useRef([]);
    const [valoresIniciais, setValoresIniciais] = useState({})
    const [valorTotalLiquidacao, setValorTotalLiquidacao] = useState(0)
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {

        if (idLancamento != undefined) {

            Promise.resolve(get(`/financeiro/obterDadosIniciaisLiquidacao`, { id: idLancamento }, null)).then(val => {
                val.atendimento = val.atendimento_value != null ? { label: val.atendimento_label, value: val.atendimento_value } : ''
                val.data_liquidacao = new moment().format('DD/MM/YYYY')
                val.liquidacao = [{
                    modo_liquidacao: undefined, valor: val.valor, valor_antigo: val.valor, acrescimo: 0, desconto: 0, juros_multa: 0, imposto_nfse: 0,
                    glosas: 0, outros_impostos: 0
                }]
                setValoresIniciais(val)
                setValorTotalLiquidacao(val.valor)

            })

        } else {
            setValoresIniciais({
                movimento_financeiro: undefined,
                data_liquidacao: new moment().format('DD/MM/YYYY'),
                liquidacao: [{
                    modo_liquidacao: undefined, valor: 0, acrescimo: 0, desconto: 0, juros_multa: 0, imposto_nfse: 0,
                    glosas: 0, outros_impostos: 0, anotacoes: ''
                }],

            })
            setValorTotalLiquidacao(0)
        }
    }, [idLancamento])


    useEffect(() => {
        if (!props.showModal) {
            props.setIdLancamento(null)
            setShowModalValidacao(false)
        }

    }, [props.showModal])


    const validarSubmit = (values, propsFormik) => {
        forceUpdate()
        setSalvando(true)
        values.valor_total_liquidacao = valorTotalLiquidacao
        validar(`/financeiro/validarliquidacaolancamento`, { id: idLancamento, ...values }, () => Submit(values, propsFormik), (e) => {
            const alertas = e.response.data.alertas;
            setMensagemModal(<><div dangerouslySetInnerHTML={{ __html: alertas.map(x => x.replace(/"/g, '') + '<br/><br/>').join('') }} /></>)
            setShowModalValidacao(true)
            setSalvando(false)
        })
    }

    const Submit = (values, propsFormik) => {

        setSalvando(true)
        postComAcao(ACOES.LIQUIDAR, 116,
            { idLancamento: idLancamento, ...values, controle: idLancamento },
            '/financeiro/liquidacaolancamento',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                props.setShowModal(false)
                propsFormik.resetForm({})
                target.current.atualizar()
                setSalvando(false)
                setValorTotalLiquidacao(0)
            },
            (err) => {
                // mostrarToast('erro', mensagens.erro)
                setSalvando(false)
            })
    }


    let validacaoInicial = Yup.lazy(({ liquidacao }) => {
        let index = liquidacao.length > 0 && liquidacao?.findIndex(x => !x.modo_liquidacao)
        return Yup.object({
            data_liquidacao: Yup.string().nullable().required()
                .test('Data', 'Data inválida!', function (value) {
                    return validaData(this.parent.data_liquidacao)
                }),
            // movimento_financeiro: Yup.object().nullable().required(),
        }).shape({
            liquidacao: Yup.array()
                .of(
                    Yup.object().shape({
                        modo_liquidacao: Yup.object().nullable().when('valor', {
                            is: () => liquidacao && !liquidacao[0].modo_liquidacao || (liquidacao && !liquidacao[index]?.modo_liquidacao && liquidacao[index]?.valor),
                            then: Yup.object().required(),
                            otherwise: Yup.object().nullable()
                        }),
                        valor: Yup.number().nullable().test('Valor Liquidação', 'Campo Obrigatório!', function (value) {
                            const index = this.path.replace(/[^0-9]+/g, '');
                            return !(liquidacao.length - 1 != index && !value)
                        }), // these constraints take precedence
                    })
                )
        })
    })




    const adicionarLinha = (option, propsFormik, arrayHelpers, index) => {
        if (option) {
            propsFormik.setFieldValue(`liquidacao[${index}].modo_liquidacao`, option);
            if ((index + 1) === propsFormik.values.liquidacao.length) {
                arrayHelpers.insert(propsFormik.values.liquidacao.length, { modo_liquidacao: undefined, valor: undefined })
            }
        } else {
            propsFormik.setFieldValue(`liquidacao[${index}].modo_liquidacao`, undefined);
        }

    }

    const adicionarLinhaNovo = (propsFormik, arrayHelpers, index) => {
        if ((index + 1) === propsFormik.values.liquidacao.length)
            arrayHelpers.insert(propsFormik.values.liquidacao.length, {
                modo_liquidacao: undefined, valor: 0, valor: 0, valor_antigo: 0, acrescimo: 0, desconto: 0, juros_multa: 0, imposto_nfse: 0,
                glosas: 0, outros_impostos: 0, anotacoes: ''
            })
    }

    const removerLinha = (arrayHelpers, propsFormik, index) => {

        const arrayLiquidacao = [...propsFormik.values.liquidacao];
        if (arrayLiquidacao[index]?.modo_liquidacao && arrayLiquidacao[index]?.modo_liquidacao.value || !arrayLiquidacao[index]?.modo_liquidacao && !arrayLiquidacao[index]?.modo_liquidacao?.value && !(arrayLiquidacao.length === index + 1)) {
            arrayHelpers.remove(index)
            arrayLiquidacao.splice(index, 1)
        }
        if (propsFormik.values.liquidacao.length === 1) {
            propsFormik.setFieldValue('liquidacao', [{ modo_liquidacao: undefined }])
        }
        if (arrayLiquidacao.length === 1 && arrayLiquidacao[index]?.modo_liquidacao && arrayLiquidacao[index]?.modo_liquidacao.value) {
            arrayHelpers.insert(arrayLiquidacao.length, { modo_liquidacao: undefined })
        }

        if (arrayLiquidacao.length === index + 1 && arrayLiquidacao[index].valor) {
            propsFormik.setFieldValue(`liquidacao[${index}]`, { modo_liquidacao: undefined, valor: undefined })
            arrayLiquidacao[index] = { modo_liquidacao: undefined, valor: undefined }
            // revisar posteriormente
        }

        onChangeValorLiquidacao(arrayLiquidacao)

    }

    const removerLinhaNovo = (arrayHelpers, propsFormik, index) => {

        const arrayLiquidacao = propsFormik.values.liquidacao;
        if (arrayLiquidacao.length === 1) {
            arrayHelpers.remove(index)
            propsFormik.setFieldValue(propsFormik.values.liquidacao[index].valor, 0)
            arrayHelpers.insert(propsFormik.values.liquidacao.length, {
                modo_liquidacao: undefined, valor: 0, valor_antigo: 0, acrescimo: 0, desconto: 0, juros_multa: 0, imposto_nfse: 0,
                glosas: 0, outros_impostos: 0, anotacoes: ''
            })

        }
        else {
            if (arrayLiquidacao.length === 1 && arrayLiquidacao[index].modo_liquidacao && arrayLiquidacao[index].modo_liquidacao.value) {

                propsFormik.setFieldValue(propsFormik.values.liquidacao[index].valor, 0)

                arrayHelpers.insert(propsFormik.values.liquidacao.length, {
                    modo_liquidacao: undefined, valor: 0, valor_antigo: 0, acrescimo: 0, desconto: 0, juros_multa: 0, imposto_nfse: 0,
                    glosas: 0, outros_impostos: 0, anotacoes: ''
                })
            }

            else {
                arrayHelpers.remove(index)
            }
        }

        onChangeValorLiquidacao(arrayLiquidacao)
    }

    const onChangeValorLiquidacao = (arrayRetencao) => {

        const valoresLiquidacao = arrayRetencao
        let valorTotal = valoresLiquidacao?.map(x => parseFloat(x.valor || 0)).reduce((ant, atual) => ant + atual, 0);
        setValorTotalLiquidacao((valorTotal))

    }


    const onChangeValorLiquidacaoNovo = (arrayLiquidacao, index, propsFormik) => {

        let valor_liquidacao = parseFloat(arrayLiquidacao[index].valor_antigo)

        const operacoes = [
            { propriedade: 'acrescimo', operacao: 'soma' },
            { propriedade: 'desconto', operacao: 'subtrai' },
            { propriedade: 'juros_multa', operacao: 'soma' },
            { propriedade: 'imposto_nfse', operacao: 'subtrai' },
            { propriedade: 'glosas', operacao: 'subtrai' },
            { propriedade: 'outros_impostos', operacao: 'subtrai' }
        ];


        for (let i = 0; i < operacoes.length; i++) {
            const { propriedade, operacao } = operacoes[i];

            if (arrayLiquidacao[index][propriedade] !== undefined) {
                if (operacao === 'soma') {
                    valor_liquidacao += parseFloat(arrayLiquidacao[index][propriedade]);
                } else if (operacao === 'subtrai') {
                    valor_liquidacao -= parseFloat(arrayLiquidacao[index][propriedade]);
                }
            }
        }

        arrayLiquidacao[index].valor = valor_liquidacao

        propsFormik.setFieldValue(`liquidacao[${index}].valor`, valor_liquidacao)

        let valorTotal = arrayLiquidacao?.map(x => parseFloat(x.valor || 0)).reduce((ant, atual) => ant + atual, 0);
        setValorTotalLiquidacao((valorTotal))

    }

    return (
        <>
            <ModalCadastro titulo={props.titulo || 'Informações Lançamento'} show={props.showModal} setShowModal={props.setShowModal} zerarTotal={setValorTotalLiquidacao} >
                <Formik
                    initialValues={valoresIniciais}
                    validationSchema={validacaoInicial}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
                    {propsFormik => (
                        <div>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <DetalhesLancamnetoFinanceiro detalhesLancamento={detalhesLancamento} />
                                    <div >
                                        <div className='' style={{ padding: 10 }}>
                                            <div ref={refMovimentoFInanceiro}>

                                                <InputMovimentoFinanceiroLiquidacao label='Movimento Financeiro' tamanho={'col-lg-12 p-0'} name='movimento_financeiro' placeholder='Movimento Financeiro' propsFormik={propsFormik}
                                                    classeTooltip={'tooltip-input-modal'} autoFocus
                                                    onChange={option => {
                                                        propsFormik.setFieldValue('movimento_financeiro', option)
                                                        propsFormik.setFieldValue('data_liquidacao', option?.data_formatada || null)
                                                        forceUpdate()
                                                    }}
                                                    onFocus={() => {
                                                        propsFormik.setFieldError('movimento_financeiro', undefined)
                                                    }}
                                                />
                                                {propsFormik.errors.movimento_financeiro && !Array.isArray(propsFormik.errors.movimento_financeiro) ?
                                                    <Overlay target={refMovimentoFInanceiro.current} show={true} placement="bottom">
                                                        {props2 => {
                                                            return (
                                                                <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                                                    <div className="error">
                                                                        <FiAlertTriangle className="icone" /> <span>{propsFormik.errors.movimento_financeiro}</span></div>
                                                                </Tooltip>)
                                                        }}
                                                    </Overlay> : <></>}
                                            </div>

                                            <InputDatePicker tamanho={'col-lg-12 p-0'} label={"Data"} name={`data_liquidacao`} type="text" obrigatorio={true}
                                                setarValor={value => {
                                                    propsFormik.setFieldValue(`data_liquidacao`, value)
                                                    // checarLiquidado(value)
                                                }} classeTooltip={'tooltip-input-modal'} mostrarFeriado={true} placeholder={'Data'} />

                                            <InputAtendimentoFinanceiro label='Atendimento' name='atendimento' placeholder='Atendimento' tamanho={'col-lg-12 p-0'}
                                                classeTooltip={props.modal && 'tooltip-input-modal'} propsFormik={propsFormik} onChange={option => {

                                                    const valor_atendimento = parseFloat(option?.valor_total_formatado.replace('R$', '').replace('.', '').replace(',', '.').trim()) || 0
                                                    propsFormik.setFieldValue('liquidacao[0].valor', valor_atendimento)
                                                    forceUpdate()

                                                }} />
                                        </div>
                                    </div>
                                    <div ref={refLiquidacao}>
                                        <div className="modal-header">
                                            <h5 className='modal-title'>Liquidação</h5>
                                        </div>
                                        <FieldArray name="liquidacao" className='display-flex ' classeTooltip={'tooltip-input-modal'} >
                                            {(arrayHelpers => {
                                                return (
                                                    <>
                                                        {
                                                            propsFormik.values.liquidacao && propsFormik.values.liquidacao.length > 0 ?
                                                                propsFormik.values.liquidacao.map((item, index) => (

                                                                    <div className='modal-body-cadastro row mr-0 ml-0 separator-liquidacao'  >
                                                                        <div className='row'>

                                                                            <InputCurrency
                                                                                className='valor-indicador-azul'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorAcrescimo.current[index] = valor
                                                                                }}
                                                                                label='Acrescimo R$'
                                                                                name={`liquidacao[${index}].acrescimo`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorAcrescimo.current[index] || propsFormik.values.liquidacao[index].acrescimo_formatado || propsFormik.values.liquidacao[index].acrescimo}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorAcrescimo.current[index] || inputValorAcrescimo.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao[index].acrescimo = parseFloat(inputValorAcrescimo.current[index] && inputValorAcrescimo.current[index].toString().trim());
                                                                                        propsFormik.values.liquidacao[index].acrescimo_formatado = formatarMoeda(inputValorAcrescimo.current && inputValorAcrescimo.current[index].toString());

                                                                                        onChangeValorLiquidacaoNovo(propsFormik.values.liquidacao, index, propsFormik)
                                                                                    }
                                                                                    inputValorAcrescimo.current[index] = null;
                                                                                }

                                                                                } classDiv='col-6' />

                                                                            <InputCurrency
                                                                                className='valor-indicador-errado'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorDesconto.current[index] = valor
                                                                                }}
                                                                                label='Desconto R$'
                                                                                name={`liquidacao[${index}].desconto`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorDesconto.current[index] || propsFormik.values.liquidacao[index].valor_desconto_formatado || propsFormik.values.liquidacao[index].desconto}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorDesconto.current[index] || inputValorDesconto.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao[index].desconto = inputValorDesconto.current[index] && inputValorDesconto.current[index].toString().trim();
                                                                                        propsFormik.values.liquidacao[index].valor_desconto_formatado = formatarMoeda(inputValorDesconto.current && inputValorDesconto.current[index].toString());

                                                                                        onChangeValorLiquidacaoNovo(propsFormik.values.liquidacao, index, propsFormik)
                                                                                    }
                                                                                    inputValorDesconto.current[index] = null;
                                                                                }

                                                                                } classDiv='col-6' />
                                                                        </div>
                                                                        <div className='row'>



                                                                            <InputCurrency
                                                                                className='valor-indicador-azul'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorJurosMulta.current[index] = valor
                                                                                }}
                                                                                label='Juros/Multa R$'
                                                                                name={`liquidacao[${index}].juros_multa`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorJurosMulta.current[index] || propsFormik.values.liquidacao[index].valor_juros_multa_formatado || propsFormik.values.liquidacao[index].juros_multa}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorJurosMulta.current[index] || inputValorJurosMulta.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao[index].juros_multa = inputValorJurosMulta.current[index] && inputValorJurosMulta.current[index].toString().trim();
                                                                                        propsFormik.values.liquidacao[index].valor_juros_multa_formatado = formatarMoeda(inputValorJurosMulta.current && inputValorJurosMulta.current[index].toString());

                                                                                        onChangeValorLiquidacaoNovo(propsFormik.values.liquidacao, index, propsFormik)
                                                                                    }
                                                                                    inputValorJurosMulta.current[index] = null;
                                                                                }

                                                                                } classDiv='col-6 col-xl-3col-lg-3' />

                                                                            <InputCurrency
                                                                                className='valor-indicador-errado'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorImpostoNFE.current[index] = valor
                                                                                }}
                                                                                label='Imposto NFSE R$'
                                                                                name={`liquidacao[${index}].imposto_nfse`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorImpostoNFE.current[index] || propsFormik.values.liquidacao[index].valor_imposto_nfse_formatado || propsFormik.values.liquidacao[index].imposto_nfse}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorImpostoNFE.current[index] || inputValorImpostoNFE.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao[index].imposto_nfse = inputValorImpostoNFE.current[index] && inputValorImpostoNFE.current[index].toString().trim();
                                                                                        propsFormik.values.liquidacao[index].valor_imposto_nfse_formatado = formatarMoeda(inputValorImpostoNFE.current && inputValorImpostoNFE.current[index].toString());

                                                                                        onChangeValorLiquidacaoNovo(propsFormik.values.liquidacao, index, propsFormik)
                                                                                    }
                                                                                    inputValorImpostoNFE.current[index] = null;
                                                                                }

                                                                                } classDiv='col-6 col-xl-3col-lg-3' />

                                                                        </div>
                                                                        <div className='row'>
                                                                            <InputCurrency
                                                                                className='valor-indicador-errado'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorGlosa.current[index] = valor
                                                                                }}
                                                                                label='Glosa R$'
                                                                                name={`liquidacao[${index}].glosas`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorGlosa.current[index] || propsFormik.values.liquidacao[index].valor_glosa_formatado || propsFormik.values.liquidacao[index].glosas}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorGlosa.current[index] || inputValorGlosa.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao[index].glosas = inputValorGlosa.current[index] && inputValorGlosa.current[index].toString().trim() || 0;
                                                                                        propsFormik.values.liquidacao[index].valor_glosa_formatado = formatarMoeda(inputValorGlosa.current && inputValorGlosa.current[index].toString());

                                                                                        onChangeValorLiquidacaoNovo(propsFormik.values.liquidacao, index, propsFormik)
                                                                                    }
                                                                                    inputValorGlosa.current[index] = null;
                                                                                }

                                                                                } classDiv='col-6 col-xl-3col-lg-3' />

                                                                            <InputCurrency
                                                                                className='valor-indicador-errado'
                                                                                onUpdate={val => {
                                                                                    let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                    inputValorOutrosImpostos.current[index] = valor
                                                                                }}
                                                                                label='Outros R$'
                                                                                name={`liquidacao[${index}].outros_impostos`}
                                                                                classeTooltip={'tooltip-input-modal'}
                                                                                propsFormik={propsFormik}
                                                                                max={100000000}
                                                                                value={inputValorOutrosImpostos.current[index] || propsFormik.values.liquidacao[index].valor_outros_impostos_formatado || propsFormik.values.liquidacao[index].outros_impostos}
                                                                                onBlur={(e) => {
                                                                                    if (inputValorOutrosImpostos.current[index] || inputValorOutrosImpostos.current[index] === 0) {
                                                                                        propsFormik.values.liquidacao[index].outros_impostos = inputValorOutrosImpostos.current[index] && inputValorOutrosImpostos.current[index].toString().trim();
                                                                                        propsFormik.values.liquidacao[index].valor_outros_impostos_formatado = formatarMoeda(inputValorOutrosImpostos.current && inputValorOutrosImpostos.current[index].toString());

                                                                                        onChangeValorLiquidacaoNovo(propsFormik.values.liquidacao, index, propsFormik)
                                                                                    }
                                                                                    inputValorOutrosImpostos.current[index] = null;
                                                                                }


                                                                                } classDiv='col-6 col-xl-3col-lg-3' />
                                                                        </div>

                                                                        <InputModoLiquidacao label={'Modo Liquidação'} obrigatorio={true} placeholder='Modo Liquidação' classeTooltip={'tooltip-input-modal'}
                                                                            name={`liquidacao[${index}].modo_liquidacao`} propsFormik={propsFormik} tamanho={'col-lg-12 p-0 mr-3'} onChange={option => {
                                                                                // adicionarLinha(option, propsFormik, arrayHelpers, index)
                                                                            }}
                                                                            menuPlacement='top'
                                                                            isClearable={false}
                                                                        />


                                                                        <InputCurrency
                                                                            classDiv={'width-50'}
                                                                            obrigatorio={true}
                                                                            onUpdate={val => {
                                                                                let valor = val.replace('R$', '').replace(/\./g, '').replace(',', '.')
                                                                                inputValorLiquidacao.current[index] = valor
                                                                            }}
                                                                            label='Valor R$'
                                                                            name={`liquidacao[${index}].valor`}
                                                                            classeTooltip={'tooltip-input-modal'}
                                                                            propsFormik={propsFormik}
                                                                            max={100000000}
                                                                            value={inputValorLiquidacao.current[index] || propsFormik.values.liquidacao[index].valor_total_formatado || propsFormik.values.liquidacao[index].valor}
                                                                            onBlur={(e) => {
                                                                                if (inputValorLiquidacao.current[index] || inputValorLiquidacao.current[index] === 0) {
                                                                                    propsFormik.values.liquidacao[index].valor = inputValorLiquidacao.current[index] && inputValorLiquidacao.current[index].toString().trim();
                                                                                    propsFormik.values.liquidacao[index].valor_liquidacao_formatado = formatarMoeda(inputValorLiquidacao.current && inputValorLiquidacao.current[index].toString());

                                                                                    // onChangeValorLiquidacao(propsFormik.values.liquidacao)
                                                                                    propsFormik.values.liquidacao[index].valor_antigo = inputValorLiquidacao.current[index] && inputValorLiquidacao.current[index].toString().trim();
                                                                                }
                                                                                inputValorLiquidacao.current[index] = null;
                                                                            }

                                                                            } />
                                                                        <React.Fragment >
                                                                            {propsFormik.values.liquidacao.length === index + 1 ?
                                                                                <BtnAcao type={'button'} className='botao-liquidacao' icone="icon-Add" texto="Adicionar" action={() => adicionarLinhaNovo(propsFormik, arrayHelpers, index)} /> : <>
                                                                                </>}
                                                                            <BtnAcao type={'button'} className='botao-liquidacao' icone="icon-New-Trash" texto="Remover" action={() => removerLinhaNovo(arrayHelpers, propsFormik, index)} />
                                                                            {propsFormik.values.liquidacao[index].modo_liquidacao && parseFloat(convertValueToFloat(propsFormik.values.liquidacao[index].valor)) ?
                                                                                <BtnAcao type={'button'} className='botao-liquidacao' icone={propsFormik?.values?.liquidacao[index]?.show_anotacoes ? 'icon-Yes' : "icon-Note"} texto={`${propsFormik?.values?.liquidacao[index]?.anotacoes ? 'Alterar' : 'Adicionar'} Anotações`} action={() => propsFormik.setFieldValue(`liquidacao[${index}].show_anotacoes`, !propsFormik?.values?.liquidacao[index]?.show_anotacoes)} />
                                                                                : <></>}
                                                                        </React.Fragment>

                                                                        <>
                                                                            {

                                                                                ((propsFormik?.values?.liquidacao[index]?.anotacoes || propsFormik?.values?.liquidacao[index]?.show_anotacoes) && propsFormik.values.liquidacao[index].modo_liquidacao && parseFloat(convertValueToFloat(propsFormik.values.liquidacao[index].valor)) !== 0)
                                                                                && <div className='form-group col-lg-12 p-0 mr-3'>
                                                                                    {propsFormik?.values?.liquidacao[index]?.show_anotacoes ?
                                                                                        // <TextArea performance={true}  type='text' placeholder='Anotações' tamanho={'col-12'} classeTooltip={'tooltip-input-modal'} rows={2} />
                                                                                        <TextArea label={'Anotações'} name={`liquidacao[${index}].anotacoes`}
                                                                                            value={propsFormik?.values?.liquidacao[index]?.anotacoes}
                                                                                            type='text' placeholder='Anotações' tamanho={!props.modal && 'col-lg-12 p-0'} rows={2}
                                                                                            classeTooltip={props.modal && 'tooltip-input-modal'} />
                                                                                        : (propsFormik.values.liquidacao[index].modo_liquidacao && parseFloat(convertValueToFloat(propsFormik.values.liquidacao[index].valor))) ?
                                                                                            <div style={{ width: '100%' }}>
                                                                                                <div className='form-group display-flex col-lg-12 p-0 mr-3 anotacoes-liquidacao'>
                                                                                                    <span className='icon-Note'></span>
                                                                                                    <h6>{propsFormik?.values?.liquidacao[index]?.anotacoes}</h6>
                                                                                                </div>
                                                                                            </div> : <></>}
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    </div>




                                                                )) : <></>



                                                        }
                                                        <div className='valor_total_liquidacao'>
                                                            <span><b style={{ color: 'black' }}>Valor Total: {formatarMoeda(valorTotalLiquidacao)}</b></span>
                                                        </div>
                                                    </>
                                                )
                                            }

                                            )}
                                        </FieldArray>

                                    </div>

                                </div>
                                <div className='separator mt-40 mb-2'></div>


                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>

                            </Form>
                            <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => { Submit(propsFormik.values, propsFormik); setShowModalValidacao(false) }} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                                esconderFechar mensagem={<>{<>{'Validações da Liquidação:'} <br /><br /></>} {mensagemModal}<br />
                                    {'Deseja Continuar?'}</>}
                                textoOpcao1={'Cancelar'}
                                classeOpcao1={'btn-sm btn-primary-modal simples'}
                                textoOpcao2={'Continuar'} />
                        </div>
                    )}
                </Formik>
            </ModalCadastro >

        </>
    )
}

const ModalCancelamento = ({ idLancamento, target, detalhesLancamento, ...props }) => {

    const [salvando, setSalvando] = useState(false)
    const [validacao] = useState(Yup.object({
        anotacoes: Yup.object().nullable()
    }))

    const Submit = (values, propsFormik) => {

        setSalvando(true)
        putComAcao(ACOES.CANCELAR_LANCAMENTO, 116,
            { idLancamento: idLancamento, controle: idLancamento, ...values },
            '/financeiro/cancelarlancamento',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                props.setShowModal(false)
                propsFormik.resetForm({})
                target.current.atualizar()
                setSalvando(false)
            },
            (err) => {
                console.log('err: ', err);
                setSalvando(false)
            })
    }


    return (
        <>
            <ModalCadastro titulo={props.titulo || 'Cancelamento Lançamento'} show={props.showModal} setShowModal={props.setShowModal}>
                <Formik
                    initialValues={{ anotacoes: '' }}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                    {propsFormik => (
                        <div>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <DetalhesLancamnetoFinanceiro detalhesLancamento={detalhesLancamento} />
                                    <div className={'form-group col-12'}>
                                        <TextArea label={<b>Anotações</b>} name='anotacoes' type='text' placeholder='Anotações' tamanho={!props.modal && 'col-lg-12 p-0'} rows={3}
                                            classeTooltip={props.modal && 'tooltip-input-modal'} autoFocus />

                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>

                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    );
}

const ModalRemoverLiquidacao = ({ idLancamento, target, detalhesLancamento, ...props }) => {

    const [salvando, setSalvando] = useState(false)
    const [validacao] = useState(Yup.object({
        anotacoes: Yup.object().nullable()
    }))

    const Submit = (values, propsFormik) => {

        setSalvando(true)
        putComAcao(
            ACOES.REMOVER_LIQUIDACAO,
            MODULO["/financeiro/lancamento"],
            { id: idLancamento, ...values },
            '/financeiro/removerliquidacao',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                target.current.atualizar();
                props.setShowModal(false)
                setSalvando(false)
            },
            (erro) => {
                setSalvando(false)
            })
    }


    return (
        <>
            <ModalCadastro titulo={props.titulo || 'Remover Liquidação'} show={props.showModal} setShowModal={props.setShowModal}>
                <Formik
                    initialValues={{ anotacoes: '' }}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                    {propsFormik => (
                        <div>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <DetalhesLancamnetoFinanceiro detalhesLancamento={detalhesLancamento} />
                                    <div className={'form-group col-12'}>
                                        <TextArea label={<b>Anotações</b>} name='anotacoes' type='text' placeholder='Anotações' tamanho={!props.modal && 'col-lg-12 p-0'} rows={3}
                                            classeTooltip={props.modal && 'tooltip-input-modal'} autoFocus />

                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>

                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    );
}

const ModalMovimento = ({ idLancamento, target, detalhesLancamento, ...props }) => {

    const [salvando, setSalvando] = useState(false)
    const [validacao] = useState(Yup.object({
        movimento_financeiro: Yup.object().nullable().required(),
    }))

    const refMovimento = useRef(null)

    const Submit = (values, propsFormik) => {

        setSalvando(true)
        putComAcao(
            ACOES.ADICIONAR_MOVIMENTO,
            MODULO["/financeiro/lancamento"],
            { id: idLancamento, ...values },
            '/financeiro/adicionarMovimento',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                target.current.atualizar();
                props.setShowModal(false)
                setSalvando(false)
            },
            (erro) => {
                setSalvando(false)
            })
    }

    return (
        <>
            <ModalCadastro titulo={props.titulo || 'Adicionar Movimento'} show={props.showModal} setShowModal={props.setShowModal}>
                <Formik
                    initialValues={{ anotacoes: '' }}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                    {propsFormik => (
                        <div>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <DetalhesLancamnetoFinanceiro detalhesLancamento={detalhesLancamento} />
                                    <div className={'form-group col-12'}>
                                        <div ref={refMovimento}>
                                            <InputMovimentoFinanceiroLiquidacao label='Movimento Financeiro' tamanho={'col-lg-12 p-0'} name='movimento_financeiro' placeholder='Movimento Financeiro' propsFormik={propsFormik}
                                                classeTooltip={'tooltip-input-modal'} obrigatorio autoFocus
                                                onFocus={() => {
                                                    propsFormik.setFieldError('movimento_financeiro', undefined)
                                                }}
                                            />
                                            {propsFormik.errors.movimento_financeiro && !Array.isArray(propsFormik.errors.movimento_financeiro) ?
                                                <Overlay target={refMovimento.current} show={true} placement="bottom">
                                                    {props2 => {
                                                        return (
                                                            <Tooltip id={Math.random()} className={"error-tooltip tooltip-input-modal"} {...props2}>
                                                                <div className="error">
                                                                    <FiAlertTriangle className="icone" /> <span>{propsFormik.errors.movimento_financeiro}</span></div>
                                                            </Tooltip>)
                                                    }}
                                                </Overlay> : <></>}
                                        </div>
                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>

                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    );
}

const DetalhesLancamnetoFinanceiro = ({ detalhesLancamento, ...props }) => {

    let dados = {}

    dados.dados_lancamento = [

        { titulo: 'Código', descricao: detalhesLancamento?.id },
        { titulo: 'Data', descricao: detalhesLancamento?.data_prevista },
        { titulo: 'Cliente', descricao: detalhesLancamento?.entidade },
        { titulo: 'Sócio(s)', descricao: distinctArr(detalhesLancamento?.socio).join(', ') },
        { titulo: 'Valor R$', descricao: detalhesLancamento?.valor_total_formatado },
    ]


    return (
        <div className='col-12 '>
            <div className='detalhes '>
                <div className='item-detalhes display-flex col-12 row'>
                    {dados?.dados_lancamento ?

                        dados?.dados_lancamento.map((item, idx) => {
                            return (
                                <>
                                    {/* {idx === 0 ? <></> : <hr />} */}

                                    <div className={item.titulo.includes('Cliente') ? 'col-12' : 'col-6'}>
                                        <h6><b>{item.titulo}</b></h6>
                                        {item?.titulo?.includes('Valor R$') || item.titulo.includes('Data') ?
                                            <h6 >
                                                <b className='valor-indicador-certo'>{item.descricao}</b>
                                            </h6>
                                            :
                                            <h6 >{item.descricao}</h6>}
                                        {item.html}
                                    </div>

                                </>
                            )
                        })
                        : <></>
                    }
                </div>

            </div>
            <div class="separator mt-20 mb-20"></div>
        </div>
    )

}

const ModalAdicionarCheque = ({ idLancamento, target, detalhesLancamento, ...props }) => {

    const [salvando, setSalvando] = useState(false)
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    const [validacao] = useState(Yup.object({
        cheque: Yup.object().nullable()
    }))

    const refMovimento = useRef(null)

    const validarSubmit = (values, propsFormik) => {
        setSalvando(true)

        validar(`/financeiro/validaradicionarcheque`, values, () => Submit(values, propsFormik), (e) => {
            const alertas = e.response.data.alertas;
            setMensagemModal(<><div dangerouslySetInnerHTML={{ __html: alertas.map(x => x.replace(/"/g, '') + '<br/><br/>').join('') }} /></>)
            setShowModalValidacao(true)
            setSalvando(false)
        })
    }

    const Submit = (values, propsFormik) => {

        setSalvando(true)

        values.id_lancamento = idLancamento

        postComAcao(ACOES.ADICIONAR_CHEQUE, 116, values, '/financeiro/adicionarChequeLancamento',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                target.current.atualizar();
                props.setShowModal(false)
                setSalvando(false)
            },
            (erro) => {
                setSalvando(false)
                mostrarToast('erro', mensagens.erro)
            })
    }

    return (
        <>
            <ModalCadastro titulo={props.titulo || 'Adicionar Cheque'} show={props.showModal} setShowModal={props.setShowModal}>
                <Formik
                    initialValues={{}}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
                    {propsFormik => (
                        <div>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <DetalhesLancamnetoFinanceiro detalhesLancamento={detalhesLancamento} />
                                    <div className={'form-group col-12'}>
                                        <div ref={refMovimento}>
                                            <InputChequeDetalhado label='Cheque' tamanho={'col-lg-12 p-0'} name='cheque' placeholder='Cheque' propsFormik={propsFormik}
                                                classeTooltip={'tooltip-input-modal'} obrigatorio autoFocus multiplo
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>

                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>
                            </Form>
                            <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => { Submit(propsFormik.values, propsFormik); setShowModalValidacao(false) }} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                                esconderFechar mensagem={<>{<>{'Validações da Liquidação:'} <br /><br /></>} {mensagemModal}<br />
                                    {'Deseja Continuar?'}</>}
                                textoOpcao1={'Cancelar'}
                                classeOpcao1={'btn-sm btn-primary-modal simples'}
                                textoOpcao2={'Continuar'} />
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    );
}

const ModalAdicionarGuia = ({ idLancamento, target, detalhesLancamento, ...props }) => {

    const [salvando, setSalvando] = useState(false)
    const [showModalValidacao, setShowModalValidacao] = useState(false)
    const [mensagemModal, setMensagemModal] = useState('')
    const [validacao] = useState(Yup.object({
        atendimento: Yup.object().nullable().required(),
    }))

    const refMovimento = useRef(null)

    const validarSubmit = (values, propsFormik) => {

        setSalvando(true)
        values.valor_liquidado_lancamento = detalhesLancamento.valor_total_formatado
        validar(`/financeiro/validarAdicioanrGuiaLancamento`, { id: idLancamento, ...values }, () => Submit(values, propsFormik), (e) => {
            const alertas = e.response.data.alertas;
            setMensagemModal(<><div dangerouslySetInnerHTML={{ __html: alertas.map(x => x.replace(/"/g, '') + '<br/><br/>').join('') }} /></>)
            setShowModalValidacao(true)
            setSalvando(false)
        })
    }

    const Submit = (values, propsFormik) => {

        setSalvando(true)
        postComAcao(
            ACOES.ADICIONAR_GUIA,
            MODULO["/financeiro/lancamento"],
            { id_lancamento: idLancamento, ...values },
            '/financeiro/adicionarGuia',
            () => {
                mostrarToast('sucesso', mensagens.sucesso)
                target.current.atualizar();
                props.setShowModal(false)
                setSalvando(false)
            },
            (erro) => {
                console.log('erro: ', erro);
                setSalvando(false)
            })
    }

    return (
        <>
            <ModalCadastro titulo={props.titulo || 'Adicionar Atendimento(Guia)'} show={props.showModal} setShowModal={props.setShowModal}>
                <Formik
                    initialValues={{ anotacoes: '' }}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => validarSubmit(values, propsFormik)}>
                    {propsFormik => (
                        <div>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <DetalhesLancamnetoFinanceiro detalhesLancamento={detalhesLancamento} />
                                    <div className={'form-group col-12'}>
                                        <div ref={refMovimento}>
                                            <InputAtendimentoFinanceiro label='Atendimento(Guia)' tamanho={'col-lg-12 p-0'} name='atendimento' placeholder='Atendimento' propsFormik={propsFormik}
                                                classeTooltip={'tooltip-input-modal'} obrigatorio autoFocus
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='separator mt-40 mb-2'></div>

                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>
                            </Form>
                            <ModalDuasOpcoes showModal={showModalValidacao} setShowModal={setShowModalValidacao} funcaoOpcao2={() => { Submit(propsFormik.values, propsFormik); setShowModalValidacao(false) }} funcaoOpcao1={() => { setSalvando(false); setShowModalValidacao(false) }}
                                esconderFechar mensagem={<>{<>{'Validações Adicionar Guia:'} <br /><br /></>} {mensagemModal}<br />
                                    {'Deseja Continuar?'}</>}
                                textoOpcao1={'Cancelar'}
                                classeOpcao1={'btn-sm btn-primary-modal simples'}
                                textoOpcao2={'Continuar'} />
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    );
}

const AcaoDetalhesLancamento = (props) => {
    const [itemDetalhes, setItemDetalhes] = useState({});

    useEffect(() => {
        setItemDetalhes(props.data)
    }, [props.data])

    return <ModalPadraoCadastro titulo={props.titulo || 'Detalhes Lançamento'} showModal={props.showModal} setShowModal={props.setShowModal}>
        <div>
            <div className='detalhes'>
                {itemDetalhes.lancamento && itemDetalhes.lancamento.map((item, idx) => {
                    return (
                        <>
                            {idx === 0 ? <></> : <hr />}
                            <div className='item-detalhes'>
                                <h6><b>{item.titulo}</b></h6>
                                <h6>{item.descricao}</h6>
                                {item.html}
                            </div></>
                    )
                })}
            </div>

            <div>
                {itemDetalhes?.guia && itemDetalhes.guia.length > 0 ?
                    <>
                        <div className="modal-header">
                            <h5 className="modal-title"><span>Guia</span></h5>
                        </div>
                        <div>
                            <ul className='lista-modal'>
                                {
                                    itemDetalhes?.guia && itemDetalhes?.guia?.map(item => <li className="mb-1">
                                        <div className='item-detalhes row-space-between'>
                                            <h6><b>Código</b></h6>
                                            <h6><b>Data Registro</b></h6>
                                        </div>
                                        <div className='item-detalhes row-space-between'>
                                            <h6>{item.id}</h6>
                                            <h6>{item.data_registro}</h6>
                                        </div>
                                        <hr />
                                        <div className='item-detalhes'>
                                            <h6><b>Paciente</b></h6>
                                        </div>
                                        <div className='item-detalhes'>
                                            <h6>{item.nome_beneficiario || '---'}</h6>
                                        </div>
                                        <hr />
                                        <div className='item-detalhes'>
                                            <h6><b>Profissional(is) Executante(s) Cooperativa (CONVÊNIO)</b></h6>
                                        </div>
                                        <div className='item-detalhes'>
                                            <h6>{item?.socio_externo || '---'}</h6>
                                        </div>
                                        <hr />
                                        <div className='item-detalhes'>
                                            <h6><b>Profissional(is) Executante(s) EQUIPE</b></h6>
                                        </div>
                                        <div className='item-detalhes'>
                                            <h6>{item?.socio_interno || '---'}</h6>
                                        </div>
                                        <hr />
                                        <div className='item-detalhes row-space-between'>
                                            <h6><b>Data Procedimento</b></h6>
                                            <h6><b>Valor R$</b></h6>
                                        </div>
                                        <div className='item-detalhes row-space-between'>
                                            <h6>{item.data_procedimento || '---'}</h6>
                                            <h6>{item.valor_total || '---'}</h6>
                                        </div>
                                        <hr />

                                    </li>)
                                }
                            </ul>
                        </div>
                    </>
                    : <></>}
            </div>

            <div>
                {
                    itemDetalhes?.movimento && itemDetalhes.movimento[0].descricao != null ?

                        <>

                            <div className="modal-header">
                                <h5 className="modal-title"><span>Movimento Financeiro</span></h5>
                            </div>

                            <div className='detalhes'>
                                <ul className='lista-modal'>
                                    {

                                        itemDetalhes.movimento && itemDetalhes.movimento.map((item, idx) => {
                                            return (
                                                <>
                                                    {idx === 0 ? <></> : <hr />}
                                                    <div className='item-detalhes'>
                                                        <h6><b>{item.titulo}</b></h6>
                                                        <h6>{item.descricao}</h6>
                                                        {item.html}
                                                    </div></>
                                            )
                                        })

                                    }
                                </ul>
                            </div>


                        </>
                        : <></>
                }
            </div>

            {
                itemDetalhes.nota_fiscal && itemDetalhes.nota_fiscal.length > 0 ?
                    <div className="modal-header">
                        <h5 className="modal-title"><span>Nota Fiscal</span></h5>
                    </div>
                    : <></>
            }


            {/* <div >
                {itemDetalhes.nota_fiscal && itemDetalhes.nota_fiscal.map((item, idx) => {
                    return (
                        <div className='detalhes' style={{ marginBottom: 15 }}>
                            {
                                item.map((innerItem, innerIdx) => {
                                    return (
                                        <>
                                            {innerIdx === 0 ? <></> : <hr />}
                                            <div >
                                                <ul className='lista-modal'>
                                                    <h6><b>{innerItem?.titulo}</b></h6>
                                                    <h6>{innerItem?.descricao}</h6>
                                                    {innerItem.html}
                                                </ul>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </div>)
                })}

            </div> */}
            <div>
                <ul className='lista-modal'>
                    {
                        itemDetalhes && itemDetalhes.nota_fiscal && itemDetalhes.nota_fiscal.map(item =>
                            <li className="mb-1">
                                <div className='col-hora row-space-between'>

                                    <span className='span-hora'>{item.data_registro}</span>
                                </div>
                                <div className='item-detalhes mt-0  pl-1' style={{ flex: 1 }}>
                                    <h6><b>Código</b></h6>
                                    <h6 className='mb-1'>{item.id}</h6>
                                </div>
                                <div className='item-detalhes mt-0  pl-1' style={{ flex: 1 }}>
                                    <h6><b>Número NFS-e </b></h6>
                                    <h6 className='mb-1'>{item.numero}</h6>
                                </div>
                                <div className='item-detalhes mt-0  pl-1' style={{ flex: 1 }}>
                                    <h6><b>Código Verificação</b></h6>
                                    <h6 className='mb-1'>{item.codigo_verificacao}</h6>
                                </div>
                                <div className='item-detalhes mt-0  pl-1' style={{ flex: 1 }}>
                                    <h6><b>Status Nota</b></h6>
                                    <h6 style={{ background: item.cor_status?.cor_id, color: 'white', display: 'inline-block', padding: '4px 8px' }} className='mb-1 guia-status'>
                                        {item.cor_status.descricao}
                                    </h6>
                                </div>
                                <div className='item-detalhes mt-0  pl-1' style={{ flex: 1 }}>
                                    <h6><b>Status Envio</b></h6>
                                    <h6 style={{ background: item.cor_status_envio?.cor_id, color: 'white', display: 'inline-block', padding: '4px 8px' }} className='mb-1 guia-status'>
                                        {item.cor_status_envio.descricao}
                                    </h6>
                                </div>
                                <div className='item-detalhes mt-0  pl-1' style={{ flex: 1 }}>
                                    <h6><b>Tomador/Cliente</b></h6>
                                    <h6 className='mb-1'>{item.cliente?.nome_cliente}</h6>
                                </div>
                                <div className='item-detalhes mt-0  pl-1' style={{ flex: 1 }}>
                                    <h6><b>Prestador/Empresa</b></h6>
                                    <h6 className='mb-1'>{item.empresa.label}</h6>
                                </div>

                                <div className='mt-2 row-space-between mb-2' style={{ flexDirection: 'row' }}>
                                    <div className='item-detalhes mt-0  pl-1' style={{ flex: 1 }}>
                                        <h6><b>Data Emissão</b></h6>
                                        <h6 className='mb-1'>{item.data_emissao}</h6>
                                    </div>
                                    <div className='item-detalhes mt-0  pl-1' style={{ flex: 1 }}>
                                        <h6><b>Valor (R$)</b></h6>
                                        <h6 className='mb-1'>{item.valor_nota_formatado}</h6>
                                    </div>
                                    <div className='item-detalhes mt-0  pl-1' style={{ flex: 1 }}>
                                        <h6><b>Valor Trib. (R$)</b></h6>
                                        <h6 className='mb-1'>
                                            {formatarMoeda(item?.tributos?.reduce((acc, x) => acc + (convertValueToFloat(x?.valor_tributo || 0)), 0))}
                                        </h6>
                                    </div>
                                </div>
                            </li>)
                    }
                </ul>
            </div>
        </div>
    </ModalPadraoCadastro >
}

const ModalRecibo = ({ idLancamento, socioRecibo, empresaRecibo, target, detalhesLancamento, ...props }) => {
    const [salvando, setSalvando] = useState(false)
    const [editarData, setEditarData] = useState(false)
    const [editarAssinatura, setEditarAssinatura] = useState(null)

    const [validacao] = useState(Yup.object({
        tipo_assinatura: Yup.object().nullable().required(),
        tipo_data_emissao: Yup.object().nullable().required(),
        data_recibo: Yup.string().nullable()
            .test('Data', 'Data inválida!', function (value) {
                return validaData(this.parent.data_recibo)
            })
            .test('Data Recibo', 'Campo Obrigatório!', function (value) {
                if (this.parent?.tipo_data_emissao?.value !== 4)
                    return true
                if (this.parent.data_recibo !== undefined && this.parent.data_recibo !== null && this.parent.data_recibo !== '')
                    return true

                return false
            }),
        socio: Yup.object().nullable()
            .test('Sócio', 'Campo Obrigatório!', function (value) {
                if (this.parent?.tipo_assinatura?.value !== 1)
                    return true
                if (this.parent.socio !== undefined && this.parent.socio !== null)
                    return true

                return false
            }),
        empresa: Yup.object().nullable()
            .test('Empresa', 'Campo Obrigatório!', function (value) {
                if (this.parent?.tipo_assinatura?.value !== 2)
                    return true
                if (this.parent.empresa !== undefined && this.parent.empresa !== null)
                    return true

                return false
            })
    }))

    const Submit = (values, propsFormik) => {
        propsFormik.resetForm({})
        setEditarAssinatura(false)
        setEditarAssinatura(null)
        props.setShowModal(false)

        values.id_lancamento = idLancamento


        const dataStr = encodeURIComponent(JSON.stringify(values))
        const newPath = `/financeiro/lancamento/recibo/${idLancamento}?data=${dataStr}`
        const url = window.location.origin + newPath
        window.open(url, '_blank')
    }

    useEffect(() => {
        setEditarAssinatura(false)
        setEditarAssinatura(null)
    }, [props.showModal])

    return (
        <>
            <ModalCadastro titulo={props.titulo || 'Recibo'} show={props.showModal} setShowModal={props.setShowModal}>
                <Formik
                    initialValues={{}}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, propsFormik) => Submit(values, propsFormik)}>
                    {propsFormik => (
                        <div>
                            <Form>
                                <div className='modal-body-cadastro'>
                                    <div class="separator mt-20 mb-20"></div>
                                    <InputAssinaturaRecibo tamanho={'col-lg-12 p-0'} label={"Assinatura do Recibo"} name={`tipo_assinatura`} type="text" obrigatorio propsFormik={propsFormik}
                                        classeTooltip={'tooltip-input-modal'} mostrarFeriado={true} placeholder={'Assinatura do Recibo'}
                                        onChange={option => {
                                            propsFormik.setFieldValue('tipo_assinatura', option)

                                            if (option.value === 1) {
                                                propsFormik.setFieldValue('socio', socioRecibo.value ? socioRecibo : null)
                                                propsFormik.setFieldValue('empresa', null)
                                                setEditarAssinatura(option.value)
                                            }
                                            else if (option.value === 2) {
                                                propsFormik.setFieldValue('socio', null)
                                                propsFormik.setFieldValue('empresa', empresaRecibo.value ? empresaRecibo : null)
                                                setEditarAssinatura(option.value)
                                            }
                                            else {
                                                propsFormik.setFieldValue('socio', null)
                                                propsFormik.setFieldValue('empresa', null)
                                                setEditarAssinatura(null)
                                            }
                                        }} />
                                    {
                                        editarAssinatura === 1 ? <InputSocioAtivos tamanho={'col-lg-12 p-0'} label="Sócio" name="socio" type="text" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'}
                                            placeholder={'Sócio'} obrigatorio /> : <></>
                                    }
                                    {
                                        editarAssinatura === 2 ?
                                            <InputEmpresa tamanho={'col-lg-12 p-0'} label="Empresa" name="empresa" type="text" propsFormik={propsFormik} classeTooltip={'tooltip-input-modal'}
                                                placeholder={'Empresa'} obrigatorio />
                                            : <></>
                                    }
                                    <InputDataRecibo tamanho={'col-lg-12 p-0'} label={"Data de Emissão"} name={`tipo_data_emissao`} type="text" obrigatorio propsFormik={propsFormik}
                                        classeTooltip={'tooltip-input-modal'} mostrarFeriado={true} placeholder={'Data de Emissão'} id={idLancamento}
                                        onChange={option => {
                                            propsFormik.setFieldValue('tipo_data_emissao', option)
                                            propsFormik.setFieldValue('data_recibo', null)
                                            if (option.value === 4)
                                                setEditarData(true)
                                            else
                                                setEditarData(false)
                                        }} />
                                    {
                                        editarData ? <InputDatePicker tamanho={'col-lg-12 p-0'} label={"Data Recibo"} name={`data_recibo`} type="text" obrigatorio={true}
                                            setarValor={value => {
                                                propsFormik.setFieldValue(`data_recibo`, value)
                                                propsFormik.setFieldTouched('data_recibo', true)
                                            }} classeTooltip={'tooltip-input-modal'} mostrarFeriado={true} placeholder={'Data Recibo'} /> : <></>
                                    }
                                </div>
                                <div className='separator mt-40 mb-2'></div>
                                <div className='direita container-botoes mb-3 group-salvar-novo'>
                                    <BotaoPadrao texto='Emitir' disabled={salvando} click={_ => { }} idTooltip={"salvar"} />
                                </div>
                            </Form>
                        </div>
                    )}
                </Formik>
            </ModalCadastro>
        </>
    );
}

export default ConsultaLancamento