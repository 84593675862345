import React, { useState, useEffect } from 'react';
import { InputAutocomplete, InputAutocompleteBtn, InputAutocompleteArray, InputAutocompleteArrayBtn, InputTimePicker, InputPadrao } from '../components/Inputs'
import { getApi, getApiSetLocalStorage, getUsuario } from '../util/Configuracoes'
import { ModalPadraoCadastro } from '../components/Modais'
import { LoadingCombo } from '../components/Loading'
import { CadastroCbos } from '../areas/parametros/cbos/pages/Cadastro'
import { CadastroPaciente } from '../areas/faturamento/beneficiario/pages/Cadastro'
import { CadastroGrupoModal } from '../areas/controleAcesso/grupo/pages/Cadastro'
import { CadastroTipoEscalador } from '../areas/parametros/tipo-escalador/pages/Cadastro'
import { CadastroHospital } from '../areas/administracao/estabelecimento_saude/pages/Cadastro'
import { CadastroCategoria } from '../areas/financeiro/movimento/pages/Cadastro';
import { CadastroContaBancaria } from '../areas/financeiro/conta-bancaria/pages/Cadastro'
import CadastroCliente from '../areas/financeiro/cliente/pages/Cadastro';
import CadastroTabelaProcedimento from '../areas/faturamento/tabela-procedimento/pages/Cadastro'
import CadastroDepartamento from '../areas/administracao/departamento/pages/Cadastro'
import CadastroCirurgiao from '../areas/administracao/cirurgiao/pages/Cadastro'
import CadastroLote from '../areas/faturamento/lote/pages/Cadastro';
import CadastroOperadoraSaude from '../areas/administracao/operadora-saude/pages/Cadastro'
import CadastroAcomodacao from '../areas/faturamento/acomodacao/pages/Cadastro'
import CadastroEmpresa from '../areas/administracao/empresa/pages/Cadastro'
// import { CadastroTipoOcorrenciaModal } from '../areas/administracao/socio-tipo-ocorrencia/pages/Cadastro'
import ls from 'local-storage'
import { get, salvar } from '../util/AcessoApi'
import { removeAcentos } from '../util/FuncoesComuns'
import Yup, { validaHora } from '../util/Validacoes';
import { mostrarToast } from '../components/Toasts'
import { mensagens } from '../util/Utilitarios'
import { Formik, Form } from "formik"
import { BotaoPadrao } from '../components/Botoes'
import moment from "moment"
import 'moment/locale/pt-br'
import chroma from 'chroma-js'
import { components } from 'react-select'
import { ACOES } from '../util/Enums';
import { defaultProps } from 'react-select/async/dist/react-select.cjs.dev';

const InputEstado = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/estadoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete isClearable={true} tamanho={props.tamanho} label={props.label} name={props.name} type="select" placeholder={props.placeholder}
            obrigatorio={props.obrigatorio} onChange={option => props.propsFormik.setFieldValue(props.name, option)} loadOptions={promiseOptions} propsFormik={props.propsFormik} />
    )
}

const InputSexo = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/sexoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete isClearable={true} tamanho={props.tamanho} label={props.label} name={props.name} type="select" isMulti={props.multiplo != undefined ? props.multiplo : false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio} onChange={option => props.propsFormik.setFieldValue(props.name, option)} loadOptions={promiseOptions}
            propsFormik={props.propsFormik} />
    )
}

const InputEstabelecimentoSaude = props => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const user = getUsuario()
    const [data, setData] = useState(null)

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/EstabelecimentoSaudeCombo', { pesquisa: inputValue, ...user }, true)).then(value => filtrar(value, inputValue))

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)

                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const action = () => {
        setShowModal(true)
    }

    return (props.botaoVisivel || props.botaoVisivel === undefined ?
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                botaoVisivel={props.botaoVisivel}
                action={() => action()}
                type="select"
                isMulti={props.multiplo != undefined ? props.multiplo : false}
                placeholder={props.placeholder} obrigatorio={props.obrigatorio}
                onChange={option => { props.propsFormik.setFieldValue(props.name, option); props.onChangeCombo && props.onChangeCombo(option) }}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik} setReference={props.setReference ? props.setReference : {}} {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Estabelecimento de Saúde'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroHospital modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado}></CadastroHospital>
            </ModalPadraoCadastro>
        </> : <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => {
                props.propsFormik && props.propsFormik.setFieldValue(props.name, option);
                if (props.onChangeCombo) {
                    props.onChangeCombo(option)

                }
            }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputCBOS = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/CbosCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)

                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])


    const action = () => {
        setShowModal(true)
    }

    return (
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                botaoVisivel={props.botaoVisivel}
                action={() => action()}
                type="select"
                isMulti={props.multiplo != undefined ? props.multiplo : false}
                placeholder={props.placeholder} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik} minLength={4} />
            <ModalPadraoCadastro titulo={'Cadastro CBOS'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroCbos modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado}></CadastroCbos>
            </ModalPadraoCadastro>
        </>
    )
}

const InputMunicipio = (props) => {
    const filtrarMunicipios = inputValue => {
        let dataMunicipio = ls.get('dataMunicipio')
        if (dataMunicipio) {
            return dataMunicipio.filter(i =>
                removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase()))
            ).slice(0, 10)
        }
    }

    const carregarMunicipios = async (inputValue, callback) => {
        let dataMunicipio = ls.get('dataMunicipio')

        if (!dataMunicipio) {
            dataMunicipio = await getApiSetLocalStorage('dataMunicipio', '/parametros/municipioCombo')
        }

        if (inputValue.toString().length > 0)
            return callback(filtrarMunicipios(inputValue))

        return dataMunicipio.slice(0, 10)
    }

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            placeholder={props.placeholder}
            obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={carregarMunicipios}
            propsFormik={props.propsFormik}
            {...props} />)
}

const InputMunicipioFiltro = (props) => {
    const filtrarMunicipios = inputValue => {
        let dataMunicipio = ls.get('dataMunicipio')
        if (dataMunicipio) {
            return dataMunicipio.filter(i =>
                removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase()))
            ).slice(0, 10)
        }
    }

    const carregarMunicipios = async (inputValue, callback) => {
        let dataMunicipio = ls.get('dataMunicipio')

        if (!dataMunicipio) {
            dataMunicipio = await getApiSetLocalStorage('dataMunicipio', '/parametros/municipioCombo')
        }

        if (inputValue.toString().length > 0)
            return callback(filtrarMunicipios(inputValue))

        return dataMunicipio.slice(0, 10)
    }

    const defaultValue = props.colunaFiltros ? props.colunaFiltros.valor : null


    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            placeholder={props.placeholder}
            obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={carregarMunicipios}
            propsFormik={props.propsFormik}
            isMulti={true}
            value={props.propsFormik.values[props.name] || defaultValue}

            {...props} />)
}

const InputTipoEndereco = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/tipoEnderecoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoDocumento = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/tipoDocumentoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete isClearable={true} tamanho={props.tamanho} label={props.label} name={props.name} type="select" loadOptions={promiseOptions} propsFormik={props.propsFormik}
            isMulti={props.multiplo != undefined ? props.multiplo : false} placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)} />
    )
}

const InputSimNao = (props) => {
    const data = [{ value: 'true', label: 'Sim' },
    { value: 'false', label: 'Não' }]

    const filtrar = inputValue =>
        data && data.filter(i =>
            removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase()))
        ).slice(0, 10);

    const loadOptions = (inputValue, callback) => callback(filtrar(inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); props.onChangeValue && props.onChangeValue(option) }}
            loadOptions={loadOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoRepasse = (props) => {
    const data = [{ value: 'guia', label: 'Guia' },
    { value: 'recurso', label: 'Recurso' },
    { value: 'aih_outros', label: 'AIH/Outros' }]

    const filtrar = inputValue =>
        data && data.filter(i =>
            removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase()))
        ).slice(0, 10);

    const loadOptions = (inputValue, callback) => callback(filtrar(inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); props.onChangeValue && props.onChangeValue(option) }}
            loadOptions={loadOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputPais = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/paisCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputParentesco = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/parentescoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputEstadoCivil = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/estadoCivil', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoEstabelecimento = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/tipoEstabelecimentoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputGrupo = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [data, setData] = useState([])

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/controleAcesso/grupoCombo', null, null, props.noAuth)).then(value => filtrar(value, inputValue))

    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/controleAcesso/grupoCombo', null, null, props.noAuth)
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const action = () => {
        setShowModal(true)
    }

    return (
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                action={() => action()}
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option)}
                defaultOptions={data.length > 0 ? data : true}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Grupo'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroGrupoModal modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado}></CadastroGrupoModal>
            </ModalPadraoCadastro>
        </>
    )
}

const InputEspecialidadeMedica = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/cbos/especiadade', null, null, null)).then(value => filtrar(value, inputValue))

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)

                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])


    const action = () => {
        setShowModal(true)
    }

    return (
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                botaoVisivel={props.botaoVisivel}
                action={() => action()}
                type="select"
                isMulti={props.multiplo != undefined ? props.multiplo : false}
                placeholder={props.placeholder} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik} />
            <ModalPadraoCadastro titulo={'Cadastro CBOS'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroCbos modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado}></CadastroCbos>
            </ModalPadraoCadastro>
        </>
    )
}

const InputRaca = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/raca', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputSocio = (props) => {
    const [data, setData] = useState(null)

    const filtrar = (value, inputValue) => {
        let filtroSigla = [{ value: null, label: 'CONSELHO' }, ...value].filter(i => i.sigla === inputValue.toUpperCase().trim());
        if (filtroSigla && filtroSigla.length > 0)
            return filtroSigla;
        else
            return value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    }

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/socioCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            defaultOptions={[]}
            noOptionsMessage={() => 'Digite para buscar'}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => {
                props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option);
                props.onChangeValue && props.onChangeValue(option)
            }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputSocioConselho = (props) => {
    const [data, setData] = useState(null)

    const filtrar = (value, inputValue) => {
        let filtroSigla = [{ value: null, label: 'CONSELHO' }, ...value].filter(i => i.sigla === inputValue.toUpperCase().trim());
        if (filtroSigla && filtroSigla.length > 0)
            return filtroSigla;
        else
            return value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    }

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/socioCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            defaultOptions={[]}
            noOptionsMessage={() => 'Digite para buscar'}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => {
                props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option);
                props.onChangeValue && props.onChangeValue(option)
            }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoEscalador = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [data, setData] = useState([])

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/tipoEscaladorCombo', null, null, null)).then(value => filtrar(value, inputValue))

    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/parametros/tipoEscaladorCombo')
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)

                props.propsFormik.setFieldValue(props.name, [...array])
            } else {

                props.propsFormik.setFieldValue(props.name, itemSelecionado)
            }
        }
    }, [itemSelecionado])


    const action = () => {
        setShowModal(true)
    }

    return (
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                action={() => action()}
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option)}
                defaultOptions={data.length > 0 ? data : true}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Tipo Escalador'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroTipoEscalador modal setShow={setShowModal} setItemSelecionado={setItemSelecionado}></CadastroTipoEscalador>
            </ModalPadraoCadastro>
        </>
    )
}

const InputTipoUsuario = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/controleAcesso/usuarioTipoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    useEffect(() => {
        if (props.propsFormik.values[props.name] && props.propsFormik.values[props.name].value === 1) {
            props.propsFormik.setFieldValue('socio', null)
            props.propsFormik.setFieldValue('estabelecimento_saude', null)
            props.propsFormik.setFieldValue('cirurgiao', null)
        }
        else if (props.propsFormik.values[props.name] && (props.propsFormik.values[props.name].value === 2 || props.propsFormik.values[props.name].value === 3)) {
            props.propsFormik.setFieldValue('departamento', null)
            props.propsFormik.setFieldValue('estabelecimento_saude', null)
            props.propsFormik.setFieldValue('cirurgiao', null)
        }
        else if (props.propsFormik.values[props.name] && props.propsFormik.values[props.name].value === 4) {
            props.propsFormik.setFieldValue('departamento', null)
            props.propsFormik.setFieldValue('socio', null)
        }
        else if (props.propsFormik.values[props.name] === null) {
            props.propsFormik.setFieldValue('socio', null)
            props.propsFormik.setFieldValue('estabelecimento_saude', null)
            props.propsFormik.setFieldValue('cirurgiao', null)
            props.propsFormik.setFieldValue('departamento', null)
        }
    }, [props.usuarioTipo])

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); props.setUsuarioTipo && props.setUsuarioTipo(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputEmpresa = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [data, setData] = useState([])

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/empresa/combo', null, null, null)).then(value => filtrar(value, inputValue))

    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/administracao/empresa/combo')
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)

                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])


    const action = () => {
        setShowModal(true)
    }

    return (
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                botaoVisivel={props.botaoVisivel}
                action={() => action()}
                type="select"
                isMulti={props.multiplo != undefined ? props.multiplo : false}
                placeholder={props.placeholder} obrigatorio={props.obrigatorio}
                onChange={option => props.onChange && props.onChange || props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik} {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Empresa'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroEmpresa modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado} />
            </ModalPadraoCadastro>
        </>
    )
}

const InputEmpresaExecutante = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [data, setData] = useState([])

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/empresa/combo', null, null, null)).then(value => filtrar(value, inputValue))

    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/administracao/empresa/combo')
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)

                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])


    const action = () => {
        setShowModal(true)
    }

    return (
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                botaoVisivel={props.botaoVisivel}
                action={() => action()}
                type="select"
                isMulti={props.multiplo != undefined ? props.multiplo : false}
                placeholder={props.placeholder} obrigatorio={props.obrigatorio}
                onChange={option => props.onChange && props.onChange || props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik} {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Empresa'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroEmpresa modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado} />
            </ModalPadraoCadastro>
        </>
    )
}

const InputDepartamento = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [data, setData] = useState([])

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/departamentoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/administracao/departamentoCombo')
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)

                props.propsFormik.setFieldValue(props.name, [...array])
            } else {

                props.propsFormik.setFieldValue(props.name, itemSelecionado)
            }
        }
    }, [itemSelecionado])

    const action = () => {
        setShowModal(true)
    }

    return (
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                action={() => action()}
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                defaultOptions={data.length > 0 ? data : true}
                {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Departamento'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroDepartamento modal setShow={setShowModal} setItemSelecionado={setItemSelecionado}></CadastroDepartamento>
            </ModalPadraoCadastro>
        </>
    )
}

const InputTipoTabelaProcedimento = props => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/tipoTabelaProcedimentoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputCirurgiaoCombo = props => {
    const user = getUsuario()
    const promiseOptions = inputValue => Promise.resolve(get('/administracao/cirurgiaoCombo', { pesquisa: inputValue, ...user }, true)).then(value => value)

    return <InputAutocomplete
        cacheOptions={false}
        isClearable={true}
        tamanho={props.tamanho}
        label={props.label}
        name={props.name}
        type="select"
        isMulti={props.multiplo || false}
        placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
        onChange={option => {
            props.propsFormik && props.propsFormik.setFieldValue(props.name, option);
            if (props.onChangeCombo) {
                props.onChangeCombo(option)
            }
        }}
        loadOptions={promiseOptions}
        propsFormik={props.propsFormik}
        {...props} />
}

const InputCirurgiao = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [data, setData] = useState([])

    useEffect(() => {
        setData(null);
        Promise.resolve(get('/administracao/cirurgiaoCombo', { pesquisa: '' }, null, null)).then(value => {
            ls.set('tem_cache_cirurgiao', true);
            ls.set('cache_cirurgiao', value);
            ls.set('ultimo_cache_cirurgiao', moment());
            setData(value);
        })
    }, [])

    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/administracao/cirurgiaoCombo')
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)

                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const filtrar = (value, inputValue) => {
        let login = ls.get('usuario').login
        value = value;
        inputValue = removeAcentos(inputValue).toUpperCase().trim();
        let arrayPesquisa = inputValue.split(' ');

        value = value.filter(i => {
            if (inputValue == '' && i.logins.includes(login)) {
                i.peso = 50;
                return true;
            }
            else {
                i.peso = 10;
                return true;
            }
        }).sort(function (a, b) {
            if (a.peso > b.peso) { return -1; }
            if (b.peso > a.peso) { return 1; }
            return 0;
        })

        if (inputValue.length < 1)
            return value.splice(0, 30);

        let res = value.filter(i => {
            i.peso = 0;
            if (i.descricao && i.descricao === inputValue) {
                i.peso = 130;
                return true;
            }
            if (i.label.includes(inputValue)) {
                i.peso = 100;
                return true;
            }
            else {
                let countPalavras = 0;
                if (i.label.includes(inputValue)) {
                    countPalavras = countPalavras + 50;
                }
                let arrayPalavras = i.label.split(' ');
                for (let index = 0; index < arrayPalavras.length; index++) {
                    const palavra = arrayPalavras[index];
                    for (let j = 0; j < arrayPesquisa.length; j++) {
                        if (palavra.includes(arrayPesquisa[j])) {
                            countPalavras = countPalavras + 1;
                        }
                    }
                }
                i.peso = countPalavras;
                if (countPalavras > 0)
                    return true;
            }
        }
        ).sort(function (a, b) {
            if (a.peso > b.peso) { return -1; }
            if (b.peso > a.peso) { return 1; }
            return 0;
        }).slice(0, 50)

        delete res['peso']
        return res;
    }

    const promiseOptions = inputValue => {
        if (ls.get('tem_cache_cirurgiao') && moment().diff(ls.get('ultimo_cache_cirurgiao'), 'minutes') < 60) {
            return new Promise(resolve => {
                resolve(filtrar(ls.get('cache_cirurgiao'), inputValue));
            });
        }
        else {
            return Promise.resolve(get('/administracao/cirurgiaoCombo', { pesquisa: '' }, null, null)).then(value => {
                ls.set('tem_cache_cirurgiao', true);
                ls.set('cache_cirurgiao', value);
                ls.set('ultimo_cache_cirurgiao', moment());
                filtrar(value, inputValue)
            })
        }

    }




    const action = () => {
        setShowModal(true)
    }
    return data === null ? (<LoadingCombo {...props}></LoadingCombo>) : (props.botaoVisivel ?
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                action={() => action()}
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => {
                    props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option);
                    props.onChangeValue && props.onChangeValue(option)
                }}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Cirurgião'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroCirurgiao modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado} {...props} />
            </ModalPadraoCadastro>
        </> :
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => {
                props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option);
                props.onChangeValue && props.onChangeValue(option)
            }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputGrupoEmpresa = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/parametros/grupoCombo', null, null, null)).then(value => filtrar(value, inputValue))
    const [data, setData] = useState([])

    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/parametros/grupoCombo')
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)

                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])


    const action = () => {
        setShowModal(true)
    }

    return (
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                botaoVisivel={props.botaoVisivel}
                action={() => action()}
                type="select"
                isMulti={props.multiplo != undefined ? props.multiplo : false}
                placeholder={props.placeholder} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                defaultOptions={data.length > 0 ? data : true}
                propsFormik={props.propsFormik} {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Empresa'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroCbos modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado}></CadastroCbos>
            </ModalPadraoCadastro>
        </>
    )
}

const InputOperadoraSaude = props => { // aqui
    let removerInativos = props.removerInativos || false
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/administracao/operadoraSaudeCombo', { removerInativos, pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))
    const [data, setData] = useState([])


    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/administracao/operadoraSaudeCombo')
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const action = _ => { setShowModal(true) }

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.cnpj ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>{item.cnpj ? `CNPJ: ${item.cnpj}` : ''}  </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <>
            <InputAutocompleteBtn
                components={{ Option }}
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                action={() => action()}
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option) &&
                    (props.functionOnChange && props.functionOnChange(props.propsFormik, option) || '')}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                defaultOptions={data.length > 0 ? data : true}
                {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Operadora de Saúde'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroOperadoraSaude modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado} {...props} />
            </ModalPadraoCadastro>
        </>
    )
}

const InputOperadoraSaudePorContrato = props => {
    const [data, setData] = useState([])
    const contrato = props.propsFormik.values.operadora_saude

    useEffect(() => {
        if (moment(props.data_procedimento, 'DD/MM/YYYY', true).isValid()) {
            Promise.resolve(get('/administracao/operadoraSaudeContratoCombo', { data_procedimento: props.data_procedimento }, null, null)).then(value => {
                if (contrato != null) {
                    const index = value.findIndex(x => x.value === contrato.value)
                    props.propsFormik.setFieldValue(props.name, index > -1 ? value[index] : undefined)
                }
                setData(value)
            })
        } else {
            setData([])

        }
    }, [props.data_procedimento])

    const filtrar = (value, inputValue) => value.filter(i => (i.codigo_barras + ' - ' + i.label + ' - ' + i.contrato_id + ' - ' + i.numero + ' - ' + i.codigo_subdivisao + ' - ' + i.idenficacao).toLowerCase().includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(data).then(value => filtrar(value, inputValue))

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.codigo_subdivisao || item.numero ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>{item.codigo_subdivisao ? `Código: ${item.codigo_subdivisao} |` : ''} N° Contrato: {item.numero + ' | '} Vigência: {item.vigencia} {item.descricao ? ` | Descrição: ${item.descricao}` : ''}  </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <InputAutocomplete
            components={{ Option }}
            isDisabled={props.disabled}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
            defaultOptions={data && data.length ? data : true}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoOcorrencia = props => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/administracao/socio-tipo-ocorrencia/combo', null, null, null)).then(value => filtrar(value, inputValue))
    const [data, setData] = useState([])

    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/administracao/socio-tipo-ocorrencia/combo')
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const action = _ => { setShowModal(true) }

    return (
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                action={() => action()}
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                defaultOptions={data.length > 0 ? data : true}
                {...props} />
        </>
    )
}

const InputContratoStatus = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/contratoStatusCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTabelaProcedimento = props => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/tabelaProcedimentoCombo', null, null, null)).then(value => filtrar(value, inputValue))
    const [data, setData] = useState([])

    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/faturamento/tabelaProcedimentoCombo')
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const action = _ => { setShowModal(true) }

    return (
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                action={() => action()}
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                defaultOptions={data.length > 0 ? data : true}
                {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Tabela Procedimento'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroTabelaProcedimento modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado} {...props} />
            </ModalPadraoCadastro>
        </>
    )
}

const InputContratoBaseCalculo = props => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/contratoBaseCalculoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputEscalador = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/administracao/escaladorCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputPeriodo = (props) => {
    const data = [
        { value: 1, label: 'Dia' },
        { value: 2, label: 'Semana' },
        { value: 3, label: 'Mes' }]

    const filtrar = inputValue =>
        data && data.filter(i =>
            removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase()))
        ).slice(0, 10);

    const loadOptions = (inputValue, callback) => callback(filtrar(inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={loadOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputUsuarioAuditoria = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/controleAcesso/usuarioAuditoriaCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputAcomodacao = props => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/acomodacaoCombo', null, null, null)).then(value => filtrar(value, inputValue))
    const [data, setData] = useState([])

    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/faturamento/acomodacaoCombo')
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const action = _ => { setShowModal(true) }

    return (
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                action={() => action()}
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                defaultOptions={data.length > 0 ? data : true}
                {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Acomodação'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroAcomodacao modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado} {...props} />
            </ModalPadraoCadastro>
        </>
    )
}

const InputAcomodacaoContrato = React.forwardRef((props, ref) => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/acomodacaoContratoCombo', { contrato: props.contrato }, null, null)).then(value => filtrar(value, inputValue))
    const [data, setData] = useState([])
    const [valorInput, setValorInput] = useState({})

    const atualizar = () => {
        Promise.resolve(get('/faturamento/acomodacaoContratoCombo', { contrato: props.contrato }, null, null)).then(value => {

            if (props.propsFormik.values[props.name] && value.findIndex(x => x.value === props.propsFormik.values[props.name].value) === -1) {
                props.aposAtualizar && props.aposAtualizar(undefined)
                props.propsFormik.setFieldValue(props.name, undefined)
            } else {
                props.aposAtualizar && props.aposAtualizar(props.propsFormik.values[props.name])
            }

            setData(value)
        })
    }


    if (ref)
        ref.current = {
            atualizar: atualizar,
            value: props.propsFormik.values[props.name]
        }
    useEffect(_ => {
        if (props.propsFormik.values.acomodacao)
            props.aposAtualizar && props.aposAtualizar(props.propsFormik.values[props.name])


    }, [props.propsFormik.values.acomodacao])


    useEffect(() => {

        if (props.setarSeForUm) {
            Promise.resolve(get('/faturamento/acomodacaoContratoCombo', { contrato: props.contrato }, null)).then(value => {
                if (value && value.length == 1) {
                    setValorInput(value)
                    props.propsFormik.setFieldValue(props.name, value[0])
                    props.setProcedimentoBloqueado(false)
                }
                else if (!props.id) {
                    props.propsFormik.setFieldValue(props.name, undefined)
                    props.setProcedimentoBloqueado(true)

                }
            })
        }

    }, [props.setarSeForUm, props.contrato])

    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/faturamento/acomodacaoContratoCombo')
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const action = _ => { setShowModal(true) }

    return (
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                action={() => action()}
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                defaultOptions={data && data.length > 0 ? data : true}
                {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Acomodação'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroAcomodacao modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado} {...props} />
            </ModalPadraoCadastro>
        </>
    )
});

const InputTipoRelatorio = props => {
    const [itemSelecionado, setItemSelecionado] = useState(null)

    useEffect(_ => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)

                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const filtrar = inputValue => {
        if (props.tipoRelatorio)
            return props.tipoRelatorio.filter(i =>
                removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase()))
            ).slice(0, 10)
    }

    const loadOptions = (inputValue, callback) => callback(filtrar(inputValue))

    if (props.tipoRelatorio.length > 0)
        return (<><InputAutocompleteBtn
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            botaoVisivel={props.botaoVisivel}
            type="select"
            isMulti={props.multiplo != undefined ? props.multiplo : false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); option && props.setRelatorioSelecionado(option.value) }}
            loadOptions={loadOptions}
            propsFormik={props.propsFormik} {...props} />
        </>)
    else
        return (<></>)
}

const InputTipoGuia = props => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/guiaTipoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputPorArray = (props) => {
    const [data, setData] = useState(new Promise((resolve, reject) => resolve(props.data)))

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(data).then(value => filtrar(value, inputValue))

    return (
        <InputAutocompleteArray
            isClearable={props.isClearable != undefined ? props.isClearable : true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo != undefined ? props.multiplo : false}
            placeholder={props.placeholder}
            obrigatorio={props.obrigatorio}
            onChange={props.onChange}
            loadOptions={promiseOptions}
            {...props}
        />
    )
}

const InputPorteProcedimento = props => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/porteProcedimentoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputBairro = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/bairroCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputOrdenamento = props => {
    const [data, setData] = useState([])
    const countDisplay = props.countDisplay && props.countDisplay || 10
    useEffect(() => {
        setData(props.data || [])
    }, [props.data])

    const filtrar = (inputValue) => {
        if (data) {
            let dados2 = data.filter(i => {
                if (i.label2)
                    return removeAcentos(i.label2.toLowerCase()).includes(inputValue ? removeAcentos(inputValue.toLowerCase()) : '')
                else if (typeof (i.label) == 'string')
                    return removeAcentos((i.label || '').toLowerCase()).includes(inputValue ? removeAcentos(inputValue.toLowerCase()) : '')
            }).slice(0, countDisplay);

            return dados2.map(x => {
                if (typeof (x.label) == 'string') {
                    x.label2 = x.label;
                    x.label = (<div>{x.label.toString().replace("- Asc", " ").replace("- Desc", " ")}{x.label.toString().toLowerCase().indexOf("asc") > 0 ? <span className="icon-Down icone-ordenamento"></span> : <span className="icon-Up icone-ordenamento"></span>}</div>)
                }
                return x;
            })
        }
    }
    const loadOptions = (inputValue, callback) => {
        callback(filtrar(inputValue));
    }

    if (data.length > 0)
        return (props.propsFormik ? <InputAutocompleteArray
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            icone={props.icone}
            name={props.name}
            type="select"
            isMulti={props.multiplo != undefined ? props.multiplo : false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={props.onChange}
            loadOptions={loadOptions}
            {...props}
        /> : <InputAutocompleteArray
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo != undefined ? props.multiplo : false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={props.onChange}
            loadOptions={loadOptions}
            {...props}
        />)
    else {
        return (<></>)
    }
}

const InputIntervaloHorario = React.forwardRef((props, ref) => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [data, setData] = useState([])
    const [salvarENovo, setSalvarENovo] = useState(false)
    const [salvando, setSalvando] = useState(false)
    const [valoresIniciais, setValoresIniciais] = useState({ horario_inicial: '', horario_final: '' })
    const [validacao] = useState(Yup.object({
        horario_inicial: Yup.string().required().test('Horário Inicial', 'Hora inválida!', function (value) { return validaHora(this.parent.horario_inicial) }),
        horario_final: Yup.string().required().test('Horário Final', 'Hora inválida!', function (value) { return validaHora(this.parent.horario_final) })
    }))

    const atualizar = () => {
        getApi(setData, '/administracao/intervaloHorarioCombo')
    }

    if (ref)
        ref.current = {
            atualizar: _ => getApi(setData, '/administracao/intervaloHorarioCombo')
        }

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/intervaloHorarioCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    const action = () => { setShowModal(true) }

    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/administracao/intervaloHorarioCombo')
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
            } else {
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
            }
        }
    }, [itemSelecionado])

    const Submit = (values, resetForm) => {
        setSalvando(true)

        salvar(values, '/administracao/intervaloHorario',
            (obj) => {
                mostrarToast('sucesso', mensagens.sucesso)
                getApi(setData, '/administracao/intervaloHorarioCombo')
                setItemSelecionado({ value: obj.id, label: obj.horario_inicial.split(' ')[1] + ' até ' + obj.horario_final.split(' ')[1] })

                if (props.funcaoAposSalvar)
                    props.funcaoAposSalvar();

                if (salvarENovo) {
                    resetForm({})
                    setSalvando(false)
                }
                else
                    setShowModal(false)
            },
            () => { setSalvando(false) })
    }

    return (props.botaoVisivel || props.botaoVisivel === undefined ?
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                action={() => action()}
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => { props.propsFormik && props.propsFormik.setFieldValue(props.name, option); props.onChangeValue && props.onChangeValue(option) }}
                defaultOptions={data.length > 0 ? data : true}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Intervalo Horário'} showModal={showModal} setShowModal={setShowModal}>
                <Formik
                    initialValues={valoresIniciais}
                    validationSchema={validacao}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm }) => Submit(values, resetForm)}>
                    {propsFormik => (<Form>
                        <div className='row'>
                            <InputTimePicker label="Horário Inicial" mascara="11:11" name="horario_inicial" type="text" placeholder="Horário Inicial" classeTooltip={'tooltip-input-modal'}
                                setarValor={value => { propsFormik.setFieldValue("horario_inicial", value) }} />
                            <InputTimePicker label="Horário Final" mascara="11:11" name="horario_final" type="text" placeholder="Horário Final" classeTooltip={'tooltip-input-modal'}
                                setarValor={value => { propsFormik.setFieldValue("horario_final", value) }} />
                        </div>
                        <div className='direita container-botoes mb-3 group-salvar-novo'>
                            <BotaoPadrao texto='Salvar e Novo' disabled={salvando} click={_ => setSalvarENovo(true)} />
                            <BotaoPadrao texto='Salvar' disabled={salvando} click={_ => setSalvarENovo(false)} />
                        </div>
                    </Form>)}
                </Formik>
            </ModalPadraoCadastro>
        </> : <InputAutocomplete
            defaultOptions={data.length > 0 ? data : true}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik && props.propsFormik.setFieldValue(props.name, option); props.onChangeValue && props.onChangeValue(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            onFocus={() => props.atualizarOnFocus ? atualizar() : {}}
            {...props} />
    )
});

const InputBeneficiario = props => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label + ' - ' + i.value + ' - ' + i.cpf + ' - ' + i.cpf_sem_acento).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/beneficiarioCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    const action = () => {
        setShowModal(true)
    }

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
                if (props.onChange)
                    props.onChange([...array])
            } else {
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
                if (props.onChange)
                    props.onChange(itemSelecionado)
            }
        }
    }, [itemSelecionado])


    const Option = (props) => {

        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.cpf || item.rg ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>{item.cpf ? `CPF: ${item.cpf} |` : ''}  {item.rg ? `RG: ${item.rg} |` : ''} </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    const onChange = (option) => {
        if (props.onChange) {
            props.onChange(option)
        }
    }

    return (
        <>
            <InputAutocompleteBtn
                components={{ Option }}
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                botaoVisivel={props.botaoVisivel}
                action={() => action()}
                type="select"
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => {
                    props.propsFormik.setFieldValue(props.name, option);
                    onChange(option)
                }}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik} />
            <ModalPadraoCadastro titulo={'Cadastro Paciente'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroPaciente modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado}></CadastroPaciente>
            </ModalPadraoCadastro>
        </>)
}

const InputBeneficiarioGuia = props => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => {
        if (inputValue)
            props.setDefaultOptionsPaciente(true)

        return Promise.resolve(get('/faturamento/beneficiarioComboGuia', null, null, null)).then(value => filtrar(value, inputValue))
    }

    const action = () => {
        setShowModal(true)
    }

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
                if (props.onChange)
                    props.onChange([...array])
            } else {
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
                if (props.onChange)
                    props.onChange(itemSelecionado)
            }
        }
    }, [itemSelecionado])

    const onChange = (option) => {
        if (props.onChange) {
            props.onChange(option)
        }
    }

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.cpf || item.rg ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>{item.cpf ? `CPF: ${item.cpf} |` : ''}  {item.rg ? `RG: ${item.rg} |` : ''} </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <>
            <InputAutocompleteBtn
                // defaultMenuIsOpen = {props.idGuiaComplementar != null ? true : false}
                openMenuOnFocus={props.openMenuOnFocus}
                options={props.options}
                components={{ Option }}
                defaultOptions={props.defaultOptions && props.defaultOptions.length > 1 ? props.defaultOptions : true}
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                botaoVisivel={props.botaoVisivel}
                action={() => action()}
                type="select"
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => {
                    props.propsFormik.setFieldValue(props.name, option);
                    onChange(option)
                }}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik} setReference={props.setReference ? props.setReference : {}} />
            <ModalPadraoCadastro titulo={'Cadastro Paciente'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroPaciente modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado}></CadastroPaciente>
            </ModalPadraoCadastro>
        </>)
}

const InputProcedimento = props => {
    const filtrar = (value, inputValue) => value

    const promiseOptions = inputValue => {
        return Promise.resolve(get('/faturamento/procedimentoCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))
    }

    return (
        <InputAutocomplete isClearable={true} tamanho={props.tamanho} label={props.label} name={props.name} type="select" isMulti={props.multiplo || false} {...props}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio} loadOptions={promiseOptions} propsFormik={props.propsFormik}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option) }} minLength={4} />
    )
}

const InputProcedimentoCompleto = props => {
    const filtrar = (value, inputValue) => value

    const promiseOptions = inputValue => {
        return Promise.resolve(get('/faturamento/procedimentoCompletoCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))
    }

    return (
        <InputAutocomplete isClearable={true} tamanho={props.tamanho} label={props.label} name={props.name} type="select" isMulti={props.multiplo || false} {...props}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio} loadOptions={promiseOptions} propsFormik={props.propsFormik}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option) }} minLength={4} />
    )
}

const InputProcedimentoCodigo = props => {
    const filtrar = (value, inputValue) => value

    const promiseOptions = inputValue => {
        return Promise.resolve(get('/faturamento/procedimentoCodigoCombo', { pesquisa: inputValue }, null, null)).then(value => { return filtrar(value, inputValue) })
    }

    return (
        <InputAutocomplete isClearable={true} tamanho={props.tamanho} label={props.label} name={props.name} type="select" isMulti={props.multiplo || false} {...props}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio} loadOptions={promiseOptions} propsFormik={props.propsFormik}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option) }} minLength={4} />
    )
}

const InputContrato = props => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/contratoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputContratoFiltro = props => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label + ' - ' + i.value + ' - ' + i.codigo_subdivisao + ' - ' + i.numero_contrato).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/contratoComboFiltro', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.codigo_subdivisao || item.numero ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Código: {item.value} | {item.codigo_subdivisao ? `Código Sub.: ${item.codigo_subdivisao} |` : ''} N° Contrato: {item.numero_contrato + ' | '} Vigência: {item.vigencia} </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputContratoFiltroParticular = props => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label + ' - ' + i.value + ' - ' + i.codigo_subdivisao + ' - ' + i.numero_contrato).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/contratoComboFiltroParticular', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.codigo_subdivisao || item.numero ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Código: {item.value} | {item.codigo_subdivisao ? `Código Sub.: ${item.codigo_subdivisao} |` : ''} N° Contrato: {item.numero_contrato + ' | '} Vigência: {item.vigencia} </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}


const InputUsuarioSocio = (props) => {

    const filtrar = (value, inputValue) => {

        try {
            let filtroSigla = value.filter(i => i.sigla === inputValue.toUpperCase().trim());
            if (filtroSigla && filtroSigla.length > 0)
                return filtroSigla;
            else
                return value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
        } catch (error) {
            console.log('error: ', error);
            return value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
        }


    }

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/socioComboUsuario', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputSocioAtivos = (props) => {
    const [data, setData] = useState([])

    useEffect(() => {
        Promise.resolve(get('/administracao/socioComboAtivos', { data: props.data }, null, null)).then(value => setData(value))
    }, [props.data])

    const promiseOptions = inputValue => Promise.resolve(data).then(value => filtrar(value, inputValue))

    const filtrar = (value, inputValue) => {
        let filtroSigla = value.filter(i => i.sigla === inputValue.toUpperCase().trim());
        if (filtroSigla && filtroSigla.length > 0)
            return filtroSigla;
        else
            return value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    }

    const onChange = (option) => {
        props.propsFormik.setFieldValue(props.name, option);
        if (props.onChangeSocio)
            props.onChangeSocio(option, props.propsFormik);
    }

    useEffect(() => {
        setData(new Promise((resolve, reject) => resolve(props.data)))
    }, [props.data])

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => { onChange(option); props.onChangeValue && props.onChangeValue(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputSocioGuia = ({ value, ...props }) => {
    const [data, setData] = useState([])

    useEffect(() => {
        const valor = value
        if (moment(props.data, 'DD/MM/YYYY', true).isValid() && (props.atualizar === true || props.atualizar === undefined)) {
            get('/administracao/socioComboGuia', { data: props.data }, null, null).then(value => {
                let newOption = undefined
                if (value && valor) {
                    newOption = value.length > 0 && value.findIndex(x => x.value === valor.value) > -1 ? valor : undefined
                }

                props.propsFormik.setFieldValue(props.name, newOption)
                if (props.funcaoAposObter)
                    props.funcaoAposObter(newOption)
            })
        }
    }, [props.data])

    const promiseOptions = inputValue => {
        return Promise.resolve(get('/administracao/socioComboGuia', { pesquisa: inputValue, data: props.data }, null, null)).then(value => {
            return filtrar(value, inputValue)
        })
    }

    const filtrar = (value, inputValue) => {
        let filtroSigla = value.filter(i => i.sigla === inputValue.toUpperCase().trim());
        if (filtroSigla && filtroSigla.length > 0)
            return filtroSigla;
        else
            return value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    }

    const onChange = (option) => {
        props.propsFormik.setFieldValue(props.name, option);
        if (props.onChangeSocio)
            props.onChangeSocio(option, props.propsFormik);
    }

    return (
        <InputAutocomplete
            defaultOptions={[]}
            noOptionsMessage={() => 'Digite para buscar'}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => { onChange(option); props.onChangeValue && props.onChangeValue(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            onFocus={(x) => props.propsFormik.setFieldTouched(props.name, true)}
            {...props} />
    )
}

const InputEmpresaGuia = React.forwardRef((props, ref) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const valorAtual = { ...props.valor }

    const forcarAtualizacao = (obj) => {
        setLoading(true)
        get('/administracao/empresaComboPorSocioAtivo', { data: obj.data, id: obj.socioId }, null, null).then(value => {
            setLoading(false)
            setData(value)
        })
    }


    const atualizar = (obj) => {
        if (props.atualizar || !obj.socioId) {
            setTimeout(() => {
                props.propsFormik.setFieldValue(props.name, undefined)
            }, 50)
        }
        if (moment(obj.data, 'DD/MM/YYYY', true).isValid() && obj.socioId) {
            get('/administracao/empresaComboPorSocioAtivo', { data: obj.data, id: obj.socioId }, null, null).then(value => {
                let novoValor = undefined
                setData(value)
                if (props.funcaoAoObter)
                    props.funcaoAoObter(value)

                if ((obj.tipoPessoa && obj.tipoPessoa === 1) || !props.atualizar) {
                    return;
                }

                if (value.length === 1) {
                    novoValor = value[0]
                } else if (value.length > 1) {
                    const empresa = value.filter(x => x.fatura_empresa === true)
                    if (empresa.length === 1) {
                        novoValor = empresa[0]
                    } else if (valorAtual && value.findIndex(x => x.value === valorAtual.value) > -1) {
                        novoValor = value[value.findIndex(x => x.value === valorAtual.value)]
                    }
                }
                setTimeout(() => {
                    props.propsFormik.setFieldValue(props.name, novoValor)
                }, 100)
            })
        } else {
            setData([])
        }
    }

    if (ref) {
        ref.current = {
            atualizar: atualizar,
            limparCombo: () => setData([])
        }
    }

    const promiseOptions = inputValue => Promise.resolve(data).then(value => filtrar(value, inputValue))

    const filtrar = (value, inputValue) => {
        let filtroSigla = value.filter(i => i.sigla === inputValue.toUpperCase().trim());
        if (filtroSigla && filtroSigla.length > 0)
            return filtroSigla;
        else
            return value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    }

    const onChange = (option) => {
        props.propsFormik.setFieldValue(props.name, option);
        if (props.onChangeSocio)
            props.onChangeSocio(option, props.propsFormik);
    }

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => { onChange(option); props.onChangeValue && props.onChangeValue(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            defaultOptions={data && data.length ? data : true}
            onMenuOpen={() => props.onMenuOpenPrev ? props.onMenuOpenPrev(data, forcarAtualizacao) : _ => { }}
            onMenuClose={() => setData([])}
            isLoading={loading}
            {...props} />
    )
})

const InputSocioStatus = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/socioStatusCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputCro = props => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/CroCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputFiltroEstabelecimentoSaude = props => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/EstabelecimentoSaudeCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputAcoes = props => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/controleAcesso/acoesCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputModulo = props => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/controleAcesso/modulosCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputPorArrayBtn = (props) => {
    const [data, setData] = useState(new Promise((resolve, reject) => resolve(props.data)))
    const [itemSelecionado, setItemSelecionado] = useState(null)

    useEffect(() => {
        setData(new Promise((resolve, reject) => resolve(props.data)))
    }, [props.data])

    useEffect(_ => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)

                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const filtrar = (value, inputValue) => {
        return value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    }

    const promiseOptions = inputValue => {
        return Promise.resolve(data).then(value => filtrar(value, inputValue))
    }

    const acao = () => {
        if (props.action)
            props.action()
        else
            console.log('Ação para InputPorArrayBtn não definida')
    }

    return (
        <InputAutocompleteArrayBtn
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo != undefined ? props.multiplo : false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={props.onChange}
            defaultOptions={props.data}
            loadOptions={promiseOptions}
            action={acao}
            {...props}
        />
    )
}

const InputCodigoProcedimento = props => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => {
        return Promise.resolve(get('/faturamento/codigoProcedimentoCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))
    }

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputUsuarioApp = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/controleAcesso/usuarioAppCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputPorteAnestesico = props => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/porteProcedimentoFiltroCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputSocioEmpresa = (props) => {
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [dataEmpresa, setDataEmpresa] = useState([])
    const [dataSocio, setDataSocio] = useState([])

    useEffect(() => {
        setDataSocio(new Promise((resolve, reject) => resolve(get('/administracao/socioComboAtivos', null, null, null))))
    }, [dataSocio])

    useEffect(() => {
        setDataEmpresa(new Promise((resolve, reject) => resolve(get('/administracao/empresa/combo', null, null, null))))
    }, [dataEmpresa])

    const filtrar = (value, inputValue) => {
        let filtroSigla = value.filter(i => i.sigla === inputValue.toUpperCase().trim());
        if (filtroSigla && filtroSigla.length > 0)
            return filtroSigla;
        else
            return value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    }

    const promiseOptionsEmpresa = inputValue => Promise.resolve(dataEmpresa).then(value => filtrar(value, inputValue))
    const promiseOptionsSocio = inputValue => Promise.resolve(dataSocio).then(value => filtrar(value, inputValue))

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)

                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const onChange = (input, propsFormik, option) => {
        if (input === 'empresa') {
            if (option) {
                Promise.resolve(get(`/administracao/socioComboAtivosPorEmpresa/${option.value}`, null, null, null)).then(value => {
                    if (value.length === 1)
                        propsFormik.setFieldValue('executante', value[0])
                    setDataSocio(value)
                })
            }
            else
                Promise.resolve(get(`/administracao/socioComboAtivos`, null, null, null)).then(value => setDataSocio(value))
        }
        else {
            if (option && option.length) {
                Promise.resolve(get(`/administracao/empresaComboPorSocio/${option[0].value}`, null, null, null)).then(value => {
                    if (value.length === 1)
                        propsFormik.setFieldValue('empresa', value[0])
                    setDataEmpresa(value)
                })
            }
            else
                Promise.resolve(get(`/administracao/empresa/combo`, null, null, null)).then(value => setDataEmpresa(value))
        }

        propsFormik.setFieldValue(input, option)

        if (input === 'executante') {
            props.onChangeSocio(propsFormik, option)
        }
    }

    return (
        <>
            <InputAutocompleteBtn isClearable={true} tamanho={props.tamanho} label={'Empresa'} name={'empresa'} type="select" isMulti={false} placeholder={'Empresa'}
                onChange={option => onChange('empresa', props.propsFormik, option)} loadOptions={promiseOptionsEmpresa}
                propsFormik={props.propsFormik} {...props} defaultOptions={dataEmpresa.length > 0 ? dataEmpresa : true} />

            <InputAutocompleteBtn isClearable={true} tamanho={props.tamanho} label={'Sócio'} name={'executante'} type="select" isMulti={true} placeholder={'Sócio'}
                onChange={option => onChange('executante', props.propsFormik, option)} loadOptions={promiseOptionsSocio}
                propsFormik={props.propsFormik} {...props} defaultOptions={dataSocio.length > 0 ? dataSocio : true} />
        </>
    )
}

const InputStatusAgendamento = props => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/statusAgendamentoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputPeriodoGozo = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/ferias/comboperiodogozo', null, null)).then(value => filtrar(value, inputValue))

    useEffect(() => {
        if (props.name && !props.propsFormik.values[props.name])
            Promise.resolve(get('/administracao/ferias/comboperiodogozo', null, null)).then(value => {
                let index = value.findIndex(anos => anos.ano.toString() === moment().format('yyyy'))
                index = index === -1 ? 0 : index
                props.propsFormik.setFieldValue(props.name, value[index])
            })
    }, [])

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputEscalaTipo = props => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/escalaTipoCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => { props.onChange && props.onChange(option) || props.propsFormik.setFieldValue(props.name, option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputRecorrencio = props => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/recorrenciaCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputStatusAgendamentoPlantao = props => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/statusplantao', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputPeriodoFerias = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/ferias/comboperiodo', null, null, null)).then(value => filtrar(value, inputValue))

    const onChange = (option) => {
        props.propsFormik.setFieldValue(props.name, option);
        if (props.onChangeSocio)
            props.onChangeSocio(option, props.propsFormik);
    }

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => { onChange(option); props.onChangeValue && props.onChangeValue(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputMercadoTipo = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [data, setData] = useState([])
    const [renderizar, setRenderizar] = useState(false)

    const popularCombo = () => {
        get('/administracao/ferias/combotipodevolucao', data, true)
            .then((dados) => {
                setData(dados)
                setRenderizar(true)
            })
            .catch((erro) => {

            })
    }

    useEffect(() => {
        popularCombo();
    }, [])

    useEffect(() => {
        if (itemSelecionado != null) {
            setRenderizar(false)
            popularCombo()
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])


    const action = () => {
        setShowModal(true)
    }

    const filtrar = (inputValue) => {
        if (data) {
            return data.filter(i =>
                removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase()))
            ).slice(0, 10);
        }
    }
    const loadOptions = (inputValue, callback) => callback(filtrar(inputValue))

    if (renderizar)
        return (<><InputAutocompleteBtn
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            action={() => action()}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={loadOptions}
            propsFormik={props.propsFormik}
            {...props} />
        </>)
    else {
        return (<></>)
    }
}

const InputSocioAtivosComConselho = (props) => {
    const [data, setData] = useState([])

    const filtrar = (value, inputValue) => {
        let filtroSigla = value.filter(i => i.sigla === inputValue.toUpperCase().trim());
        if (filtroSigla && filtroSigla.length > 0)
            return filtroSigla;
        else
            return value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    }

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/socioComboAtivos', null, null, null)).then(value => { let obj = value; obj.push({ label: 'CONSELHO', value: null }); return filtrar(obj, inputValue); })

    const onChange = (option) => {
        props.propsFormik.setFieldValue(props.name, option);
        if (props.onChangeSocio)
            props.onChangeSocio(option, props.propsFormik);
    }


    return data && (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => { onChange(option); props.onChangeValue && props.onChangeValue(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputAnoFiltro = (props) => {
    const anoAtual = new Date().getUTCFullYear();
    const [data, setData] = useState(Array(anoAtual - (anoAtual - 10)).fill('').map((v, idx) => ({ value: anoAtual - idx, label: anoAtual - idx })))

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(data).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            defaultOptions={data}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => {
                props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option);
                props.onChangeValue && props.onChangeValue(option)
            }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}
const InputAnoAnestesiaOdonto = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/anestesiaOdontoAno', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTodosProcedimentos = (props) => {
    const [data, setData] = useState([])

    const filtrar = (value, inputValue) => {
        value = value;
        inputValue = removeAcentos(inputValue).toUpperCase().trim();
        let arrayPesquisa = inputValue.split(' ');
        if (inputValue.length < 1)
            return value.splice(0, 30);

        let res = value.filter(i => {
            i.peso = 0;
            if (i.descricao && i.descricao === inputValue) {
                i.peso = 130;
                return true;
            }
            if (i.label.includes(inputValue)) {
                i.peso = 100;
                return true;
            }
            else {
                let countPalavras = 0;
                if (i.label.includes(inputValue)) {
                    countPalavras = countPalavras + 50;
                }
                let arrayPalavras = i.label.split(' ');
                for (let index = 0; index < arrayPalavras.length; index++) {
                    const palavra = arrayPalavras[index];
                    for (let j = 0; j < arrayPesquisa.length; j++) {
                        if (palavra.includes(arrayPesquisa[j])) {
                            countPalavras = countPalavras + 1;
                        }
                    }
                }
                i.peso = countPalavras;
                if (countPalavras > 0)
                    return true;
            }
        }
        ).sort(function (a, b) {
            if (a.peso > b.peso) { return -1; }
            if (b.peso > a.peso) { return 1; }
            return 0;
        }).slice(0, 50)

        delete res['peso']
        return res;
    }

    const promiseOptions = inputValue => {
        if (ls.get('tem_cache_procedimentos_agenda') && moment().diff(ls.get('ultimo_cache_procedimentos_agenda'), 'minutes') < 60) {
            return new Promise(resolve => {
                resolve(filtrar(ls.get('cache_procedimentos_agenda'), inputValue));
            });
        }
        else {
            return Promise.resolve(get('/faturamento/todosProcedimentosComboAgenda', { pesquisa: inputValue }, null, null)).then(value => {
                ls.set('tem_cache_procedimentos_agenda', true);
                ls.set('cache_procedimentos_agenda', value);
                ls.set('ultimo_cache_procedimentos_agenda', moment());
                filtrar(value, inputValue)
            })
        }

    }
    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => {
                props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option);
                props.onChangeValue && props.onChangeValue(option)
            }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputProcedimentosContrato = (props) => {
    const [data, setData] = useState([])

    const filtrar = (value, inputValue) => {
        value = value;
        inputValue = removeAcentos(inputValue).toUpperCase().trim();
        let arrayPesquisa = inputValue.split(' ');
        if (inputValue.length < 1)
            return value.splice(0, 30);

        let res = value.filter(i => {
            i.peso = 0;
            if (i.descricao && i.descricao.toUpperCase() === inputValue.toUpperCase()) {
                i.peso = 130;
                return true;
            }
            if (i.label.toUpperCase().includes(inputValue.toUpperCase())) {
                i.peso = 100;
                return true;
            }
            else {
                let countPalavras = 0;
                if (i.label.toUpperCase().includes(inputValue.toUpperCase())) {
                    countPalavras = countPalavras + 50;
                }
                let arrayPalavras = i.label.split(' ');
                for (let index = 0; index < arrayPalavras.length; index++) {
                    const palavra = arrayPalavras[index];
                    for (let j = 0; j < arrayPesquisa.length; j++) {
                        if (palavra.toUpperCase().includes(arrayPesquisa[j].toUpperCase())) {
                            countPalavras = countPalavras + 1;
                        }
                    }
                }
                i.peso = countPalavras;
                if (countPalavras > 0)
                    return true;
            }
        }
        ).sort(function (a, b) {
            if (a.peso > b.peso) { return -1; }
            if (b.peso > a.peso) { return 1; }
            return 0;
        }).slice(0, 50)

        delete res['peso']
        return res;
    }

    const promiseOptions = inputValue => {
        return props.contrato ? Promise.resolve(get('/faturamento/todosProcedimentosComboGuia', { pesquisa: inputValue, contrato: props.contrato }, null, null)).then(value => value) : Promise.resolve([])
    }

    useEffect(() => {
        Promise.resolve(get('/faturamento/todosProcedimentosComboGuia', { pesquisa: '', contrato: props.contrato }, null, null)).then(value => {
            setData(value)
        })
    }, [props.contrato])

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.tabela_descricao ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Tabela: {item.tabela_descricao}</span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => {
                props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option);
                props.onChangeValue && props.onChangeValue(option)
            }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            defaultOptions={data && data.length > 0 ? data : true}
            {...props} />
    )
}

const InputProcedimentosTabela = (props) => {
    //passe props.tabelas para carregar os procedimentos
    //tabelasSelecionadas diz se você quer apenas das tabelas que ele selecionou ou todas
    let apenasDasTabelas = props.apenasDasTabelas !== false
    const [data, setData] = useState([])

    const filtrar = (value, inputValue) => {
        value = value;
        inputValue = removeAcentos(inputValue).toUpperCase().trim();
        let arrayPesquisa = inputValue.split(' ');
        if (inputValue.length < 1)
            return value.splice(0, 30);

        let res = value.filter(i => {
            i.peso = 0;
            if (i.descricao && i.descricao.toUpperCase() === inputValue.toUpperCase()) {
                i.peso = 130;
                return true;
            }
            if (i.label.toUpperCase().includes(inputValue.toUpperCase())) {
                i.peso = 100;
                return true;
            }
            else {
                let countPalavras = 0;
                if (i.label.toUpperCase().includes(inputValue.toUpperCase())) {
                    countPalavras = countPalavras + 50;
                }
                let arrayPalavras = i.label.split(' ');
                for (let index = 0; index < arrayPalavras.length; index++) {
                    const palavra = arrayPalavras[index];
                    for (let j = 0; j < arrayPesquisa.length; j++) {
                        if (palavra.toUpperCase().includes(arrayPesquisa[j].toUpperCase())) {
                            countPalavras = countPalavras + 1;
                        }
                    }
                }
                i.peso = countPalavras;
                if (countPalavras > 0)
                    return true;
            }
        }
        ).sort(function (a, b) {
            if (a.peso > b.peso) { return -1; }
            if (b.peso > a.peso) { return 1; }
            return 0;
        }).slice(0, 50)

        delete res['peso']
        return res;
    }

    const promiseOptions = inputValue => {
        return props.tabelas || props.apenasDasTabelas === false ? Promise.resolve(get('/faturamento/procedimentosComboTabela', { pesquisa: inputValue, tabelas: props.tabelas, apenasDasTabelas }, null, null)).then(value => filtrar(value, inputValue)) : Promise.resolve([])
    }

    useEffect(() => {
        Promise.resolve(get('/faturamento/procedimentosComboTabela', { pesquisa: '', tabelas: props.tabelas, apenasDasTabelas }, null, null)).then(value => {
            setData(value)
        })
    }, [props.tabelas])

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.tabela_descricao ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Tabela: {item.tabela_descricao}</span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => {
                props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option);
                props.onChangeValue && props.onChangeValue(option)
            }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            defaultOptions={data && data.length > 0 ? data : true}
            {...props} />
    )
}

const InputSocioAtivosComCores = (props) => {
    let carregando = false;
    const [naoRenderizar, setNaoRenderizar] = useState(true)
    const [dadosSocio, setDadosSocio] = useState(null)

    const obterDadosSocios = async () => {
        let dataPesquisa = moment(props.data_inicial, 'DD/MM/YYYY HH:mm').add(1, 'minutes').format('DD/MM/YYYY HH:mm');
        return get(`/administracao/obterDadosComboCorSocio`, { data_inicial: dataPesquisa, id: props.agenda_id });
    }
    useEffect(() => {
        setDadosSocio(null)
        if (props.data_inicial === undefined || props.data_inicial === null || props.data_inicial.toString().length < 10 || props.data_inicial === '' || props.data_inicial.indexOf('_') > -1) {
            setTimeout(() => {
                setDadosSocio([]);
            }, 250);

            setNaoRenderizar(true);
            return;
        }
        setNaoRenderizar(false);
        carregando = true;
        obterDadosSocios().then(val => {
            setTimeout(() => {
                setDadosSocio(val);
            }, 250);
        });

    }, [props.data_inicial, props.agenda_id])

    useEffect(() => {
        if (dadosSocio != null)
            carregando = false;

    }, dadosSocio)

    const filtrar = (value, inputValue) => {
        inputValue = removeAcentos(inputValue).toUpperCase().trim();
        let arrayPesquisa = inputValue.split(' ');
        let res = value.map(x => {
            x.ordem = 20;
            x.horarios = '';

            let registros = dadosSocio.filter(z => {
                return parseInt(z.socio_id) === parseInt(x.value)
            });

            for (let index = 0; index < registros.length; index++) {
                const registro = registros[index];
                switch (registro.tipo_registro) {
                    case 'ferias':
                    case 'ocorrencia':
                        if (x.tipo === 'agenda' || registro.compoe_mapa_horas)
                            break;
                        x.color = '#660099'
                        x.backgroundColor = '#fff'
                        x.backgroundColorActive = '#ffff93'
                        x.ordem = 10;
                        x.tipo = 'ferias';
                        x.tipo_label = '(ausência)';
                        break;
                    case 'alcancavel':
                        if (x.tipo === 'ferias' || x.tipo === 'ocorrencia' || x.tipo === 'agenda')
                            break;
                        x.color = '#0099ff'
                        x.backgroundColor = '#fff'
                        x.backgroundColorActive = '#6dc4ff'
                        x.ordem = 95;
                        x.tipo = 'alcancavel';
                        x.tipo_label = '(alcançável)';
                        break;
                    case 'plantao':
                        if (x.tipo === 'ferias' || x.tipo === 'ocorrencia' || x.tipo === 'agenda')
                            break;
                        x.color = '#00cc66'
                        x.backgroundColor = '#fff'
                        x.backgroundColorActive = '#81e8b4'
                        x.ordem = 100;
                        x.tipo = 'plantao';
                        x.tipo_label = '(plantão)';
                        break;
                    case 'agenda':
                        x.color = '#ff3300'
                        //x.backgroundColor = '#ff3300'
                        x.backgroundColor = '#fff'
                        x.backgroundColorActive = '#ea5b38'
                        x.ordem = 90;
                        x.tipo = 'agenda';
                        x.tipo_label = '(escalado - @horario)';
                        x.horarios = x.horarios + (x.horarios.length > 0 ? '; ' + registro.hora : registro.hora);
                        break;
                    default:
                        break;
                }

            }

            x.label = x.label + (x.tipo_label ? ' ' + x.tipo_label : '');
            x.label = x.label.replace('@horario', x.horarios);
            return x;
        })

        res = res.sort(function (a, b) {
            if (a.ordem > b.ordem) { return -1; }
            if (b.ordem > a.ordem) { return 1; }
            return 0;
        });


        let filtroSigla = res.filter(i => i.sigla === inputValue.toUpperCase().trim());
        if (filtroSigla && filtroSigla.length > 0)
            return filtroSigla;
        else if (inputValue && inputValue.length > 0)
            return res.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
        else return res
    }

    const promiseOptions = inputValue => {
        if (naoRenderizar) {
            return new Promise(resolve => {
                resolve(filtrar([], inputValue, []));
            });
        }
        if (ls.get('tem_cache_socios_ativos') && moment().diff(ls.get('ultimo_cache_socios_ativos'), 'minutes') < 10 && ls.get('cache_socios_ativos').length > 0) {
            return new Promise(resolve => {
                resolve(filtrar(ls.get('cache_socios_ativos'), inputValue, dadosSocio));
            });
        }
        else {
            return Promise.resolve(get('/administracao/socioComboAtivos', { pesquisa: inputValue }, null, null)).then(value => {
                ls.set('tem_cache_socios_ativos', true);
                ls.set('cache_socios_ativos', value);
                ls.set('ultimo_cache_socios_ativos', moment());
                return filtrar(value, inputValue, dadosSocio);
            })
        }


    }
    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            data.color = data.color == undefined ? '#000' : data.color;
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color || '#ccc'
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color || '#ccc',
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor:
                        !isDisabled && (isSelected ? data.color || '#ccc' : color.alpha(0.3).css()),
                },
            };
        },
        multiValue: (styles, { data }) => {
            const color = chroma(data.color || '#000');
            return {
                ...styles,
                backgroundColor: color.alpha(0.1).css(),
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color || '#000',
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color || '#000',
            ':hover': {
                backgroundColor: data.color || '#ccc',
                color: 'white',
            },
        }),
    };

    return dadosSocio === null ? (<LoadingCombo {...props}></LoadingCombo>) : (
        <InputAutocomplete
            isClearable={true}
            cacheOptions={false}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            noOptionsMessage={() => (naoRenderizar ? "Informe a data e hora inicial antes de consultar os sócios." : "Nenhum registro encontrado.")}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => {
                props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option);
                props.onChangeValue && props.onChangeValue(option)
            }}
            selectStyle={colourStyles}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputSocioAtivosComGrupos = (props) => {
    let carregando = false;
    const [naoRenderizar, setNaoRenderizar] = useState(true)
    const [gruposEscala, setGruposEscala] = useState(null)
    const [socioComGrupos, setSocioComGrupos] = useState(null)

    const obterDadosSociosNoGrupo = async () => {
        let dataPesquisa = moment(props.data_inicial, 'DD/MM/YYYY').format('DD/MM/YYYY');
        return get(`/administracao/socioComGrupos`, { data_inicial: dataPesquisa });
    }


    const obterDadosGrupo = async () => {
        let dataPesquisa = moment(props.data_inicial, 'DD/MM/YYYY').format('DD/MM/YYYY');
        return get(`/administracao/obterGruposEscala`, { data_inicial: dataPesquisa });
    }
    useEffect(() => {
        setGruposEscala(null);
        setSocioComGrupos(null);
        if (props.data_inicial === '' || props.data_inicial.indexOf('_') > -1) {
            setGruposEscala([]);
            setSocioComGrupos([]);
            setNaoRenderizar(true);
            return;
        }
        setNaoRenderizar(false);
        carregando = true;
        obterDadosSociosNoGrupo().then(val => {
            setSocioComGrupos(val);
        });

        obterDadosGrupo().then(val => {
            setGruposEscala(val);
        });


    }, [props.data_inicial, props.agenda_id])

    useEffect(() => {
        if (gruposEscala != null)
            carregando = false;

    }, gruposEscala)

    const filtrar = (value, inputValue) => {
        inputValue = removeAcentos(inputValue).toUpperCase().trim();
        let arrayPesquisa = inputValue.split(' ');

        let res = value.map(x => {
            x.ordem = 20;
            x.grupo = false;
            x.socios = null;
            return x;
        })

        if (gruposEscala && gruposEscala.length > 0 && socioComGrupos && socioComGrupos.length > 0)
            for (let index = 0; index < gruposEscala.length; index++) {
                const element = gruposEscala[index];
                res.push({
                    value: 'grupo_' + element.id, label: element.descricao, sigla: element.descricao, ordem: 30, grupo: true,
                    socios: socioComGrupos.filter(z => z.grupo_id == element.id).map(z => ({
                        value: z.socio_id,
                        label: z.sigla + ' - ' + z.nome,
                        nome: z.nome, sigla: z.sigla,
                        ordem: z.ordenamento,
                        grupo: false
                    }))
                })


            }
        res = res.sort(function (a, b) {
            if (a.ordem > b.ordem) { return -1; }
            if (b.ordem > a.ordem) { return 1; }
            return 0;
        });
        let filtroSigla = res.filter(i => i.sigla === inputValue.toUpperCase().trim());
        if (filtroSigla && filtroSigla.length > 0)
            return filtroSigla;
        else if (inputValue && inputValue.length > 0)
            return res.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
        else return res
    }

    const promiseOptions = inputValue => {
        let sociosCache = ls.get('cache_socios_ativos');
        if (naoRenderizar && gruposEscala == null && socioComGrupos == null) {
            return new Promise(resolve => {
                resolve(filtrar([], inputValue));
            });
        }
        else if (ls.get('tem_cache_socios_ativos') && moment().diff(ls.get('ultimo_cache_socios_ativos'), 'minutes') < 10 && ls.get('cache_socios_ativos').length > 0) {
            return new Promise(resolve => {
                resolve(filtrar(sociosCache, inputValue));
            });
        }
        else {
            return Promise.resolve(get('/administracao/socioComboAtivos', { pesquisa: '' }, null, null)).then(value => {
                ls.set('tem_cache_socios_ativos', true);
                ls.set('cache_socios_ativos', value);
                ls.set('ultimo_cache_socios_ativos', moment());
                return filtrar(value, inputValue);
            })
        }
    }

    return gruposEscala === null || socioComGrupos === null ? (<LoadingCombo {...props}></LoadingCombo>) : (
        <InputAutocomplete
            isClearable={true}
            cacheOptions={false}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            noOptionsMessage={() => (naoRenderizar ? "Informe a data antes de consultar os sócios." : "Nenhum registro encontrado.")}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => {
                let array = [];
                if (option === null || option.length === 0) {
                    props.propsFormik.setFieldValue(props.name, []);
                }
                else if (option && option.length) {
                    for (var i = 0; i < option.length; i++) {
                        const elemnt = option[i];
                        if (elemnt.socios != null && elemnt.socios.length == 0) {
                            mostrarToast('erro', `Nenhum sócio disponível no grupo '${elemnt.label}' para a data informada.`);
                        }
                        else if (elemnt.socios != null && elemnt.socios.length > 0) {
                            array = array.concat(elemnt.socios)
                            mostrarToast('sucesso', `${elemnt.socios.length} sócios adicionados automaticamente.`);
                        }
                        else {
                            array.push(elemnt)
                        }
                    }
                }

                props.propsFormik.setFieldValue(props.name, array);
                props.onChange && props.onChange(option);
                props.onChangeValue && props.onChangeValue(option)
            }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoOcorrenciaComFerias = (props) => {
    const [itemSelecionado, setItemSelecionado] = useState(null)

    const filtrar = (value, inputValue) => {
        let filtroSigla = value.filter(i => i.sigla === inputValue.toUpperCase().trim());
        if (filtroSigla && filtroSigla.length > 0)
            return filtroSigla;
        else
            return value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    }

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/socio-tipo-ocorrencia-ferias/combo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    return (
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                {...props} />
        </>
    )
}

const InputTipoDataFiltro = (props) => {
    const [data, setData] = useState([{ value: 1, label: 'Interposição' }, { value: 2, label: 'Entre Datas' }, { value: 3, label: 'Data Específica' }])
    const defaultValue = props.colunaFiltros ? props.colunaFiltros.valor : null
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(data).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            defaultOptions={data}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            value={props.propsFormik.values[props.name] || defaultValue}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => {
                props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option);
                props.onChangeValue && props.onChangeValue(option)
            }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputAnoFeriado = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/feriadoAnoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputAnoDireitoFolga = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/direitoFolgaAnoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputMesFeriado = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/feriadoMesCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputMesDireitoFolga = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/direitoFolgaMesCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputUsuario = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/controleAcesso/usuarioCombo', null, null, null)).then(value => filtrar(value, inputValue))

    const defaultValue = props.colunaFiltros ? props.colunaFiltros.valor : null

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            value={props.propsFormik.values[props.name] || props.limparValueCombo ? undefined : defaultValue}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoDireitoFolga = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/administracao/tipoDireitoFolga', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputStatusMarcacao = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/statusMarcacaoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputAnoGozo = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/anoGozoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoAtendimento = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/tipoAtendimentoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoAtendimentoEquipe = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/tipoAtendimentoEquipeCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputIndicacaoAcidente = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/indicacaoAcidenteCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputCoberturaEspecial = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/coberturaEspecialCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputRegimeAtendimento = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/regimeAtendimentoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputSaudeOcupacional = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/saudeOcupacionalCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputCaraterAtendimento = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/caraterAtendimentoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoFaturamento = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/tipoFaturamentoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoInternacao = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/tipoInternacaoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoAtendimentoTiss = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/tipoAtendimentoTissCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoAtendimentoEquipeParticular = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/tipoAtedimentoEquipeCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoConsulta = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/tipoConsultaCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputMotivoEncerramento = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/motivoEncerramentoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputRegimeInternacao = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/regimeInternacaoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputPercentual = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/percentualCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputPercentualAcomodacao = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/percentualAcomodacaoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputStatusContratoParticular = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/contratoParticularStatusCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputVersaoTISS = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/versaoTissCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}
const InputTipoExecutante = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/tipoExecutanteCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            onFocus={(x) => props.propsFormik.setFieldTouched(props.name, true)}
            {...props} />
    )
}
const InputPacote = ({ onChange, ...props }) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/pacoteCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); onChange(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoAtendimentoOdonto = ({ onChange, ...props }) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/tipoAtendimentoOdontologiaCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); onChange && onChange(option); }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputLoteGuia = (props) => {

    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [data, setData] = useState([])

    const action = () => {
        setShowModal(true)
    }

    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/faturamento/loteComboGuia')
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)

                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.operadora_saude + ' - ' + i.label + ' - ' + i.codigo_operadora + ' - ' + i.empresa).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/loteComboGuia', null, null, null)).then(value => {
        let valores = props.propsFormik.values[props.name]
        let dados = value
        if (valores && Array.isArray(valores)) {
            dados = dados.filter(item => valores.filter(item2 => item.value === item2.value).length === 0)
            dados = [...valores, ...dados]
        } else if (valores) {
            dados = dados.filter(item => item.value !== valores.value)
            dados = [valores, ...dados]
        }
        return filtrar(dados, inputValue)
    })

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>{item.referencia ? `Ref: ${item.referencia} ` : ''} {item.empresa ? `| Empresa: ${item.empresa} ` : ''} {item.operadora_saude ? `| Op. Saúde: ${item.operadora_saude} ` : ''}</span>
                        <br />
                    </>
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return data === null ? (<LoadingCombo {...props}></LoadingCombo>) : (props.botaoVisivel ?
        <>
            <InputAutocompleteBtn
                components={{ Option }}
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                action={() => action()}
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => {
                    props.propsFormik.setFieldValue(props.name, option)
                }}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Lote'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroLote modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado} {...props} />
            </ModalPadraoCadastro>
        </> :
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputLoteGuiaFiltro = (props) => {

    // const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const filtrar = (value, inputValue) => value.filter(i => (i.operadora_saude + ' - ' + i.label + ' - ' + i.codigo_operadora + ' - ' + i.empresa).toLowerCase().includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/loteComboGuiaFiltro', null, null, null)).then(value => filtrar(value, inputValue))

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>{item.referencia ? `Ref: ${item.referencia} ` : ''} {item.empresa ? `| Empresa: ${item.empresa} ` : ''} {item.operadora_saude ? `| Op. Saúde: ${item.operadora_saude} ` : ''}</span>
                        <br />
                    </>
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputGuiaStatus = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/guiaStatus', props.status_guia_convenio && { particular: false }, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}
const InputGuiaStatusParticular = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/guiaStatus', { particular: true }, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputGuiaStatusRelatorio = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/guiaStatusRelatorios', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}


const InputStatusEnvio = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/combostatusenvio', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputLoteStatus = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/loteStatus', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputProcedimentosGuiaParticular = (props) => {
    const [data, setData] = useState([])
    const { tipo_honorario, acomodacao, data_procedimento, hora_inicial, hora_final } = props.propsFormik.values
    const [digitando, setDigitando] = useState(null)

    let params = {
        pesquisa: '',
        tipo_honorario_id: tipo_honorario && tipo_honorario.value,
        data_procedimento: data_procedimento,
        acomodacao_id: acomodacao && acomodacao.value,
        local_atendimento_id: acomodacao && acomodacao.local_atendimento_id,
        acomodacao_contrato_id: acomodacao && acomodacao.contrato_id || null,
        idComplementar: props.idGuiaComplementar,
        contrato: props.contrato,
        hora_procedimento: hora_inicial !== '' && hora_final !== '' ? moment(hora_final, 'HH:mm').diff(moment(hora_inicial, 'HH:mm'), 'minutes') : ''
    }

    const funcObter = (inputValue) => {
        params.pesquisa = inputValue
        return get('/faturamento/todosProcedimentosComboGuiaParticular', params, null, null).then(value => value)
    }

    const promiseOptions = inputValue => {
        if (digitando) {
            clearTimeout(digitando);
        }

        return new Promise(resolve => {
            setDigitando(setTimeout(function () {
                resolve(funcObter(inputValue));
            }, 200))
        })
    }

    useEffect(() => {
        if (acomodacao?.value)
            Promise.resolve(get('/faturamento/todosProcedimentosComboGuiaParticular', params, null, null)).then(value => {
                setData(value)
            })
    }, [acomodacao, props.contrato, data_procedimento, tipo_honorario, hora_inicial, hora_final, props.idGuiaComplementar])

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.tabela_descricao ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Tabela: {item.tabela_descricao}</span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => {
                props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option);
                props.onChangeValue && props.onChangeValue(option)
            }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            defaultOptions={data && data.length > 0 ? data : true}
            {...props} />
    )
}

const InputEstabelecimentoSaudeContrato = props => {
    const [data, setData] = useState([])
    const contrato = props.propsFormik.values.estabelecimento_saude

    useEffect(() => {
        if (props.data_procedimento && props.data_procedimento.length > 0) {
            Promise.resolve(get('/administracao/estabelecimentoSaudeContratoCombo', { data_procedimento: props.data_procedimento, pesquisa: '' }, null, null)).then(value => {
                if (contrato && contrato.contrato_id != null) {
                    const index = value && value.findIndex(x => x.value === contrato.value)
                    props.propsFormik.setFieldValue(props.name, index > -1 ? value[index] : undefined)
                }
                setData(value)
            })
        } else {
            setData([])
        }
    }, [props.data_procedimento])

    const filtrar = (value, inputValue) => value.filter(i => (i.codigo_barras + ' - ' + i.label + ' - ' + i.contrato_id + ' - ' + i.numero + ' - ' + i.codigo_subdivisao).toLowerCase().includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => {
        return props.data_procedimento ? Promise.resolve(get('/administracao/estabelecimentoSaudeContratoCombo', { data_procedimento: props.data_procedimento, pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue)) : Promise.resolve([])
    }
    // const promiseOptions = inputValue => Promise.resolve(data).then(value => filtrar(value, inputValue))

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.contrato_id || item.numero ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>{item.contrato_id ? `Código: ${item.contrato_id} |` : ''} N° Contrato: {item.numero + ' | '} Vigência: {item.vigencia}  {item.descricao ? ` | Descrição: ${item.descricao}` : ''}</span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <InputAutocomplete
            components={{ Option }}
            isDisabled={props.disabled}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
            defaultOptions={data && data.length ? data : true}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputPacoteContrato = ({ onChange, ...props }) => {
    const [data, setData] = useState([])

    useEffect(() => {
        if (props.contrato) {
            Promise.resolve(get('/parametros/pacoteContratoCombo', { contrato: props.contrato }, null, null)).then(value => setData(value))
        } else {
            setData([])
        }
    }, [props.contrato])

    const filtrar = (value, inputValue) => value.filter(i => (i.label + ' - ' + i.contrato_id + ' - ').toLowerCase().includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(data).then(value => filtrar(value, inputValue))

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.tabela_descricao ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Tabela: {item.tabela_descricao}</span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); onChange(option) }}
            defaultOptions={data && data.length ? data : true}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}
const InputAcomodacaoContratoParticular = React.forwardRef((props, ref) => {

    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const filtrar = (value, inputValue) => { return value?.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase()))) }
    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/acomodacaoContratoComboParticular', { contrato: props.contrato, tipo_honorario_id: props?.propsFormik?.values?.tipo_honorario?.value }, null, null)).then(value => filtrar(value, inputValue)).catch((e) => console.log('ERRO BUAR ', e))
    const [data, setData] = useState([])

    useEffect(() => {
        Promise.resolve(get('/faturamento/acomodacaoContratoComboParticular', { contrato: props.contrato, tipo_honorario_id: props?.propsFormik?.values?.tipo_honorario?.value, data_procedimento: props?.propsFormik?.values?.data_procedimento }, null, null)).then(value => {
            setData(value)
        })
    }, [props?.propsFormik?.values?.tipo_honorario?.value, props.contrato, props?.propsFormik?.values?.data_procedimento, props?.propsFormik?.values?.estabelecimento_saude])

    const atualizar = () => {

        Promise.resolve(get('/faturamento/acomodacaoContratoComboParticular', { contrato: props.contrato, tipo_honorario_id: props?.propsFormik?.values?.tipo_honorario?.value, data_procedimento: props?.propsFormik?.values?.data_procedimento }, null, null)).then(value => {

            if (props.propsFormik.values[props.name] && value?.findIndex(x => x.value === props.propsFormik.values[props.name].value) === -1) {
                props.aposAtualizar && props.aposAtualizar(undefined)
                props.propsFormik.setFieldValue(props.name, undefined)
            } else {
                props.aposAtualizar && props.aposAtualizar(props.propsFormik.values[props.name])
            }
            setData(value)

        })
    }

    if (ref)
        ref.current = {
            atualizar: atualizar,
            value: props.propsFormik.values[props.name]
        }

    useEffect(() => {
        if (itemSelecionado != null) {
            getApi(setData, '/faturamento/acomodacaoContratoComboParticular')
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    useEffect(() => {

        if (props.setarSeForUm) {
            Promise.resolve(get('/faturamento/acomodacaoContratoComboParticular', { contrato: props.contrato, tipo_honorario_id: props?.propsFormik?.values?.tipo_honorario?.value, data_procedimento: props?.propsFormik?.values?.data_procedimento }, null)).then(value => {
                if (value && value.length == 1) {
                    props.propsFormik.setFieldValue(props.name, value[0])
                }
                else if (!props.id) {
                    props.propsFormik.setFieldValue(props.name, undefined)

                }
            })
        }

    }, [props.setarSeForUm, props.contrato])

    const action = _ => { setShowModal(true) }

    return (
        <>
            <InputAutocompleteBtn
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                action={() => action()}
                isMulti={props.multiplo || false}
                placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
                onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                defaultOptions={data && data.length > 0 ? data : true}
                {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Acomodação'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroAcomodacao modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado} {...props} />
            </ModalPadraoCadastro>
        </>
    )
});

const InputGuia = props => {
    const filtrar = (value, inputValue) => value

    const promiseOptions = inputValue => {
        return Promise.resolve(get('/faturamento/obterGuiaCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))
    }

    const Option = (props) => {
        const item = props.data

        return (
            <div>
                <components.Option {...props}>
                    <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}> {`Cod.: ${item.value}`} {item.codigo_barras ? `Cód. Barras : ${item.codigo_barras} | ` : ''} {`Data Proc.: ${item.data_procedimento} | `}
                        Status: <span style={{ color: item.cor }} >{item.status}</span></span>
                    <br />
                    <span>{`Paciente: ${item.nome_beneficiario}`}</span>
                    <br />
                    <span>{`Op. de Saúde: ${item.operadora_saude}`}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            {...props}
            placeholder={props.placeholder || props.label}
            obrigatorio={props.obrigatorio}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option) }} />
    )
}

const InputEntidade = props => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/movimento-financeiro/entidadeCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))


    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.tipo_cliente ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Cod: {item.value} | Tipo Cliente: {item.tipo_cliente} </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }
    return (
        <>
            <InputAutocompleteBtn
                components={{ Option }}
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                botaoVisivel={props.botaoVisivel}
                type="select"
                isMulti={props.multiplo != undefined ? props.multiplo : false}
                placeholder={props.placeholder} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option) &&
                    (props.functionOnChange && props.functionOnChange(props.propsFormik, option) || '')}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik} {...props} />
        </>
    )
}


const InputTodasCategorias = props => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.conta_principal + ' - ' + i.label + ' - ' + i.value + ' - ' + i.conta_formatada + ' - ').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/movimento-financeiro/todasCategoriasCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)

                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.tipo_lancamento ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Cod: {item.value} Tipo Lanc.: {item.tipo_lancamento} | C. Principal: {item.conta_principal ? item.conta_principal : item.label} </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }


    const action = () => {
        setShowModal(true)
    }

    return (
        <>
            <InputAutocompleteBtn
                components={{ Option }}
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                botaoVisivel={props.botaoVisivel}
                action={() => action()}
                type="select"
                isMulti={props.multiplo != undefined ? props.multiplo : false}
                placeholder={props.placeholder} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}  {...props} />
            <ModalPadraoCadastro titulo={'Cadastro Categoria'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroCategoria modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado}></CadastroCategoria>
            </ModalPadraoCadastro>
        </>
    )
}

const InputFormaReceita = props => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/movimento-financeiro/formaReceitaCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputRetencao = props => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/movimento-financeiro/formaRetencaoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputAtendimentoLancamentoFinanceiro = ({ onChange, ...props }) => {
    const [digitando, setDigitando] = useState(null)

    const funcObter = (inputValue) => {
        return get('/financeiro/atendimentoLancamentoFinanceiro', { pesquisa: inputValue }, null, null).then(value => value)
    }

    const promiseOptions = inputValue => {
        if (digitando) {
            clearTimeout(digitando);
        }

        return new Promise(resolve => {
            setDigitando(setTimeout(function () {
                resolve(funcObter(inputValue));
            }, 200))
        })
    }

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.value ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>{`Cód.: ${item.value} | ${item.data_formatada} | ${item.valor_total_formatado} | `}
                            {item.status && <span style={{ color: item.cor_status }}>{item.status}</span>}</span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); onChange && onChange(option) }}
            loadOptions={promiseOptions}
            setReference={props.setReference ? props.setReference : {}}
            propsFormik={props.propsFormik}
            {...props} />
    )
}


const InputAtendimentoFinanceiro = ({ onChange, ...props }) => {
    const [digitando, setDigitando] = useState(null)

    const funcObter = (inputValue) => {
        return get('/financeiro/atendimentoFinanceiro', { pesquisa: inputValue }, null, null).then(value => value)
    }

    const promiseOptions = inputValue => {
        if (digitando) {
            clearTimeout(digitando);
        }

        return new Promise(resolve => {
            setDigitando(setTimeout(function () {
                resolve(funcObter(inputValue));
            }, 200))
        })
    }

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.value ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>{`Cód.: ${item.value} | ${item.data_formatada} | ${item.valor_total_formatado} | `}
                            {item.status && <span style={{ color: item.cor_status }}>{item.status}</span>}</span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); onChange && onChange(option) }}
            loadOptions={promiseOptions}
            setReference={props.setReference ? props.setReference : {}}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoLancamento = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/tipoLancamento', null, null, null)).then(value => filtrar(value, inputValue))

    useEffect(() => {

        if (props.setarSeForUm) {
            Promise.resolve(get('/parametros/tipoLancamento', null, null)).then(value => {
                if (value.length == 1) {
                    props.propsFormik.setFieldValue(props.name, value[0])
                }
            })
        }

    }, [props.setarSeForUm])

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoLancamentoFinanceiro = ({ onChange, ...props }) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/tipoLancamentoFinanceiro', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); onChange && onChange(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}


const InputTipoDocMovimento = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const action = () => {
        setShowModal(true)
    }

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/tipoDocMovimentoCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <><InputAutocompleteBtn
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            action={() => action()}
            {...props} />
        </>
    )
}

const InputStatusMovimentoFinanceiro = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/movimento-financeiro/inputStatusMovimentoFinanceiro', null, null, null)).then(value => filtrar(value, inputValue))


    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputStatusLancamento = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/lancamentoStatusCombo', null, null, null)).then(value => filtrar(value, inputValue))


    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputStatusCorLancamento = (props) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))
    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/lancamentoCoresCombo', null, null, null)).then(value => filtrar(value, inputValue))

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.MultiValue {...props}>
                    <span>{item.label}</span>
                </components.MultiValue>
            </div>
        )
    }

    const customStyles = {
        control: styles => ({
            ...styles,
            backgroundColor: 'bg-dark-gray',
            borderRadius: 0,
            boxShadow: 'none',
            border: 0,
            fontSize: '12px',
            fontFamily: 'SF-regular',
            height: 45,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? 'black' : isSelected ? '#cccc' : isFocused ? 'gray' : null,
                color: isDisabled ? '#ccc' : isSelected ? 'white' : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
        placeholder: styles => ({
            ...styles,
            fontSize: 19,
            fontFamily: 'SF-regular',
            color: 'white',
        }),
    };

    const colourStyles = {
        multiValue: (styles, { data }) => {
            const color = chroma(data.cor);
            return {
                ...styles,
                backgroundColor: color.alpha(0.1).css(),
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.cor,
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.cor,
            ':hover': {
                backgroundColor: data.cor,
                color: 'white',
            },
        }),
    };


    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            selectStyle={colourStyles}
            {...props} />
    )
}


const InputPlanoContas = (props) => {
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const action = () => {
        setShowModal(true)
    }

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/planoContasCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (<>
        <InputAutocompleteBtn
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            action={() => action()}
            {...props} />
    </>
    )
}

const InputContaBancaria = (props) => {

    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [data, setData] = useState([])

    useEffect(_ => {
        if (props.ordenarPorCliente) {
            Promise.resolve(get('/financeiro/contaBancaria', { pesquisa: '', cliente_id: props.propsFormik.values.entidade?.value }, null, null)).then(value => {
                setData(value)
            })
        }
    }, [props.propsFormik.values.entidade])

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label + ' - ' + i.codigo_banco + ' - ' + i.banco + ' - ' + i.agencia + ' - ' + i.conta).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => {
        return Promise.resolve(get('/financeiro/contaBancaria', { pesquisa: inputValue, cliente_id: props.propsFormik.values.cliente_lancamento?.value }, null, null)).then(value => filtrar(value, inputValue))
    }

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.banco || item.agencia ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}> Banco: {item.codigo_banco + ' - ' + item.banco + ' | '} Agência: {item.agencia + ' | '} Conta: {item.conta} </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    const action = () => {

        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: 119,
            acao_id: ACOES.INSERIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                setShowModal(true)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
                if (props.onChange)
                    props.onChange([...array])
            } else {
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
                if (props.onChange)
                    props.onChange(itemSelecionado)
            }
        }
    }, [itemSelecionado])


    return (
        <>
            <InputAutocompleteBtn
                components={{ Option }}
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                isMulti={props.multiplo || false}
                placeholder={props.placeholder} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                botaoVisivel={props.botaoVisivel}
                defaultOptions={data && data.length > 0 ? data : true}
                action={() => action()}
                {...props}
            />
            <ModalPadraoCadastro titulo={'Cadastro Conta Bancária'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroContaBancaria modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado}></CadastroContaBancaria>
            </ModalPadraoCadastro>
        </>
    )
}

const InputBanco = ({ propsFormik, ...props }) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/combobanco', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder}
            obrigatorio={props.obrigatorio}
            onChange={option => propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={propsFormik}
            {...props} />
    )
}

const InputCategoriaAlternativo = ({ propsFormik, ref, ...props }) => { // TRAZ APENAS utiliza_lancamento_movimento: TRUE

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.conta_principal + ' - ' + i.label + ' - ' + i.value + ' - ' + i.conta_formatada).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/movimento-financeiro/categoriaCombo', { pesquisa: inputValue, tipo_lancamento_id: propsFormik.values?.tipo_lancamento?.value || null }, null, null)).then(value => filtrar(value, inputValue))
    const [data, setData] = useState([])

    const Option = (props) => {
        const item = props.data

        return (
            <div>
                <components.Option {...props}>
                    {item.tipo_lancamento || item.conta_principal ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Cod: {item.value} Tipo Lanc.: {item.tipo_lancamento} | {item.conta_principal ? 'C. Principal: ' + item.conta_principal : ''} </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    useEffect(() => {

        if (props.setarSeForUm) {
            Promise.resolve(get('/movimento-financeiro/categoriaCombo', null, null)).then(value => {
                if (value && value.length == 1) {
                    propsFormik.setFieldValue(props.name, value[0])
                }
            })
        }
        else {
            Promise.resolve(get('/movimento-financeiro/categoriaComboFinanceiro', { pesquisa: '', tipo_lancamento_id: propsFormik.values?.tipo_lancamento?.value || 1 }, null, null)).then(value => {
                setData(value)
            })
        }

    }, [props.setarSeForUm, propsFormik.values?.tipo_lancamento])
    return (
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder}
            obrigatorio={props.obrigatorio}
            onChange={option => propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={propsFormik}
            defaultOptions={data && data.length > 0 ? data : true}
            {...props} />
    )
}

const InputCategoriaFinanceiro = ({ propsFormik, onChange, ref, ...props }) => { //TRAZ TODAS AS CATEGORIAS

    const [data, setData] = useState([])

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.conta_principal + ' - ' + i.label + ' - ' + i.value + ' - ' + i.conta_formatada || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/movimento-financeiro/categoriaComboFinanceiro', { pesquisa: inputValue, tipo_lancamento_id: propsFormik.values?.tipo_lancamento?.value || 1 }, null, null)).then(value => filtrar(value, inputValue)
    )

    useEffect(_ => {
        Promise.resolve(get('/movimento-financeiro/categoriaComboFinanceiro', { pesquisa: '', tipo_lancamento_id: propsFormik.values?.tipo_lancamento?.value || 1 }, null, null)).then(value => {
            setData(value)
        })
    }, [propsFormik.values?.tipo_lancamento])

    const Option = (props) => {
        const item = props.data

        return (
            <div>
                <components.Option {...props}>
                    {item.tipo_lancamento || item.conta_principal ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Cod: {item.value} Tipo Lanc.: {item.tipo_lancamento} | {item.conta_principal ? 'C. Principal: ' + item.conta_principal : ''} </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <InputAutocomplete
            components={{ Option }}
            isDisabled={props.disabled || false}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder}
            obrigatorio={props.obrigatorio}
            onChange={option => { propsFormik.setFieldValue(props.name, option); onChange && onChange(option) }}
            loadOptions={promiseOptions}
            propsFormik={propsFormik}
            defaultOptions={data && data.length > 0 ? data : true}
            {...props} />
    )
}

const InputTipoCliente = ({ propsFormik, ref, ...props }) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/tipoClienteCombo', { pesquisa: inputValue }, null, null)).then(value => { console.log('VALUE ', value); return filtrar(value, inputValue) })


    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder}
            obrigatorio={props.obrigatorio}
            onChange={option => propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={propsFormik}
            {...props} />
    )
}
const InputTipoConta = ({ propsFormik, ...props }) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/combotipoconta', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder}
            obrigatorio={props.obrigatorio}
            onChange={option => propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={propsFormik}
            {...props} />
    )
}

const InputTributoEsfera = ({ propsFormik, ...props }) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/tributoEsferaCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder}
            obrigatorio={props.obrigatorio}
            onChange={option => propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            setReference={props.setReference ? props.setReference : {}}
            propsFormik={propsFormik}
            {...props} />
    )
}

const InputTributoTipo = ({ propsFormik, ...props }) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/tributoTipoCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder}
            obrigatorio={props.obrigatorio}
            onChange={option => propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            setReference={props.setReference ? props.setReference : {}}
            propsFormik={propsFormik}
            {...props} />
    )
}



const InputPeriodicidade = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/periodicidadeCombo', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isDisabled={props.disabled}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputModoLiquidacao = ({ onChange, ...props }) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/modoLiquidacao', null, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isDisabled={props.disabled}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); onChange && onChange(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputParcela = (props) => {

    // const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    // const promiseOptions = inputValue => Promise.resolve(get('/faturamento/loteStatus', null, null, null)).then(value => filtrar(value, inputValue))

    // return (
    //     <InputAutocomplete
    //         isClearable={true}
    //         tamanho={props.tamanho}
    //         label={props.label}
    //         name={props.name}
    //         type="select"
    //         isMulti={props.multiplo || false}
    //         placeholder={props.placeholder} obrigatorio={props.obrigatorio}
    //         onChange={option => props.propsFormik.setFieldValue(props.name, option)}
    //         loadOptions={promiseOptions}
    //         propsFormik={props.propsFormik}
    //         {...props} />
    // )

    const data = [{ value: '2', label: '2' },
    { value: '3', label: '3' }, { value: '4', label: '4' }]

    const filtrar = inputValue =>
        data && data.filter(i =>
            removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase()))
        ).slice(0, 10);

    const loadOptions = (inputValue, callback) => callback(filtrar(inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isDisabled={props.disabled}

            isMulti={props.multiplo || false}
            obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); props.onChangeValue && props.onChangeValue(option) }}
            loadOptions={loadOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputClienteFinanceiro = props => {
    const user = getUsuario()
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [digitando, setDigitando] = useState(null)
    const additionalValues = props?.additionalValues

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])

    const funcObter = (inputValue) => {
        return get('/financeiro/clienteCombo', { pesquisa: inputValue, ...user }, null, null).then(value => value)
    }

    const promiseOptions = inputValue => {
        if (digitando) {
            clearTimeout(digitando);
        }

        return new Promise(resolve => {
            setDigitando(setTimeout(function () {
                resolve(funcObter(inputValue));
            }, 200))
        })
    }

    const action = () => {
        setShowModal(true)
    }

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>
                            {`Cód.: ${props.data.value} |`}
                        </span>
                        <b style={{ fontSize: 11, color: props.isSelected ? '#fff' : '#555' }}>{props.data.tipo_cliente}</b>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>
                            {`| ${props.data.identificacao || ''}${props.data.registro_conselho ? ' | ' + props.data.registro_conselho : ''}`}
                        </span>
                        <br />
                        <span>{props.data.label}</span>
                    </>
                </components.Option>
            </div>
        )
    }

    const SingleValue = ({
        children,
        ...props
    }) => {
        const item = props.data
        return (
            <components.SingleValue {...props}>
                <div>
                    <span> {item.label}</span> <br />
                    <span> <b>{item[additionalValues]}</b></span>
                </div>
            </components.SingleValue>
        )
    };
    return (<>
        <InputAutocompleteBtn
            components={{ Option, SingleValue }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isDisabled={props.disabled}
            action={() => action()}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
        <ModalPadraoCadastro titulo={'Cadastro Cliente'} showModal={showModal} setShowModal={setShowModal}>
            <CadastroCliente modal setShow={setShowModal} setItemSelecionado={setItemSelecionado}></CadastroCliente>
        </ModalPadraoCadastro>
    </>)
}


const InputPorTipoClienteFinanceiro = props => {
    const user = getUsuario()
    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [digitando, setDigitando] = useState(null)
    const additionalValues = props?.additionalValues
    const [data, setData] = useState([])

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
            } else
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
        }
    }, [itemSelecionado])


    useEffect(_ => {
        Promise.resolve(get('/financeiro/clientePorTipoCombo', { pesquisa: '', ...user, conta_empresa: props.conta_empresa }, null, null)).then(value => {
            setData(value)
        })

    }, [props.conta_empresa])

    const funcObter = (inputValue) => {
        return get('/financeiro/clientePorTipoCombo', { pesquisa: inputValue, ...user, conta_empresa: props.conta_empresa }, null, null).then(value => value)
    }

    const promiseOptions = inputValue => {
        if (digitando) {
            clearTimeout(digitando);
        }

        return new Promise(resolve => {
            setDigitando(setTimeout(function () {
                resolve(funcObter(inputValue));
            }, 200))
        })
    }

    const action = () => {
        setShowModal(true)
    }

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>
                            {`Cód.: ${props.data.value} |`}
                        </span>
                        <b style={{ fontSize: 11, color: props.isSelected ? '#fff' : '#555' }}>{props.data.tipo_cliente}</b>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>
                            {`| ${props.data.identificacao || ''}${props.data.registro_conselho ? ' | ' + props.data.registro_conselho : ''}`}
                        </span>
                        <br />
                        <span>{props.data.label}</span>
                    </>
                </components.Option>
            </div>
        )
    }

    const SingleValue = ({
        children,
        ...props
    }) => {
        const item = props.data
        return (
            <components.SingleValue {...props}>
                <div>
                    <span> {item.label}</span> <br />
                    <span> <b>{item[additionalValues]}</b></span>
                </div>
            </components.SingleValue>
        )
    };
    return (<>
        <InputAutocompleteBtn
            components={{ Option, SingleValue }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isDisabled={props.disabled}
            action={() => action()}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder || props.label} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            defaultOptions={data && data.length > 0 ? data : true}
            propsFormik={props.propsFormik}
            {...props} />
        <ModalPadraoCadastro titulo={'Cadastro Cliente'} showModal={showModal} setShowModal={setShowModal}>
            <CadastroCliente modal setShow={setShowModal} setItemSelecionado={setItemSelecionado}></CadastroCliente>
        </ModalPadraoCadastro>
    </>)
}

const InputMovimentoFinanceiro = (props) => {
    const filtrar = (value) => value

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/movimentoFinanceiroCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.value ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Cod: {item.value} | Categoria: {item.categoria} | <br />Descrição: {item.descricao}  </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }


    return (
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}
const InputMovimentoFinanceiroLiquidacao = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label + ' - ' + i.value + ' - ' + i.categoria + ' - ' + i.stauts + ' - ' + i.valor_formatado + ' - ' + i.descricao + ' - ' + i.data_formatada).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/movimentoFinanceiroComboLiquidacao', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    const styles_movimento = {
        fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555'
    }

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.value ? <>
                        <span style={styles_movimento}>Cod: {item.value} | Categoria: {item.categoria} | <br />Descrição: {item.descricao} Status: {item.status} |
                            <br /> Valor: {item.valor_formatado} | Data: {item.data_formatada}
                        </span>
                        <br />
                    </>
                        : <></>}
                    <span style={{ display: 'flex', marginTop: 2 }}>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    const SingleValue = ({
        children,
        ...props
    }) => {
        const item = props.data
        return (
            <components.SingleValue {...props}>
                <div>
                    <span style={styles_movimento} ><b>Cod:</b> {item.value} | <b>Categoria:</b> {item.categoria}</span> <br />
                    <span style={styles_movimento}><b>Descrição:</b> {item.descricao} | <b>Status:</b> {item.status}</span><br />
                    <span style={styles_movimento}><b>Valor:</b> {item.valor_formatado} | <b>Data:</b> {item.data_formatada}</span><br />
                    <b><span style={{ display: 'flex', marginTop: 2 }}>{item.label}</span></b>
                </div>
            </components.SingleValue>
        )
    };

    const colourStyles = {
        control: (styles, { data }) => ({
            ...styles,
            height: '80px !important'
        }),
        valueContainer: (styles, { data }) => ({
            ...styles,
            height: '80px !important'
        }),
        singleValue: (styles, { data }) => {
            return {
                ...styles,
                display: 'unset',
                maxWidth: "unset"
            };
        },

    };

    // const styleHeight = "74px";
    // const customStyles = {
    //     valueContainer: base => ({
    //         ...base,
    //         minHeight: styleHeight,
    //         fontSize: 10, fontWeight: '500'
    //     })
    // };


    return (
        <InputAutocomplete
            className="single-select"
            classNamePrefix="react-select"
            components={{ Option, SingleValue }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); props.onChange && props.onChange(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            selectStyle={colourStyles}

            {...props} />
    )
}

const InputLancamentoSemMovimento = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.value + ' - ' + i.atendimento + ' - ' + i.label + ' - ' + i.data_liquidacao + ' - ' + i.valor_liquidado).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/lancamentoSemMovimentoCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    const styles_movimento = {
        // fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555'
    }

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.value ? <>
                        <span style={styles_movimento}>Cod: {item.value} {item.atendimento ? '| Atend.:' + item.atendimento : ''} | Data Liq.: {item.data_liquidacao} | Valor Liquidado: <span className={props.isSelected ? '' : 'valor-indicador-certo'}>{item.valor_liquidado}</span>
                        </span>
                        <br />
                    </>
                        : <></>}
                    <span style={{ display: 'flex', marginTop: 2 }}>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    const SingleValue = ({
        children,
        ...props
    }) => {
        const item = props.data
        return (
            <components.SingleValue {...props}>
                <div>
                    <span style={styles_movimento}>Cod: {item.value} | Data Liq.: {item.data_liquidacao}</span> < br />
                    <span style={styles_movimento}>Valor Liquidado: <span className={props.isSelected ? '' : 'valor-indicador-certo'}>{item.valor_liquidado}</span></span><br />
                    <b><span style={{ display: 'flex', marginTop: 2 }}>{item.label}</span></b>
                </div>
            </components.SingleValue>
        )
    };

    const colourStyles = {
        control: (styles, { data }) => ({
            ...styles,
            height: '80px !important'
        }),
        valueContainer: (styles, { data }) => ({
            ...styles,
            height: '80px !important'
        }),
        singleValue: (styles, { data }) => {
            return {
                ...styles,
                display: 'unset',
                maxWidth: "unset"
            };
        },

    };

    return (
        <InputAutocomplete
            className="single-select"
            classNamePrefix="react-select"
            components={{ Option, SingleValue }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            selectStyle={colourStyles}

            {...props} />
    )
}
const InputLancamentoFinanceiro = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.value + ' - ' + i.atendimento + ' - ' + i.label + ' - ' + i.data_liquidacao + ' - ' + i.valor_liquidado).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/lancamentoFinanceiroCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    const styles_movimento = {
        // fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555'
    }

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.value ? <>
                        <span style={styles_movimento}>Cod: {item.value} {item.atendimento ? '| Atend.:' + item.atendimento : ''} | Data Liq.: {item.data_liquidacao} | Valor Liquidado: <span className={props.isSelected ? '' : 'valor-indicador-certo'}>{item.valor_liquidado}</span>
                        </span>
                        <br />
                    </>
                        : <></>}
                    <span style={{ display: 'flex', marginTop: 2 }}>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    const SingleValue = ({
        children,
        ...props
    }) => {
        const item = props.data
        return (
            <components.SingleValue {...props}>
                <div>
                    <span style={styles_movimento}>Cod: {item.value} | Data Liq.: {item.data_liquidacao}</span> < br />
                    <span style={styles_movimento}>Valor Liquidado: <span className={props.isSelected ? '' : 'valor-indicador-certo'}>{item.valor_liquidado}</span></span><br />
                    <b><span style={{ display: 'flex', marginTop: 2 }}>{item.label}</span></b>
                </div>
            </components.SingleValue>
        )
    };

    const colourStyles = {
        control: (styles, { data }) => ({
            ...styles,
            height: '80px !important'
        }),
        valueContainer: (styles, { data }) => ({
            ...styles,
            height: '80px !important'
        }),
        singleValue: (styles, { data }) => {
            return {
                ...styles,
                display: 'unset',
                maxWidth: "unset"
            };
        },

    };

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            components={{ Option, SingleValue }}
            name={props.name}
            type="select"
            isDisabled={props.disabled}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            selectStyle={colourStyles}
            {...props} />
    )
}
const InputLancamentoFinanceiroPorCliente = (props) => {

    const [data, setData] = useState([])

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.value + ' - ' + i.atendimento + ' - ' + i.label + ' - ' + i.data_liquidacao + ' - ' + i.valor_liquidado).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/lancamentoFinanceiroCombo', { pesquisa: inputValue, cliente_id: props.propsFormik.values.cliente?.value || 0 }, null, null)).then(value => filtrar(value, inputValue))


    useEffect(_ => {
        Promise.resolve(get('/financeiro/lancamentoFinanceiroCombo', { pesquisa: '', cliente_id: props.propsFormik.values.cliente?.value || 0 }, null, null)).then(value => {
            setData(value)
        })

    }, [props.propsFormik.values.cliente])
    const styles_movimento = {
        // fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555'
    }

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.value ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Cod: {item.value} {item.atendimento ? '| Atend.:' + item.atendimento : ''} | Data Liq.: {item.data_liquidacao} | Valor Liquidado: <span className={props.isSelected ? '' : 'valor-indicador-certo'}>{item.valor_liquidado}</span>
                        </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    const SingleValue = ({
        children,
        ...props
    }) => {
        const item = props.data
        return (
            <components.SingleValue {...props}>
                <div>
                    <span>Valor Liquidado: <span className={props.isSelected ? '' : 'valor-indicador-certo'}>{item.valor_liquidado}</span></span><br />
                    <b><span>{item.label}</span></b>
                </div>
            </components.SingleValue>
        )
    };

    const colourStyles = {
        control: (styles, { data }) => ({
            ...styles,
            height: '80px !important'
        }),
        valueContainer: (styles, { data }) => ({
            ...styles,
            height: '80px !important'
        }),
        singleValue: (styles, { data }) => {
            return {
                ...styles,
                display: 'unset',
                maxWidth: "unset"
            };
        },

    };

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            components={{ Option, SingleValue }}
            name={props.name}
            type="select"
            isDisabled={props.disabled}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            defaultOptions={data && data.length > 0 ? data : true}

            // selectStyle={colourStyles}
            {...props} />
    )
}

const InputMunicipioGOPrimeiro = ({ onChange, ...props }) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/parametros/municipioGOPrimeiroCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isDisabled={props.disabled}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); onChange && onChange(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}
const InputSituacaoTributo = ({ onChange, ...props }) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/situacaoTributoCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isDisabled={props.disabled}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); onChange && onChange(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}
const InputRecolhimentoTributo = ({ onChange, ...props }) => {
    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/recolhimentoTributoCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isDisabled={props.disabled}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); onChange && onChange(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}
const InputTributo = ({ onChange, ...props }) => {

    const [data, setData] = useState([])

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/tributoCombo', { pesquisa: inputValue, municipio: props?.municipio?.value }, null, null)).then(value => filtrar(value, inputValue))


    useEffect(_ => {
        Promise.resolve(get('/financeiro/tributoCombo', { pesquisa: '', municipio: props?.municipio?.value }, null, null)).then(value => {
            setData(value)
        })
    }, [props?.municipio])
    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {<>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>{`Cód.: ${item.cod} | ${item.tipo_tributo} | ${item.tributo_esfera || ''}${item.aliquota ? ' | ' + item.aliquota : ''} | Cat.: ${item?.categoria} `}</span>
                        <br />
                    </>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    return (
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isDisabled={props.disabled}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); onChange && onChange(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputChequeDetalhado = (props) => {

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label + ' - ' + i.value + ' - ' + i.categoria + ' - ' + i.stauts + ' - ' + i.valor_formatado + ' - ' + i.descricao + ' - ' + i.data_formatada).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/chequeCombo', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.id ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Cod: {item.id} | Conta: {item.conta_formatada} | Agência: {item.agencia_formatada}
                            <br /> Valor: {item.valor_total_formatado} | Data Emissão: {item.data_emissao} </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    const styles_movimento = {
        fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555'
    }

    const MultiValueContainer = ({
        children,
        ...props
    }) => {
        const item = props.data
        return (
            <components.MultiValueContainer {...props}>
                <div>
                    <span style={styles_movimento}><b>Cod:</b> {item.value} | <b>Agencia:</b> {item.agencia_formatada}</span> <br />
                    <span style={styles_movimento}><b>Conta:</b> {item.conta_formatada} | <b>Número:</b> {item.numero}</span><br />
                    <span style={styles_movimento}><b>Valor:</b> {item.valor_total_formatado} | <b>Data:</b> {item.data_emissao} </span><br />
                    <b><span style={{ display: 'flex', marginTop: 2 }}>{item.label}</span></b>
                </div>
            </components.MultiValueContainer>
        )
    };

    const colourStyles = {
        control: (styles, { data }) => ({
            ...styles,
            height: '90px !important'
        }),
        valueContainer: (styles, { data }) => ({
            ...styles,
            height: '90px !important'
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                display: 'unset',
                maxWidth: "unset"
            };
        },

    };


    return (
        <InputAutocomplete
            components={{ Option, MultiValueContainer }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            selectStyle={colourStyles}
            {...props} />
    )
}

const InputLoteGuiaAcao = (props) => {

    // const filtrar = (value) => value

    // const promiseOptions = inputValue => Promise.resolve(get('/faturamento/obterComboLoteGuiaAcao', { pesquisa: inputValue, operadora_saude_id: props.operadora_saude_id }, null, null)).then(value => filtrar(value, inputValue))

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label + '-' + i.value + '-' + i.operadora_saude).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/faturamento/obterComboLoteGuiaAcao', { pesquisa: inputValue, operadora_saude_id: props.guia.operadora_saude_id, empresa_id: props.guia.empresa_id, contrato_id: props.guia.contrato_id }, null, null)).then(value => {

        let valores = props.propsFormik.values[props.name]

        let dados = value

        if (valores && Array.isArray(valores)) {
            dados = dados.filter(item => valores.filter(item2 => item.value === item2.value).length === 0)
            dados = [...valores, ...dados]
        } else if (valores) {
            dados = dados.filter(item => item.value !== valores.value)
            dados = [valores, ...dados]
        }

        return filtrar(dados, inputValue)
    })

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Cod.: {item.value} | {item.referencia ? `Ref: ${item.referencia} ` : ''} {item.empresa ? `| Empresa: ${item.empresa} ` : ''} {item.operadora_saude ? `| Op. Saúde: ${item.operadora_saude} ` : ''}
                            | {item.status && <span style={{ color: item.cor_status }}>{item.status}</span>}</span>
                        <br />
                    </>
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }


    return (
        <InputAutocomplete
            components={{ Option }}
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTodosLancamentoFinanceiroPorCliente = (props) => {

    const [data, setData] = useState([])

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.value + ' - ' + i.atendimento + ' - ' + i.label + ' - ' + i.data_liquidacao + ' - ' + i.valor_liquidado).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/lancamentoFinanceiroTodosCombo', { pesquisa: inputValue, cliente_id: props.propsFormik.values.cliente?.value || 0, valor_setado: props.valor_setado.filter(x => x) }, null, null)).then(value => filtrar(value, inputValue))


    useEffect(_ => {
        Promise.resolve(get('/financeiro/lancamentoFinanceiroTodosCombo', { pesquisa: '', cliente_id: props.propsFormik.values.cliente?.value || 0, valor_setado: props.valor_setado.filter(x => x) }, null, null)).then(value => {
            setData(value)
        })

    }, [props.propsFormik.values.cliente])

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.value ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}>Cod: {item.value} Tipo: <b>{item.lancamento_tipo}</b> {item.atendimento ? '| Atend.:' + item.atendimento : ''} | Data Liq.: {item.data_liquidacao} | Valor Liquidado: <span className={props.isSelected ? '' : 'valor-indicador-certo'}>{item.valor_liquidado}</span>
                        </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    const SingleValue = ({
        children,
        ...props
    }) => {
        const item = props.data
        return (
            <components.SingleValue {...props}>
                <div>
                    <span>Valor Liquidado: <span className={props.isSelected ? '' : 'valor-indicador-certo'}>{item.valor_liquidado}</span></span><br />
                    <b><span>{item.label + '(' + item.value + ')'}</span></b>
                </div>
            </components.SingleValue>
        )
    };

    const colourStyles = {
        control: (styles, { data }) => ({
            ...styles,
            height: '80px !important'
        }),
        valueContainer: (styles, { data }) => ({
            ...styles,
            height: '80px !important'
        }),
        singleValue: (styles, { data }) => {
            return {
                ...styles,
                display: 'unset',
                maxWidth: "unset"
            };
        },

    };

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            components={{ Option, SingleValue }}
            name={props.name}
            type="select"
            isDisabled={props.disabled}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => props.onChange || props.propsFormik.setFieldValue(props.name, option)}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            defaultOptions={data && data.length > 0 ? data : true}

            // selectStyle={colourStyles}
            {...props} />
    )
}

const InputTipoGuiaPagamento = (props) => {
    const data = [{ value: 'guia', label: 'Guia' }, { value: 'recurso', label: 'Recurso' }, { value: 'ambos', label: 'Guia/Recurso' }]

    const filtrar = inputValue =>
        data && data.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase()))
        ).slice(0, 10);

    const loadOptions = (inputValue, callback) => callback(filtrar(inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); props.onChangeValue && props.onChangeValue(option) }}
            loadOptions={loadOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

// const InputEmpresaCliente = (props) => {
//     const [showModal, setShowModal] = useState(false)
//     const [itemSelecionado, setItemSelecionado] = useState(null)
//     const [data, setData] = useState([])

//     const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

//     const promiseOptions = inputValue => Promise.resolve(get('/financeiro/empresaCliente/combo', null, null, null)).then(value => filtrar(value, inputValue))

//     // useEffect(() => {
//     //     console.log('itemSelecionado: ', itemSelecionado);
//     //     if (itemSelecionado != null) {
//     //         getApi(setData, '/financeiro/empresaCliente/combo')
//     //         if (props.multiplo) {
//     //             const array = props.propsFormik.values[props.name] || []
//     //             array.push(itemSelecionado)

//     //             props.propsFormik.setFieldValue(props.name, [...array])
//     //         } else
//     //             console.log('itemSelecionado: ', itemSelecionado);
//     //         props.propsFormik.setFieldValue(props.name, itemSelecionado)
//     //     }
//     // }, [itemSelecionado])


//     const action = () => {
//         setShowModal(true)
//     }

//     return (
//         <>
//             <InputAutocompleteBtn
//                 isClearable={true}
//                 tamanho={props.tamanho}
//                 label={props.label}
//                 name={props.name}
//                 botaoVisivel={props.botaoVisivel}
//                 action={() => action()}
//                 type="select"
//                 isMulti={props.multiplo != undefined ? props.multiplo : false}
//                 placeholder={props.placeholder} obrigatorio={props.obrigatorio}
//                 onChange={option => props.onChange && props.onChange || props.propsFormik.setFieldValue(props.name, option)}
//                 loadOptions={promiseOptions}
//                 propsFormik={props.propsFormik} {...props} />
//             <ModalPadraoCadastro titulo={'Cadastro Empresa'} showModal={showModal} setShowModal={setShowModal}>
//                 <CadastroEmpresa modal="true" setShow={setShowModal} setItemSelecionado={setItemSelecionado} />
//             </ModalPadraoCadastro>
//         </>
//     )
// }

const InputEmpresaCliente = ({ onChange, ...props }) => {
    // const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => Promise.resolve(get('/financeiro/empresaCliente/combo', { pesquisa: inputValue }, null, null)).then(value => { console.log('VALUE', value); return value })

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isDisabled={props.disabled}
            isMulti={props.multiplo || false}
            placeholder={props.placeholder} obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); onChange && onChange(option) }}
            loadOptions={promiseOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}
const InputContaBancariaEmpresa = (props) => {

    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [data, setData] = useState([])

    useEffect(_ => {
        Promise.resolve(get('/financeiro/contaBancariaEmpresa', { pesquisa: '', cliente_id: props.propsFormik.values.empresa?.id_cliente }, null, null)).then(value => {
            setData(value)
        })
    }, [props.propsFormik.values.empresa])

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label + ' - ' + i.codigo_banco + ' - ' + i.banco + ' - ' + i.agencia + ' - ' + i.conta).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => {
        return Promise.resolve(get('/financeiro/contaBancariaEmpresa', { pesquisa: inputValue, cliente_id: props.propsFormik.values.empresa?.id_cliente }, null, null)).then(value => filtrar(value, inputValue))
    }

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.banco || item.agencia ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}> Banco: {item.codigo_banco + ' - ' + item.banco + ' | '} Agência: {item.agencia + ' | '} Conta: {item.conta} </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    const action = () => {

        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: 119,
            acao_id: ACOES.INSERIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                setShowModal(true)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
                if (props.onChange)
                    props.onChange([...array])
            } else {
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
                if (props.onChange)
                    props.onChange(itemSelecionado)
            }
        }
    }, [itemSelecionado])


    return (
        <>
            <InputAutocompleteBtn
                components={{ Option }}
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                isMulti={props.multiplo || false}
                placeholder={props.placeholder} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                botaoVisivel={props.botaoVisivel}
                defaultOptions={data && data.length > 0 ? data : true}
                action={() => action()}
                {...props}
            />
            <ModalPadraoCadastro titulo={'Cadastro Conta Bancária'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroContaBancaria modal="true" cliente_id={props.propsFormik.values?.empresa ?
                    { value: props.propsFormik.values?.empresa?.id_cliente, label: props.propsFormik.values?.empresa?.label } : null} setShow={setShowModal} setItemSelecionado={setItemSelecionado}></CadastroContaBancaria>
            </ModalPadraoCadastro>
        </>
    )
}

const InputContaBancariaCliente = (props) => {

    const [showModal, setShowModal] = useState(false)
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [data, setData] = useState([])

    useEffect(_ => {
        Promise.resolve(get('/financeiro/contaBancariaCliente', {
            pesquisa: '', cliente_id: props.propsFormik.values.cliente_lancamento?.value ||
                props.propsFormik.values.entidade?.value
        }, null, null)).then(value => {
            setData(value)
        })
    }, [props.propsFormik.values.cliente_lancamento || props.propsFormik.values.entidade])

    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label + ' - ' + i.codigo_banco + ' - ' + i.banco + ' - ' + i.agencia + ' - ' + i.conta).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => {
        return Promise.resolve(get('/financeiro/contaBancariaCliente', { pesquisa: inputValue, cliente_id: props.propsFormik.values.cliente_lancamento?.value || props.propsFormik.values.entidade?.value }, null, null)).then(value => filtrar(value, inputValue))
    }

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.banco || item.agencia ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}> Banco: {item.codigo_banco + ' - ' + item.banco + ' | '} Agência: {item.agencia + ' | '} Conta: {item.conta} </span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    const action = () => {

        const objPermissao = {
            usuario_id: getUsuario().id,
            modulo_id: 119,
            acao_id: ACOES.INSERIR
        }

        get('/controleAcesso/permissao', objPermissao).then(function (val) {
            if (val.length)
                setShowModal(true)
            else
                mostrarToast('erro', mensagens['permissao'])

        }).catch((e) => mostrarToast('erro', mensagens['erro']))
    }

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
                if (props.onChange)
                    props.onChange([...array])
            } else {
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
                if (props.onChange)
                    props.onChange(itemSelecionado)
            }
        }
    }, [itemSelecionado])


    return (
        <>
            <InputAutocompleteBtn
                components={{ Option }}
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                isMulti={props.multiplo || false}
                placeholder={props.placeholder} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                botaoVisivel={props.botaoVisivel}
                defaultOptions={data && data.length > 0 ? data : true}
                action={() => action()}
                {...props}
            />
            <ModalPadraoCadastro titulo={'Cadastro Conta Bancária'} showModal={showModal} setShowModal={setShowModal}>
                <CadastroContaBancaria modal="true" setShow={setShowModal} cliente_id={props.propsFormik.values?.cliente_lancamento || props.propsFormik.values.entidade || null} setItemSelecionado={setItemSelecionado}></CadastroContaBancaria>
            </ModalPadraoCadastro>
        </>
    )
}


const InputContasBancariaEmpresa = (props) => {
    const [itemSelecionado, setItemSelecionado] = useState(null)
    const [data, setData] = useState([])


    const filtrar = (value, inputValue) => value.filter(i => removeAcentos((i.label + ' - ' + i.codigo_banco + ' - ' + i.banco + ' - ' + i.agencia + ' - ' + i.conta).toLowerCase()).includes(removeAcentos(inputValue.toLowerCase())))

    const promiseOptions = inputValue => {
        return Promise.resolve(get('/financeiro/contasBancariaEmpresaFiltro', { pesquisa: inputValue }, null, null)).then(value => filtrar(value, inputValue))
    }

    const Option = (props) => {
        const item = props.data
        return (
            <div>
                <components.Option {...props}>
                    {item.banco || item.agencia ? <>
                        <span style={{ fontSize: 11, fontWeight: '500', color: props.isSelected ? '#fff' : '#555' }}> Banco: {item.codigo_banco + ' - ' + item.banco + ' | '} Agência: {item.agencia + ' | '} Conta: {item.conta} | Empresa: {item.empresa}</span>
                        <br />
                    </>
                        : <></>}
                    <span>{item.label}</span>
                </components.Option>
            </div>
        )
    }

    useEffect(() => {
        if (itemSelecionado != null) {
            if (props.multiplo) {
                const array = props.propsFormik.values[props.name] || []
                array.push(itemSelecionado)
                props.propsFormik.setFieldValue(props.name, [...array])
                if (props.onChange)
                    props.onChange([...array])
            } else {
                props.propsFormik.setFieldValue(props.name, itemSelecionado)
                if (props.onChange)
                    props.onChange(itemSelecionado)
            }
        }
    }, [itemSelecionado])


    return (
        <>
            <InputAutocompleteBtn
                components={{ Option }}
                isClearable={true}
                tamanho={props.tamanho}
                label={props.label}
                name={props.name}
                type="select"
                isMulti={props.multiplo || false}
                placeholder={props.placeholder} obrigatorio={props.obrigatorio}
                onChange={option => props.propsFormik.setFieldValue(props.name, option)}
                loadOptions={promiseOptions}
                propsFormik={props.propsFormik}
                botaoVisivel={props.botaoVisivel}
                defaultOptions={data && data.length > 0 ? data : true}
                {...props}
            />
        </>
    )
}

const InputTipoGuiaRecurso = (props) => {
    const data = [{ value: 'guia', label: 'Guia' },
    { value: 'recurso', label: 'Recurso' },
    { value: 'ambos', label: 'Guia/Recurso' }]

    const filtrar = inputValue =>
        data && data.filter(i =>
            removeAcentos((i.label || '').toLowerCase()).includes(removeAcentos(inputValue.toLowerCase()))
        ).slice(0, 10);

    const loadOptions = (inputValue, callback) => callback(filtrar(inputValue))

    return (
        <InputAutocomplete
            isClearable={true}
            tamanho={props.tamanho}
            label={props.label}
            name={props.name}
            type="select"
            isMulti={props.multiplo || false}
            obrigatorio={props.obrigatorio}
            onChange={option => { props.propsFormik.setFieldValue(props.name, option); props.onChangeValue && props.onChangeValue(option) }}
            loadOptions={loadOptions}
            propsFormik={props.propsFormik}
            {...props} />
    )
}

const InputTipoRelatorioPersonalizado = props => {

    const objPermissao = {
        usuario_id: getUsuario().login,
        modulo_id: props.modulos,
        acao_id: ACOES.CONSULTAR
    }

    const promiseOptions = _ => Promise.resolve(get('/relatorio/comboComPermissao', objPermissao, null, null)).then(value => value)

    return (<><InputAutocompleteBtn
        isClearable={true}
        tamanho={props.tamanho}
        label={props.label}
        name={props.name}
        botaoVisivel={props.botaoVisivel}
        type="select"
        isMulti={props.multiplo != undefined ? props.multiplo : false}
        placeholder={props.placeholder} obrigatorio={props.obrigatorio}
        onChange={option => { props.propsFormik.setFieldValue(props.name, option); option && props.setRelatorioSelecionado(option) }}
        loadOptions={promiseOptions}
        propsFormik={props.propsFormik} {...props} />
    </>)

}


export {
    InputEstado, InputSexo,
    InputEstabelecimentoSaude, InputCBOS,
    InputMunicipio, InputTipoEndereco,
    InputSimNao, InputTipoDocumento,
    InputPais, InputParentesco,
    InputEstadoCivil, InputTipoEstabelecimento,
    InputGrupo, InputEspecialidadeMedica,
    InputRaca, InputSocio,
    InputTipoEscalador, InputTipoUsuario,
    InputEmpresa, InputDepartamento,
    InputTipoTabelaProcedimento, InputCirurgiao,
    InputGrupoEmpresa, InputOperadoraSaude,
    InputTipoOcorrencia, InputContratoStatus,
    InputTabelaProcedimento, InputContratoBaseCalculo,
    InputEscalador, InputPeriodo,
    InputUsuario, InputAcomodacao,
    InputTipoRelatorio, InputTipoGuia,
    InputPorArray, InputPorteProcedimento,
    InputBairro, InputOrdenamento, InputIntervaloHorario,
    InputBeneficiario, InputProcedimento, InputOperadoraSaudePorContrato,
    InputContrato, InputUsuarioSocio, InputSocioAtivos,
    InputSocioStatus, InputCro, InputFiltroEstabelecimentoSaude,
    InputAcoes, InputModulo, InputPorArrayBtn, InputCodigoProcedimento, InputUsuarioApp,
    InputPorteAnestesico, InputUsuarioAuditoria, InputSocioEmpresa, InputStatusAgendamento, InputPeriodoGozo, InputMesDireitoFolga, InputAnoDireitoFolga,
    InputEscalaTipo, InputRecorrencio, InputStatusAgendamentoPlantao, InputPeriodoFerias, InputMercadoTipo, InputSocioAtivosComConselho, InputAnoFiltro,
    InputTodosProcedimentos, InputSocioAtivosComCores, InputSocioAtivosComGrupos, InputTipoOcorrenciaComFerias, InputTipoDataFiltro, InputAnoFeriado, InputMesFeriado, InputTipoDireitoFolga,
    InputStatusMarcacao, InputAnoGozo, InputTipoAtendimento, InputIndicacaoAcidente, InputCoberturaEspecial, InputRegimeAtendimento, InputSaudeOcupacional,
    InputCaraterAtendimento, InputTipoFaturamento, InputTipoInternacao, InputTipoAtendimentoTiss, InputTipoConsulta, InputMotivoEncerramento, InputRegimeInternacao,
    InputPercentual, InputTipoAtendimentoEquipe, InputPercentualAcomodacao, InputStatusContratoParticular, InputVersaoTISS, InputTipoExecutante, InputProcedimentoCompleto, InputProcedimentoCodigo, InputPacote,
    InputTipoAtendimentoOdonto, InputTipoAtendimentoEquipeParticular, InputAcomodacaoContrato, InputProcedimentosContrato, InputSocioGuia, InputEmpresaGuia, InputGuiaStatus, InputLoteGuia, InputAnoAnestesiaOdonto,
    InputLoteGuiaFiltro, InputLoteStatus, InputProcedimentosGuiaParticular, InputEstabelecimentoSaudeContrato, InputPacoteContrato, InputAcomodacaoContratoParticular, InputProcedimentosTabela,
    InputContratoFiltro, InputContratoFiltroParticular, InputGuia, InputBeneficiarioGuia, InputStatusEnvio, InputGuiaStatusParticular, InputCirurgiaoCombo, InputEntidade, InputFormaReceita, InputRetencao,
    InputContaBancaria, InputAtendimentoFinanceiro, InputClienteFinanceiro, InputTipoLancamento, InputPlanoContas, InputModoLiquidacao, InputPeriodicidade, InputParcela,
    InputStatusMovimentoFinanceiro, InputTipoDocMovimento, InputMovimentoFinanceiro, InputStatusCorLancamento, InputMovimentoFinanceiroLiquidacao, InputStatusLancamento, InputBanco,
    InputTipoConta, InputTributoEsfera, InputCategoriaAlternativo, InputLancamentoSemMovimento, InputTipoCliente, InputTributoTipo, InputMunicipioFiltro, InputTodasCategorias, InputMunicipioGOPrimeiro, InputTipoLancamentoFinanceiro,
    InputCategoriaFinanceiro, InputLancamentoFinanceiro, InputSituacaoTributo, InputRecolhimentoTributo, InputTributo, InputLancamentoFinanceiroPorCliente, InputChequeDetalhado,
    InputLoteGuiaAcao, InputGuiaStatusRelatorio, InputAtendimentoLancamentoFinanceiro, InputTodosLancamentoFinanceiroPorCliente, InputTipoGuiaPagamento, InputEmpresaCliente, InputContaBancariaEmpresa, InputContaBancariaCliente, InputTipoRepasse, InputTipoGuiaRecurso,
    InputTipoRelatorioPersonalizado, InputContasBancariaEmpresa, InputPorTipoClienteFinanceiro

}

